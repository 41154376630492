import { Box, Image, Text, Button, Flex, Center } from '@chakra-ui/react';
import React from 'react';
import { BsCalendar } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom';

const Event = ({ name, date, done, link, image }) => {
  return (
    <Box
      className='event'
      bg='white'
      padding={4}
      borderRadius='25px'
      boxShadow='xl'
      width='285px'
      m='auto'
    >
      <Image
        className='eventimg'
        src={image}
        width='100%'
        borderRadius='12px'
      />
      <Text
        className='txt'
        fontWeight={800}
        mt={2}
        color='#3D3D3D'
        fontSize={['sm', 'md', 'lg', 'lg']}
        fontFamily='Montserrat'
        padding={2}
        lineHeight={1.3}
        textAlign='left'
      >
        {name}
      </Text>

      <Flex paddingX={2}>
        <Center mt={2}>
          <BsCalendar className='icon' />
        </Center>
        <Text
          className='txt'
          fontWeight={550}
          mt={2}
          color='#3D3D3D'
          fontSize={['sm', 'md', 'lg', 'md']}
          lineHeight={1}
          fontFamily='Montserrat'
          paddingX={2}
          textAlign='left'
        >
          {date}
        </Text>
      </Flex>

      <Flex paddingX='6px'>
        <Center>
          <MdLocationOn size='20px' className='icon' />
        </Center>
        <Text
          className='txt'
          fontWeight={550}
          lineHeight={2}
          color='#3D3D3D'
          fontSize={['sm', 'md', 'lg', 'md']}
          fontFamily='Montserrat'
          paddingX={2}
          textAlign='left'
        >
          Remote
        </Text>
      </Flex>
      <Box className='hosted'>
        <Text
          className='txt'
          fontWeight={600}
          color='#a0a0a0'
          fontSize={['sm', 'sm', 'md', 'md']}
          lineHeight={1}
          fontFamily='Montserrat'
          paddingX={2}
          pt={2}
          textAlign='left'
        >
          Hosted by:
        </Text>
        <Image
          src='https://ik.imagekit.io/codewarriors/cw1_i5JZwiaaY.png'
          float='left'
          padding={2}
          width='40px'
          height='40px'
          borderRadius='full'
        />
      </Box>
      {done ? (
        <Button
          fontSize={['sm', 'md', 'lg', 'md']}
          className='btn'
          mt='5px'
          bg='#3D3D3D'
          width='100%'
          borderRadius='15px'
          color='white'
          fontFamily='Montserrat'
          onClick={() => window.open(link)}
        >
          View Now
        </Button>
      ) : (
        <Link to={link}>
          {' '}
          <Button
            fontSize={['sm', 'md', 'lg', 'md']}
            className='btn'
            mt='5px'
            bg='#3D3D3D'
            width='100%'
            borderRadius='15px'
            color='white'
            fontFamily='Montserrat'
          >
            View Details
          </Button>
        </Link>
      )}
    </Box>
  );
};
// 003049
export default Event;
