import {
  Text,
  SimpleGrid,
  Box,
  Image,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { RiShieldStarFill } from 'react-icons/ri';
import Counter from '../components/Counter';
import Faq from '../components/Faq/Faq';
import Footer from '../components/Footer/Footer';
import { Link } from 'react-router-dom';
import Notification, { Title } from '../Utils/Notification';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toast = useToast();

  useEffect(() => {
    toast({
      position: 'bottom-right',
      title: <Title />,
      description: <Notification />,
      status: 'info',
      duration: 4000,
      isClosable: true,
    });
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Box bg='#F4F5F7'>
        <Box height='auto' bg='#000'>
          <SimpleGrid columns={[1, 2]} pt={[2, 24]} pb={12}>
            <Box
              py={[8]}
              pl={[2, 4, 20, 32]}
              px={4}
              maxWidth={650}
              mt='auto'
              mb='auto'
            >
              <Text
                letterSpacing={[1.5]}
                textAlign='center'
                fontSize={['xl', '2xl', '3xl', '4xl']}
                fontWeight={900}
                color='white'
                lineHeight={1.3}
                fontFamily='Montserrat'
              >
                {' '}
                A Community Of Life Long Learners
              </Text>

              <Text
                py={8}
                letterSpacing={2}
                pb={[2, 4]}
                textAlign='center'
                fontSize={['xl', '2xl', '3xl']}
                fontWeight={800}
                color='white'
                lineHeight={1}
                fontFamily='Montserrat'
              >
                Let us come together to
              </Text>
              <Text
                letterSpacing={[4]}
                pb={8}
                textAlign='center'
                fontSize={['lg', 'xl', '2xl', '3xl']}
                fontWeight={800}
                color='white'
                lineHeight={1}
                fontFamily='Montserrat'
              >
                LEARN AND GROW
              </Text>

              <center>
                <Button
                  size='lg'
                  style={{
                    borderRadius: '25px',
                    border: '3px solid',
                    backgroundColor: '#46ADBE',
                  }}
                >
                  <Link to='/events'>
                    <Text
                      color='white'
                      fontSize={['md', 'lg', 'xl']}
                      letterSpacing={4}
                      fontWeight={600}
                      fontFamily='Montserrat'
                    >
                      Browse Events
                    </Text>
                  </Link>
                </Button>
              </center>
            </Box>
            <Image
              draggable={false}
              src='https://ik.imagekit.io/codewarriors/teaching_OM6990rAa.svg'
              width={600}
              id='hero'
              mt='auto'
              mb='auto'
            />
          </SimpleGrid>
        </Box>

        <Image
          draggable={false}
          src='https://ik.imagekit.io/codewarriors/header_XhUToVdXlH.png'
          width='100%'
          mt='-2px'
        />

        <Flex
          align='center'
          justify='center'
          marginTop={['4px', '-20px', '5px', '-40px']}
        >
          <Text fontWeight={900} fontSize={['lg', 'xl', '2xl', '3xl']}>
            OUR
          </Text>
          &nbsp;&nbsp;
          <Text
            fontWeight={500}
            fontSize={['lg', 'xl', '2xl', '3xl']}
            color='#46ADBE'
          >
            MISSION
          </Text>
        </Flex>

        <Text
          fontWeight={600}
          fontSize={['sm', 'md', 'lg', 'xl']}
          fontFamily='Montserrat'
          textAlign='center'
        >
          Code Warriors provides high-quality education from experts in a
          variety of topics.
        </Text>

        <SimpleGrid
          columns={[1, 2, 2, 4]}
          spacing={6}
          paddingX={12}
          paddingY={4}
          mt={6}
        >
          <Box bg='white' boxShadow='xl' textAlign='center' id='mission' p={2}>
            <center>
              <span className='dot'>
                <Box mt='20px' mb='20px'>
                  <RiShieldStarFill size='40px' color='#46ADBE' />
                </Box>
              </span>
              <Text fontWeight={900} fontSize={['sm', 'md', 'md', 'lg']} mt={6}>
                TRANSFORMING LIVES
              </Text>
              <Text
                fontWeight={400}
                fontSize={['sm', 'md', 'md', '15px']}
                mb='35px'
                mt={4}
              >
                We imporve students engagement to prepare the future workforce.
                People don't resist change. They resist being changed !!
              </Text>
            </center>
          </Box>

          <Box bg='white' boxShadow='xl' textAlign='center' id='mission' p={2}>
            <center>
              <span className='dot'>
                <Box mt='20px' mb='20px'>
                  <RiShieldStarFill size='40px' color='#46ADBE' />
                </Box>
              </span>
              <Text fontWeight={900} fontSize={['sm', 'md', 'md', 'lg']} mt={6}>
                EPISODIC LEARNING
              </Text>
              <Text
                fontWeight={400}
                fontSize={['sm', 'md', 'md', '15px']}
                mb='35px'
                mt={4}
              >
                Episodic learning includes investigate and play practices, and
                provides a sustained context through which learners can connect
                to knowledge in practice.
              </Text>
            </center>
          </Box>
          <Box bg='white' boxShadow='xl' textAlign='center' id='mission' p={2}>
            <center>
              <span className='dot'>
                <Box mt='20px' mb='20px'>
                  <RiShieldStarFill size='40px' color='#46ADBE' />
                </Box>
              </span>
              <Text fontWeight={900} fontSize={['sm', 'md', 'md', 'lg']} mt={6}>
                AWARENESS ABOUT TECH
              </Text>
              <Text
                fontWeight={400}
                fontSize={['sm', 'md', 'md', '15px']}
                mb='35px'
                mt={4}
              >
                Help us reach tier-two and tier-three colleges where people are
                not aware of how tech communities can change their lives.
              </Text>
            </center>
          </Box>

          <Box bg='white' boxShadow='xl' textAlign='center' id='mission' p={2}>
            <center>
              <span className='dot'>
                <Box mt='20px' mb='20px'>
                  <RiShieldStarFill size='40px' color='#46ADBE' />
                </Box>
              </span>
              <Text fontWeight={900} fontSize={['sm', 'md', 'md', 'lg']} mt={6}>
                SKILLS OVER MONEY
              </Text>
              <Text
                fontWeight={400}
                fontSize={['sm', 'md', 'md', '15px']}
                mb='35px'
                mt={4}
              >
                Majority of our programs do not require any money. We are
                looking out for passionate individuals whom we can transform to
                be the future leaders.
              </Text>
            </center>
          </Box>
        </SimpleGrid>

        <Flex align='center' justify='center' marginTop={10}>
          <Text fontWeight={900} fontSize={['lg', 'xl', '2xl', '3xl']}>
            OUR
          </Text>
          &nbsp;&nbsp;
          <Text
            fontWeight={500}
            fontSize={['lg', 'xl', '2xl', '3xl']}
            color='#46ADBE'
          >
            REACH
          </Text>
        </Flex>

        <Text
          fontWeight={600}
          fontSize={['sm', 'md', 'lg', 'xl']}
          fontFamily='Montserrat'
          textAlign='center'
        >
          We are Surat's Biggest Tech Community.
        </Text>

        <SimpleGrid
          columns={[1, 2, 2, 4]}
          spacing={6}
          paddingX={12}
          paddingY={4}
          mt={6}
        >
          {/* <Fade bottom duration={1000}> */}

          <Box bg='white' p={2} boxShadow='xl' id='mission'>
            <center>
              <span className='dot'>
                <Box mt='20px' mb='20px'>
                  <RiShieldStarFill size='40px' color='#46ADBE' />
                </Box>
              </span>
              <Counter End={201630} Duration={5} text='STUDENTS IMPACTED' />
            </center>
          </Box>
          <Box bg='white' p={2} boxShadow='xl' id='mission'>
            <center>
              <span className='dot'>
                <Box mt='20px' mb='20px'>
                  <RiShieldStarFill size='40px' color='#46ADBE' />
                </Box>
              </span>
              <Counter End={27} Duration={5} text='EVENTS CONDUCTED' />
            </center>
          </Box>
          <Box bg='white' p={2} boxShadow='xl' id='mission'>
            <center>
              <span className='dot'>
                <Box mt='20px' mb='20px'>
                  <RiShieldStarFill size='40px' color='#46ADBE' />
                </Box>
              </span>
              <Counter End={1} Duration={5} text='HACKATHON ORGANIZED' />
            </center>
          </Box>

          <Box bg='white' p={2} boxShadow='xl' id='mission'>
            <center>
              <span className='dot'>
                <Box mt='20px' mb='20px'>
                  <RiShieldStarFill size='40px' color='#46ADBE' />
                </Box>
              </span>
              <Counter End={100} Duration={5} text='COMPANIES PARTNERED' />
            </center>
          </Box>

          {/* </Fade> */}
        </SimpleGrid>

        <Flex align='center' justify='center' marginTop={10} mb={8}>
          <Text fontWeight={900} fontSize={['lg', 'xl', '2xl', '3xl']}>
            COMMON
          </Text>
          &nbsp;&nbsp;
          <Text
            fontWeight={500}
            fontSize={['lg', 'xl', '2xl', '3xl']}
            color='#46ADBE'
          >
            QUESTIONS
          </Text>
        </Flex>
        <Faq />

        <Footer />
      </Box>
    </>
  );
};

export default Home;
