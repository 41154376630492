import { Image } from "@chakra-ui/image";
import { Box } from "@chakra-ui/layout";
import React from "react";
import { auth, db, googleProvider } from "../../services/firebase";
import cw from "../../images/cw1.png";
import "./Login.css";
import { Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";

export default function Login() {

  const signInWithGoogle = () => {
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        localStorage.setItem("user", true);
        if (res.additionalUserInfo.isNewUser) {
          db.collection("members").doc(res.user.uid).set({
            name: res.user.displayName,
            email: res.user.email,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <Box className="login-page">
      <Box className="login-box">
        <Image className="cw-logo" src={cw} />
        <Text className="community">Join Our Community</Text>
        <Button className="login-btn" onClick={signInWithGoogle}>
        <span
            className="iconify google"
            data-icon="grommet-icons:google"
            data-inline="false"
          ></span>&nbsp;&nbsp;Sign Up / Sign In With Google 
        </Button>
      </Box>
    </Box>
  );
}
