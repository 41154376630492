import React, { useState } from "react";
import Navbar from "../Navbar";
import {
  Box,
  Tabs,
  TabList,
  Button,
  Image,
  TabPanel,
  TabPanels,
  Tab,
  Center,
  SimpleGrid,
  Text,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import Sidebar from "../Sidebar";
import Footer from "../Footer/Footer";
import Card from "./Card/card";
import { MdCheckCircle } from "react-icons/md";
import Card1 from "./Card/card1";
// import Card1 from "./Card/card1";

const Coding = () => {
  const [isOpened, setIsOpen] = useState(false);
  window.scrollTo(0, 0);

  const toggle = () => {
    setIsOpen(!isOpened);
  };

  return (
    <Box bg="#F4F5F7">
      <Sidebar isOpen={isOpened} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Text
        letterSpacing={1.3}
        textAlign="center"
        fontSize={["2xl", "2xl", "3xl", "5xl"]}
        p={4}
        fontWeight={900}
      >
        CW - Spring Of Code
      </Text>
      <Image
        draggable={false}
        src="https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-21_at_12.48.10_PM_cwE0FgEp0.jpeg"
        px={8}
        width="100%"
        mb={2}
      />
      <br />
      <SimpleGrid columns={[1, 2]}>
        <Box>
          <Text
            textAlign="justify"
            px={8}
            fontSize={["xl", "xl", "2xl", "2xl"]}
            fontWeight={600}
          >
            About Event
          </Text>

          <List spacing={4} px={8} py={4}>
            <ListItem>
              It’s high time now for those students who simply just want an
              excuse to quit a task they have taken to improve their skills. All
              the excitement and enthusiasm of the students often get
              disappeared as time passes.
            </ListItem>
            <ListItem>
              We all are aware of the developments taking place around the world
              and the wonders that are being made in the world of coding.
            </ListItem>

            <ListItem>
              “The only thing that makes the lion different and unique from the
              other animals is its attitude.”
            </ListItem>

            <ListItem>
              And one lion is in you that has the attitude to conquer the
              world!! Here is the opportunity to showcase it in Spring of Code
              by Code Warriors.
            </ListItem>
          </List>
        </Box>

        <Box>
          <Text px={8} fontSize={["xl", "xl", "2xl", "2xl"]} fontWeight={600}>
            Who Can Participate
          </Text>
          <List spacing={1} px={8} py={4}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              All the coder's out there
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              The participant should have beginner level knowledge in any of the
              programming languages
            </ListItem>
          </List>

          <Text
            textAlign="justify"
            px={8}
            fontSize={["xl", "xl", "2xl", "2xl"]}
            fontWeight={600}
          >
            Guidelines
          </Text>
          <List spacing={1} px={8} py={4}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Coding question will be given on day – 1 of event and answer will
              be given on day – 2 and the same pattern will be followed for the
              whole event.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color="green.500" />
              Participants have to submit their solution on the same day because
              the submission link will expire the next day and To be eligible
              for certification participant have to submit the solutions for
              each question given in the whole event
            </ListItem>
          </List>
        </Box>
      </SimpleGrid>
      <br />
      <center>
        <Button
          mb={6}
          size="lg"
          style={{ borderRadius: "25px", backgroundColor: "#46ADBE" }}
          disabled
        >
          <Text
            color="white"
            fontSize={["sm", "md", "lg"]}
            letterSpacing={2}
            fontWeight={600}
            fontFamily="Montserrat"
          >
            Registration Closed
          </Text>
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          mb={6}
          size="lg"
          style={{ borderRadius: "25px", backgroundColor: "#46ADBE" }}
          onClick={() => window.open("https://youtu.be/SZY0Bzai018")}
        >
          <Text
            color="white"
            fontSize={["sm", "md", "lg"]}
            letterSpacing={2}
            fontWeight={600}
            fontFamily="Montserrat"
          >
            Video Guide
          </Text>
        </Button>
      </center>

      <Tabs variant="soft-rounded" colorScheme="blue" mt={6} defaultIndex={1}>
        <Center>
          <TabList>
            <Tab>March Challenges</Tab>
            <Tab>April Challenges</Tab>
          </TabList>
        </Center>

        <TabPanels>
          <TabPanel>
            <center>
              <Card />
            </center>
          </TabPanel>

          <TabPanel>
            <center>
              <Card1 />
            </center>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Tabs variant="soft-rounded" colorScheme="blue" mt={6}>
        <Center>
          <TabList>
            <Tab>Event Partners</Tab>
          </TabList>
        </Center>

        <TabPanels>
          <TabPanel>
            <SimpleGrid columns={[2, 4, 4, 4]} p={6} spacingX={6} spacingY={10}>
              <Box m="auto">
                <Image src="https://ik.imagekit.io/codewarriors/tr:w-561,h-208,cm-extract,x-0,y-87/Script_Foundation_Surat_z3URlhgS0.png" />
              </Box>
              <Box m="auto">
                <Image src="https://ik.imagekit.io/codewarriors/transblack_r0xoyH9vJ.png" />
              </Box>

              <Box m="auto">
                <Image src="https://ik.imagekit.io/codewarriors/GDG_Gwalior_Logo_Y82mZ8y43.png" />
              </Box>

              <Box m="auto">
                <Image src="https://ik.imagekit.io/codewarriors/download_R9-dqHZay.png" />
              </Box>
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Footer />
    </Box>
  );
};

export default Coding;
