import { Box } from '@chakra-ui/layout';
import React from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';

const HacktoberFestForm = () => {
  return (
    <Box bg='#F4F5F7'>
      <iframe
        style={{ width: '100%', height: '100vh' }}
        title="OpenSource"
        src="https://docs.google.com/forms/d/e/1FAIpQLSc4Xsi2QX2o-XaFOXDkbnJCJmvro7rJe-TgaCJz6dVsoDl5YQ/viewform?embedded=true"
      >
        Loading…
      </iframe>
    </Box>
  );
};

export default HacktoberFestForm;
