import React, { useState } from 'react';
import {
  Box,
  Button,
  Image,
  SimpleGrid,
  Text,
  List,
  ListItem,
  ListIcon,
  Tabs,
  Center,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { useHistory } from 'react-router';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';

const Mlops = () => {
  const [isOpened, setIsOpen] = useState(false);
  window.scrollTo(0, 0);

  let history = useHistory();

  const toggle = () => {
    setIsOpen(!isOpened);
  };

  return (
    <Box bg='#F4F5F7'>
      <Sidebar isOpen={isOpened} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Image
        mt={8}
        draggable={false}
        className='chapter-img'
        src='https://ik.imagekit.io/codewarriors/Mlops1_tUPDvTLQ3mO.png'
      />
      <SimpleGrid columns={[1, 2]} mt={8}>
        <Box>
          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            MLOps: What You Need To Know?
          </Text>

          <List spacing={4} className='list'>
            <ListItem d='flex' className='quote'>
              Little by little day by day what is meant for you will find its way.
            </ListItem>
            <ListItem d='flex'>
              We all are aware of the developments taking place around the world and the wonders that are being made in the field of ML/AI.
            </ListItem>
            <ListItem d='flex' className='quote'>
              It's gonna be a beginner-friendly event so, you won't need any prior knowledge of MLOps.
            </ListItem>
          </List>

          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            About Event
          </Text>

          <List spacing={1} className='list'>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              𝗗𝗔𝗧𝗘: 15th August 2021
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              𝗧𝗜𝗠𝗘: 4:00 PM Onwards
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              𝗣𝗟𝗔𝗧𝗙𝗢𝗥𝗠: YOUTUBE
            </ListItem>
          </List>
        </Box>

        <Box>
          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            What'll you learn?
          </Text>
          <List spacing={1} className='list'>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Introduction to Machine Learning
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Introduction to DevOps
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Data Pipelines
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Model Management
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Model Serving
            </ListItem>
          </List>

          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            About Speaker
          </Text>

          <List spacing={4} className='list'>
            <ListItem d='flex'>
              Saurabh Yadav is a 𝗦𝗲𝗻𝗶𝗼𝗿 𝗠𝗮𝗰𝗵𝗶𝗻𝗲 𝗟𝗲𝗮𝗿𝗻𝗶𝗻𝗴 𝗘𝗻𝗴𝗶𝗻𝗲𝗲𝗿 𝗮𝘁 𝗙𝗿𝗮𝗰𝘁𝗮𝗹 𝗔𝗻𝗮𝗹𝘆𝘁𝗶𝗰𝘀. He completed his Bachelor of Technology, Computer Science from 𝗡𝗜𝗜𝗧 𝗨𝗻𝗶𝘃𝗲𝗿𝘀𝗶𝘁𝘆. He chases problems whole heartedly and comes up with innovative solutions. A Cloud and Kubernetes expert.
            </ListItem>
          </List>

        </Box>
      </SimpleGrid>
      <br />
      <center>
        <Text fontWeight='bold'> You will receive a certificate once you complete the quiz.</Text>
        <Button
          my={6}
          disabled
          size='lg'
          style={{ borderRadius: '25px', backgroundColor: '#46ADBE' }}
          onClick={() =>
            history.push('/events/MLOps/register')
          }
        >
          <Text
            color='white'
            fontSize={['sm', 'md', 'lg']}
            letterSpacing={2}
            fontWeight={600}
            fontFamily='Montserrat'
          >
            Register Now
          </Text>
        </Button>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          my={6}
          size="lg"
          style={{ borderRadius: "25px", backgroundColor: "#46ADBE" }}
          onClick={() => window.open("https://youtu.be/3vNU7aG_bRg")}
        >
          <Text
            color="white"
            fontSize={["sm", "md", "lg"]}
            letterSpacing={2}
            fontWeight={600}
            fontFamily="Montserrat"
          >
            View Workshop
          </Text>
        </Button>
      </center>

      <Tabs variant="soft-rounded" colorScheme="blue" mt={6}>
        <Center>
          <TabList>
            <Tab>Event Partners</Tab>
          </TabList>
        </Center>

        <TabPanels>
          <TabPanel>
            <SimpleGrid columns={[2, 4, 4, 5]} p={6} spacingX={6} spacingY={10}>
              <Box m="auto">
                <Image src="https://ik.imagekit.io/codewarriors/transblack_r0xoyH9vJ.png" />
              </Box>

              <Box m="auto">
                <Image src="https://ik.imagekit.io/codewarriors/gdg_logo_efIpZJ-yJdQ.png" />
              </Box>

              <Box m="auto">
                <Image src="https://ik.imagekit.io/codewarriors/gslogo_cIX_3tlvIt7.png" />
              </Box>

              <Box m="auto" width={[16, 20, 28]} height={[16, 20, 28]}>
                <Image src="https://ik.imagekit.io/codewarriors/wtm_logo_9se8L9MDU.PNG" />
              </Box>

              <Box m="auto" width={[16, 20, 28]} height={[16, 20, 28]}>
                <Image src="https://ik.imagekit.io/codewarriors/jigsaw_bcqIfRHtdvv.png" />
              </Box>
            </SimpleGrid>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Footer />
    </Box>
  );
};

export default Mlops;
