import { useToast } from '@chakra-ui/toast';
import React, { useState, useEffect, createContext } from 'react';
import { auth, db } from '../services/firebase';

export const UserContext = createContext({ user: null });

const UserProvider = (props) => {
  const toast = useToast();

  const [user, setuser] = useState(localStorage.getItem('user') || null);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const { displayName, email, photoURL, uid } = user;
        db.collection('members')
          .doc(user.uid)
          .get()
          .then(function (doc) {
            setuser({
              displayName,
              email,
              photoURL,
              uid,
              member: doc.exists,
            });
          })
          .catch(function (error) {
            toast({
              title: 'Something went wrong.',
              description: 'Try Again',
              status: 'error',
              duration: 2000,
              isClosable: true,
            });
          });
      }
    });
  }, []);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
