import React from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Text, Box } from '@chakra-ui/react';

function Counter({ End, text, Duration }) {
  const [focus, setFocus] = React.useState(false);

  return (
    <CountUp start={focus ? 0 : null} end={End} duration={Duration} redraw={true}>
      {({ countUpRef }) => (
        <div>
          <Box mt="10px" >
            <Text textAlign="center" fontWeight={900} fontSize={["sm", "md", "md", "lg"]} >{text}</Text>
            <Text lineHeight={1} mb="8px" mt="5px" fontWeight={400} textAlign="center" fontSize="60px" fontFamily="Montserrat" ref={countUpRef} />
          </Box>

          <VisibilitySensor
            onChange={isVisible => {
              if (isVisible) {
                setFocus(true);
              }
            }}
          >
            <a href='/' style={{ color: "white" }}>.</a>
          </VisibilitySensor>
        </div>
      )}
    </CountUp>

  )
}

export default Counter
