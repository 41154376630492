import React from 'react'
import { Text, Collapse, Divider } from '@chakra-ui/react'


const FaqComponent = ({ title, info }) => {
    const [show, setShow] = React.useState(false);

    const handleToggle = () => setShow(!show);


    return (
        <div>
            <Text onClick={handleToggle} fontWeight={700} color="black" py={4} cursor="pointer">
                {title}
            </Text>
            <Collapse mt={4} in={show} pb={4}>
                <Text color="#46ADBE" fontWeight={600} fontFamily="Montserrat">
                    {info}
                </Text>

            </Collapse>

            <Divider />
        </div>
    )
}

export default FaqComponent
