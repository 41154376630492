export const data = [
  {
    id: 1,
    no: "Problem 1",
    day: "Day 1",
    ps: "Find the maximum occurring character in a given String",
    done: true,
    text: `#Find the maximum occurring character in a given String
#If there are two characters having the same count then the character which is appeared first is printed

str = "CodeWarriors"
occurrence = {}
for i in str:
    if i in occurrence:
        occurrence[i] = occurrence[i] + 1
    else:
        occurrence[i] = 1
out = max(occurrence, key = occurrence.get)
print(out)

`,
    c: `//Find the maximum occurring character in a given String
//If there are two characters having the same count then the character which is appeared first is printed

#include <stdio.h>
#include <string.h>
 
int main()
{
  	char result;
  	int i, len;
  	int max = -1;
  	
  	int freq[256] = {0}; 
 
  	char str[] = "CodeWarriors";
  	
  	len = strlen(str);
  	
  	for(i = 0; i < len; i++)
  	{
  		freq[str[i]]++;
	}
  		
  	for(i = 0; i < len; i++)
  	{
		if(max < freq[str[i]])
		{
			max = freq[str[i]];
			result = str[i];
		}
	}
	printf("The Maximum Occurring Character in a Given String = %c ", result);
	
  	return 0;
}
`,
    cplusplus: `//Find the maximum occurring character in a given String
//If there are two characters having the same count then the character which is appeared first is printed

#include<iostream>
#include<string>
using namespace std;

int main()
{
        string str = "CodeWarriors";
        int i, len;
        int max = -1;
        char result;
            
        int freq[256] = {0}; 
            
        len = str.length();
            
        for(i = 0; i < len; i++)
        {
            freq[str[i]]++;
        }
                
        for(i = 0; i < len; i++)
        {
            if(max < freq[str[i]])
            {
                max = freq[str[i]];
                result = str[i];
            }
        }
        cout<< "The Maximum Occurring Character in " << str <<" = " << result;
        return 0;
}`,

    java: `//Find the maximum occurring character in a given String
//If there are two characters having the same count then the character which is appeared first is printed

import java.util.*;
public class Main 
{
    static final int N = 256;
    static char MaxOccuringChar(String str1) 
    {
        int ctr[] = new int[N];
        int l = str1.length();
        for (int i = 0; i < l; i++)
            ctr[str1.charAt(i)]++;
        int max = -1;
        char result = ' ';

        for (int i = 0; i < l; i++) 
        {
            if (max < ctr[str1.charAt(i)]) 
            {
                max = ctr[str1.charAt(i)];
                result = str1.charAt(i);
            }
        }
        return result;
    }
    public static void main(String[] args) 
    {
        String str1 = "CodeWarriors";
        System.out.println("Max occurring character in the given string is: " + MaxOccuringChar(str1));
    }
}`,
    JavaScript: `//Find the maximum occurring character in a given String
//If there are two characters having the same count then the character which is appeared first is printed

var string  = "CodeWarriors",
counter = {};
        
for (var i = 0;i < string.length; i += 1) 
{
    counter[string[i]] = (counter[string[i]] || 0) + 1;
}
        
var maximum = -1, char;
for (var key in counter) 
{
    if (counter[key] > maximum) 
    {
        maximum = counter[key];
        char = key;
    }
}
console.log("Max occurring character in the given string is: "+char);`,
  },

  {
    id: 2,
    day: "Day 2",
    no: "Problem 2",
    ps: "Print the word with more than one occurrence from the given String",
    testCase:
      "Input= big black bug bit a big black dog on his big black nose Output = big,black",
    done: true,
    text: `#Print the word with more than one occurrence from the given String

string = "big black bug bit a big black dog dog on his big black nose bug"; 
string = string.lower(); 
words = string.split(" "); 
l = []
for i in words:
    if words.count(i) > 1 and i not in l:
        l.append(i)
print(l)

`,
    c: `//Print the word with more than one occurrence from the given String
    
#include <stdio.h>  
#include <string.h>  
       
int main()  
{     
    char string[] = "big black bug bit a big black dog on his big black nose";  
    char words[100][100];  
    int i = 0, j = 0, k, length, count;  
        
    for(k=0; string[k]!='\0'; k++)
    {  
        if(string[k] != ' ' && string[k] != '\0'){  
            words[i][j++] = tolower(string[k]);  
        }  
        else{  
            words[i][j] = '\0';  
            i++;  
            j = 0;  
        }  
    }  
        
    length = i+1;  
        
    printf("Duplicate words in the given string:");  
    for(i = 0; i < length; i++)
    {  
        count = 1;  
        for(j = i+1; j < length; j++)
        {  
            if(strcmp(words[i], words[j]) == 0 && (strcmp(words[j],"0") != 0))
            {  
                count++;  
                strcpy(words[j],"0");  
            }   
        }  
        if(count > 1 )  
            printf("%s", words[i]);  
    }  
    return 0;  
}  `,
    cplusplus: `//Print the word with more than one occurrence from the given String
    
#include "bits/stdc++.h"
using namespace std;
typedef long long int ll;

int main() 
{
    ll tt = 1;
    while(tt--) 
    {
        string str = "";
        getline(cin, str);
        istringstream ss(str); 
        string s = ""; 	map<string, ll> mp;
        while (ss >> s) mp[s]++; 
        for(auto it:mp) 
            if(it.second > 1) cout<<it.first<<"";
    }
    return 0;
}`,
    java: `//Print the word with more than one occurrence from the given String

public class DuplicateWord 
{  
    public static void main(String[] args) 
    {  
        String string = "big black bug bit a big black dog on his big black nose";  
        int count;  
                
        string = string.toLowerCase();  
                
        String words[] = string.split(" ");  
                
        System.out.println("Duplicate words in a given string : ");   
        for(int i = 0; i < words.length; i++) 
        {  
            count = 1;  
            for(int j = i+1; j < words.length; j++) 
            {  
                if(words[i].equals(words[j])) 
                {  
                    count++;  
                    words[j] = "0";  
                }  
            }  
                
            if(count > 1 && words[i] != "0")  
                System.out.println(words[i]);  
        }  
    }  
}  `,
    JavaScript: `//Print the word with more than one occurrence from the given String

const string = 'big black bug bit a big black dog on his big black nose';
const a = string.split('  ');
const strFrequency = function (stringArr) {
  return stringArr.reduce((count, word) => {
    count[word] = (count[word] || 0) + 1;
    return count;
  }, {});
};

const list = strFrequency(a);
for (v in list) {
  if (list[v] > 1) {
    console.log(v);
  }
}`,
  },
  {
    id: 3,
    day: "Day 3",
    no: "Problem 3",
    ps: "Find the first non-repeating character in a given String",
    testCase: `Input= 'codewarriors' Output = c`,
    done: true,
    text: `#Find the first non-repeating character in a given String

str = "codewarriors"
flag = False

for i in str:
 count = str.count(i)
 if count == 1:
    ans = i
    flag = True
    break

if flag:
    print("First non-repeating character is " + ans)
else:
    print("Either all characters are repeating or string is empty")
`,
    c: `//Find the first non-repeating character in a given String

#include<stdlib.h>
#include<stdio.h>
#define NO_OF_CHARS 256

int *get_char_count(char *str)
{
	int *count = (int *)calloc(sizeof(int), NO_OF_CHARS);
	int i;
	for (i = 0; *(str+i); i++)
		count[*(str+i)]++;
	return count;
}

int first_non_repeating_character(char *str)
{
	int *count = get_char_count(str);
	int index = -1, i;

    for (i = 0; *(str+i); i++)
        {
            if (count[*(str+i)] == 1)
                {
                    index = i;
                    break;
                }
        }

	free(count);
	return index;
}

int main()
{
    char str[] = "codewarriors";
	int index = first_non_repeating_character(str);
	if (index == -1)
		printf(“All the characters are repetitive”);
	else		
		printf(“First non-repeating character is %c”, str[index]);
	getchar();
	return 0;
}
    `,
    cplusplus: `//Find the first non-repeating character in a given String

#include <stdio.h>
#include <stdlib.h>
#define NO_OF_CHARS 256

int* getCharCountArray(char* str)
{
    int* count = (int*)calloc(sizeof(int), NO_OF_CHARS);
    int i;
    for (i = 0; *(str + i); i++)
        count[*(str + i)]++;
    return count;
}

int firstNonRepeating(char* str)
{
    int* count = getCharCountArray(str);
    int index = -1, i;
    
    for (i = 0; *(str + i); i++) {
        if (count[*(str + i)] == 1) {
            index = i;
            break;
        }
    }
    
    free(count);
    return index;
}
    
int main()
{
    char str[] = "codewarriors";
    int index = firstNonRepeating(str);
    if (index == -1)
        printf("Either all characters are repeating or "
                "string is empty");
    else
        printf("First non-repeating character is %c",
                str[index]);
    getchar();
    return 0;
}`,
    java: `//Find the first non-repeating character in a given String

class A {
    static final int NO_OF_CHARS = 256;
    static char count[] = new char[NO_OF_CHARS];
    
    static void getCharCountArray(String str)
    {
        for (int i = 0; i < str.length(); i++)
            count[str.charAt(i)]++;
    }
    
    static int firstNonRepeating(String str)
    {
        getCharCountArray(str);
        int index = -1, i;
    
        for (i = 0; i < str.length(); i++) {
            if (count[str.charAt(i)] == 1) {
                index = i;
                break;
            }
        }
    
        return index;
    }
    
    public static void main(String[] args)
    {
        String str = "codewarriors";
        int index = firstNonRepeating(str);
    
        System.out.println(
            index == -1
                ? "Either all characters are repeating or string "
                        + "is empty"
                : "First non-repeating character is "
                        + str.charAt(index));
    }
}
    `,
    JavaScript: `//Find the first non-repeating character in a given String

string = 'codewarriors';
for (val in string) {
  count = 0;
  for (i = 0; i < string.length; i++) {
    if (string[val] == string[i]) {
      count += 1;
    }
  }
  if (count === 1) {
    console.log(string[val]);
    return;
  }
}
`,
  },
  {
    id: 4,
    day: "Day 4",
    no: "Problem 4",
    ps: "Print all strings of maximum length from an array of strings.",
    testCase:
      "Input: arr = [code,home,water,food,keras]  Output: [keras,water]",
    done: true,
    text: `#Print all strings of maximum length from an array of strings
    
import sys
def maxLength(arr):
    lenn = -sys.maxsize - 1
    N = len(arr)

    for i in range(N):
        l = len(arr[i])

        if (lenn < l):
            lenn = l

    return lenn

def maxStrings(arr, lenn):
 
    N = len(arr)
    ans = []

    for i in range(N):
        if (lenn == len(arr[i])):
            ans.append(arr[i])

    for i in range(len(ans)):
        print(ans[i], end = " ")

def printStrings(arr):
    max = maxLength(arr)
    maxStrings(arr, max)
 
if __name__ == '__main__':
    arr = [code,home,water,food,keras]
    printStrings(arr)    
    `,
    c: `//Print all strings of maximum length from an array of strings

#include<stdio.h>
#include<string.h>

void main()
{
    int numberOfElements=0;
    int maximum=0;
    char arrayOfStrings[10][10];

    printf("Enter no. of elements in your array :");
    scanf("%d",&numberOfElements);

    for(int i=0;i<numberOfElements;i++)
    {
        printf("Enter the [%d] String of Array : ",i+1);
        scanf("%s",arrayOfStrings[i]);

        if(strlen(arrayOfStrings[i])>maximum)
        {
            maximum=strlen(arrayOfStrings[i]);
        }
    }
    printf("[");
    for(int j=0;j<numberOfElements;j++)
    {
        if(strlen(arrayOfStrings[j])==maximum)
        printf("%s ",arrayOfStrings[j]);
    }
    printf("]");
}`,
    cplusplus: `//Print all strings of maximum length from an array of strings

#include <bits/stdc++.h>
using namespace std;
int maxLength(vector<string> arr)
{
    int len = INT_MIN;
    int N = arr.size();
    for (int i = 0; i < N; i++) {

        int l = arr[i].size();
 
        if (len < l) {
 
            len = l;
        }
    }
    return len;
}
 
void maxStrings(vector<string> arr, int len)
{
    int N = arr.size();
    vector<string> ans;
 
    for (int i = 0; i < N; i++) {
        if (len == arr[i].size()) {
            ans.push_back(arr[i]);
        }
    }

    for (int i = 0; i < ans.size(); i++) {
        cout << ans[i] << " ";
    }
}
 
void printStrings(vector<string>& arr)
{
    int max = maxLength(arr);
    maxStrings(arr, max);
}
 
int main()
{
    vector<string> arr = { code,home,water,food,keras };
    printStrings(arr);
 
    return 0;
}`,
    java: `//Print all strings of maximum length from an array of strings

import java.util.*; 
class A
{
    static int maxLength(String []arr)
    {
        int len = Integer.MIN_VALUE;
        int N = arr.length;
    
        for(int i = 0; i < N; i++)
        {
            int l = arr[i].length();
            if (len < l) 
            {
                len = l;
            }
        } 
        return len;
    }

    static void maxStrings(String []arr, int len)
    {
        int N = arr.length;
        Vector<String> ans = new Vector<String>();
    
        for(int i = 0; i < N; i++)
        {
            if (len == arr[i].length())
            {
                ans.add(arr[i]);
            }
        }
    
        for(int i = 0; i < ans.size(); i++)
        {
            System.out.print(ans.get(i) + " ");
        }
    }

    static void printStrings(String [] arr)
    {
        int max = maxLength(arr);
        maxStrings(arr, max);
    }
    
    public static void main(String[] args)
    {
        String []arr = {code,home,water,food,keras}; 
        printStrings(arr);
    }
}`,
  },
  {
    id: 5,
    day: "Day 5",
    no: "Problem 5",
    ps:
      "Rearrange array by interchanging positions of even and odd elements in the given array",
    testCase: "Input: [1, 3, 2, 4] Output: [ 2 4 1 3]",
    done: true,
    text: `#Rearrange array by interchanging positions of even and odd elements in the given array

def replace(arr,  n):
    for i in range(n):
        for j in range(i + 1, n):
 
            if (arr[i] >= 0 and
                arr[j] >= 0 and
                arr[i] % 2 == 0 and
                arr[j] % 2 != 0):
 
                tmp = arr[i]
                arr[i] = arr[j]
                arr[j] = tmp
 
                arr[j] = -arr[j]
 
                break
 
            elif (arr[i] >= 0 and
                  arr[j] >= 0 and
                  arr[i] % 2 != 0 and
                  arr[j] % 2 == 0):
 
                tmp = arr[i]
                arr[i] = arr[j]
                arr[j] = tmp
 
                arr[j] = -arr[j]
 
                break
 
    for i in range(n):
        arr[i] = abs(arr[i])
 
    for i in range(n):
        print(arr[i], end = " ")
 
if __name__ == "__main__":
 
    arr = [1, 3, 2, 4]
    n = len(arr)
 
    replace(arr, n)`,
    c: `//Rearrange array by interchanging positions of even and odd elements in the given array
    
#include <stdio.h>
int main()
{
    int arr[] = {2,1,6,8,5,7};
    int n = sizeof(arr)/sizeof(int);

    int odd = -1,even=-1;
    for(int i=0;i<n;i++){
        if (arr[i] < 0)
            continue;
    
        int r = -1;

        if (arr[i] % 2 == 0) 
        {
            odd++;
            while (arr[odd] % 2 == 0 || arr[odd] < 0)
                odd++;
            r = odd;
        }
        else
        {
            even++;
            while (arr[even] % 2 == 1 || arr[even] < 0)
                even++;
            r = even;
        }
        arr[i] *= -1;
        arr[r] *= -1;
    
        int tmp = arr[i];
        arr[i] = arr[r];
        arr[r] = tmp;
    }
    
    printf("[");
    for (int i = 0; i < n; i++)
        printf("%d ", (-1*arr[i]));
    printf("]");
}`,
    cplusplus: `//Rearrange array by interchanging positions of even and odd elements in the given array
    
#include<bits/stdc++.h>
using namespace std;
 
void replace(int arr[], int n)
{
    for(int i = 0; i < n; i++) 
    {
        for(int j = i + 1; j < n; j++) 
        {
            if (arr[i] >= 0 && arr[j] >= 0 && 
                arr[i] % 2 == 0 &&
                arr[j] % 2 != 0) 
            {
                 
                int tmp = arr[i];
                arr[i] = arr[j];
                arr[j] = tmp;
 
                arr[j] = -arr[j];
 
                break;
            }
 
            else if (arr[i] >= 0 && arr[j] >= 0 && 
                     arr[i] % 2 != 0 && 
                     arr[j] % 2 == 0)
            {
                 
                int tmp = arr[i];
                arr[i] = arr[j];
                arr[j] = tmp;
 
                arr[j] = -arr[j];
 
                break;
            }
        }
    }
 
    for(int i = 0; i < n; i++)
        arr[i] = abs(arr[i]);
 
    for(int i = 0; i < n; i++)
         cout << arr[i] << " ";
}
 
int main()
{     
    int arr[] = { 1, 3, 2, 4 };
    int n = sizeof(arr) / sizeof(arr[0]);
     
    replace(arr,n);
}`,
    java: `//Rearrange array by interchanging positions of even and odd elements in the given array

import java.util.*;
import java.lang.*;
 
class A 
{
    static void replace(int[] arr)
    {
        int n = arr.length;
 
        for (int i = 0; i < n; i++) 
        {
            for (int j = i + 1; j < n; j++) 
            {
                if (arr[i] >= 0
                    && arr[j] >= 0
                    && arr[i] % 2 == 0
                    && arr[j] % 2 != 0) {
 
                    int tmp = arr[i];
                    arr[i] = arr[j];
                    arr[j] = tmp;
 
                    arr[j] = -arr[j];
 
                    break;
                }
 
                else if (arr[i] >= 0
                         && arr[j] >= 0
                         && arr[i] % 2 != 0
                         && arr[j] % 2 == 0) {
 
                    int tmp = arr[i];
                    arr[i] = arr[j];
                    arr[j] = tmp;
 
                    arr[j] = -arr[j];
 
                    break;
                }
            }
        }
 
        for (int i = 0; i < n; i++)
            arr[i] = Math.abs(arr[i]);
 
        for (int i = 0; i < n; i++)
            System.out.print(arr[i] + " ");
    }
 
    public static void main(String[] args)
    {
        int[] arr = { 1, 3, 2, 4 };
 
        replace(arr);
    }
}`,
  },
  {
    id: 6,
    day: "Day 6",
    no: "Problem 6",
    ps: "Cyclically rotating all the elements of an array by one.",
    testCase: "Input: [1,2,3,4,5,6,7,8] Output: [8,1,2,3,4,5,6,7]",
    done: true,
    text: `#Cyclically rotating all the elements of an array by one

def rotate(arr, n): 
    x = arr[n - 1] 
      
    for i in range(n - 1, 0, -1): 
        arr[i] = arr[i - 1]; 
          
    arr[0] = x; 
   
arr= [1, 2, 3, 4, 5] 
n = len(arr) 
print ("Given array is") 
for i in range(0, n): 
    print (arr[i], end = ' ') 
  
rotate(arr, n) 
  
print ("Rotated array is") 
for i in range(0, n): 
    print (arr[i], end = ' ')`,
    c: `//Cyclically rotating all the elements of an array by one
    
#include <stdio.h> 
  
void rotate(int arr[], int n) 
{ 
   int x = arr[n-1], i; 
   for (i = n-1; i > 0; i--) 
      arr[i] = arr[i-1]; 
   arr[0] = x; 
} 
  
int main() 
{ 
    int arr[] = {1, 2, 3, 4, 5}, i; 
    int n = sizeof(arr)/sizeof(arr[0]); 
  
    printf("Given array is"); 
    for (i = 0; i < n; i++) 
        printf("%d ", arr[i]); 
  
    rotate(arr, n); 
  
    printf("Rotated array is"); 
    for (i = 0; i < n; i++) 
        printf("%d ", arr[i]); 
  
    return 0; 
}`,
    cplusplus: `//Cyclically rotating all the elements of an array by one

#include <iostream> 
using namespace std; 
  
void rotate(int arr[], int n) 
{ 
    int x = arr[n - 1], i; 
    for (i = n - 1; i > 0; i--) 
    arr[i] = arr[i - 1];  
    arr[0] = x; 
} 
  
int main()  
{ 
    int arr[] = {1, 2, 3, 4, 5}, i; 
    int n = sizeof(arr) /  
            sizeof(arr[0]); 
  
    cout << "Given array is"; 
    for (i = 0; i < n; i++) 
        cout << arr[i]; 
  
    rotate(arr, n); 
  
    cout << "Rotated array is"; 
    for (i = 0; i < n; i++) 
        cout << arr[i]; 
  
    return 0; 
} 
    `,
    java: `//Cyclically rotating all the elements of an array by one
    
import java.util.Arrays; 
  
public class Test 
{ 
    static int arr[] = new int[]{1, 2, 3, 4, 5}; 
      
    static void rotate() 
    { 
       int x = arr[arr.length-1], i; 
       for (i = arr.length-1; i > 0; i--) 
          arr[i] = arr[i-1]; 
       arr[0] = x; 
    } 
      
    public static void main(String[] args)  
    { 
        System.out.println("Given Array is"); 
        System.out.println(Arrays.toString(arr)); 
          
        rotate(); 
          
        System.out.println("Rotated Array is"); 
        System.out.println(Arrays.toString(arr)); 
    } 
} `,
  },
  {
    id: 7,
    day: "Day 7",
    no: "Problem 7",
    ps: "Reverse the elements of a Singly Linked List",
    done: true,
    testCase: "Input: 7=>1=>2=>1 Output: 1=>2=>1=>7",
    text: `#Reverse the elements of a Singly Linked List

class Node:
    def __init__(self, data):
        self.data = data
        self.next = None
 
 
class LinkedList:
    def __init__(self):
        self.head = None
 
    def reverse(self):
        prev = None
        current = self.head
        while(current is not None):
            next = current.next
            current.next = prev
            prev = current
            current = next
        self.head = prev
 
    def push(self, new_data):
        new_node = Node(new_data)
        new_node.next = self.head
        self.head = new_node
 
    def printList(self):
        temp = self.head
        while(temp):
            print temp.data,
            temp = temp.next
 
llist = LinkedList()
llist.push(20)
llist.push(4)
llist.push(15)
llist.push(85)
 
print("Given Linked List")
llist.printList()
llist.reverse()
print("Reversed Linked List")
llist.printList()
    `,
    java: `//Reverse the elements of a Singly Linked List

class LinkedList {

    static Node head;
    static class Node {
        int data;
        Node next;
    
        Node(int d)
        {
            data = d;
            next = null;
        }
    }
    
    Node reverse(Node node)
    {
        Node prev = null;
        Node current = node;
        Node next = null;
        while (current != null) {
            next = current.next;
            current.next = prev;
            prev = current;
            current = next;
        }
        node = prev;
        return node;
    }
    
    void printList(Node node)
    {
        while (node != null) {
            System.out.print(node.data + " ");
            node = node.next;
        }
    }
    
    public static void main(String[] args)
    {
        LinkedList list = new LinkedList();
        list.head = new Node(85);
        list.head.next = new Node(15);
        list.head.next.next = new Node(4);
        list.head.next.next.next = new Node(20);
    
        System.out.println("Given Linked list");
        list.printList(head);
        head = list.reverse(head);
        System.out.println("");
        System.out.println("Reversed linked list ");
        list.printList(head);
    }
}
    `,
    c: `//Reverse the elements of a Singly Linked List
    
#include <stdio.h>
#include <stdlib.h>
 
struct Node {
    int data;
    struct Node* next;
};
 
static void reverse(struct Node** head_ref)
{
    struct Node* prev = NULL;
    struct Node* current = *head_ref;
    struct Node* next = NULL;
    while (current != NULL) {
        next = current->next;
 
        current->next = prev;
 
        prev = current;
        current = next;
    }
    *head_ref = prev;
}
 
void push(struct Node** head_ref, int new_data)
{
    struct Node* new_node = (struct Node*)malloc(sizeof(struct Node));
    new_node->data = new_data;
    new_node->next = (*head_ref);
    (*head_ref) = new_node;
}
 
void printList(struct Node* head)
{
    struct Node* temp = head;
    while (temp != NULL) {
        printf("%d  ", temp->data);
        temp = temp->next;
    }
}
 
int main()
{
    struct Node* head = NULL;
 
    push(&head, 20);
    push(&head, 4);
    push(&head, 15);
    push(&head, 85);
 
    printf("Given linked list");
    printList(head);
    reverse(&head);
    printf("Reversed Linked list");
    printList(head);
    getchar();
}
    `,
    cplusplus: `//Reverse the elements of a Singly Linked List

#include <iostream>
using namespace std;
 
struct Node {
    int data;
    struct Node* next;
    Node(int data)
    {
        this->data = data;
        next = NULL;
    }
};
 
struct LinkedList {
    Node* head;
    LinkedList() { head = NULL; }
 
    void reverse()
    {
        Node* current = head;
        Node *prev = NULL, *next = NULL;
 
        while (current != NULL) {
            next = current->next;
 
            current->next = prev;
 
            prev = current;
            current = next;
        }
        head = prev;
    }
 
    void print()
    {
        struct Node* temp = head;
        while (temp != NULL) {
            cout << temp->data << " ";
            temp = temp->next;
        }
    }
 
    void push(int data)
    {
        Node* temp = new Node(data);
        temp->next = head;
        head = temp;
    }
};
 
int main()
{
    LinkedList ll;
    ll.push(20);
    ll.push(4);
    ll.push(15);
    ll.push(85);
 
    cout << "Given linked list";
    ll.print();
 
    ll.reverse();
 
    cout << "Reversed Linked list";
    ll.print();
    return 0;
}`,
  },
  {
    id: 8,
    day: "Day 8",
    no: "Problem 8",
    ps: "Merge a linked list into another linked list at alternate positions",
    testCase:
      "Input: 5->7->17->13->11  12->10->2->4->6 Output: 5->12->7->10->17->2->13->4->11->6",
    done: true,
    text: `#Merge a linked list into another linked list at alternate positions
    
class LinkedList(object): 
    def __init__(self): 
        self.head = None
  
    class Node(object): 
        def __init__(self, d): 
            self.data = d 
            self.next = None
  
    def push(self, new_data): 
        new_node = self.Node(new_data) 
        new_node.next = self.head 
        self.head = new_node 
  
    def merge(self, q): 
        p_curr = self.head 
        q_curr = q.head 
  
        while p_curr != None and q_curr != None: 
  
            p_next = p_curr.next
            q_next = q_curr.next
  
            q_curr.next = p_next 
            p_curr.next = q_curr
  
            p_curr = p_next 
            q_curr = q_next 
        q.head = q_curr 
  
    def printList(self): 
        temp = self.head 
        while temp != None: 
            print str(temp.data), 
            temp = temp.next
        print('')
  
llist1 = LinkedList() 
llist2 = LinkedList() 
llist1.push(3) 
llist1.push(2) 
llist1.push(1) 
  
print("First Linked List:")
llist1.printList() 
  
llist2.push(8) 
llist2.push(7) 
llist2.push(6) 
llist2.push(5) 
llist2.push(4) 
  
print("Second Linked List:")
  
llist2.printList() 
llist1.merge(llist2) 
  
print("Modified first linked list:")
llist1.printList() 
  
print("Modified second linked list:")
llist2.printList() `,
    c: `//Merge a linked list into another linked list at alternate positions
    
struct Node 
{ 
    int data; 
    struct Node *next; 
}; 
    
void push(struct Node ** head_ref, int new_data) 
{ 
    struct Node* new_node =  (struct Node*) malloc(sizeof(struct Node)); 
    new_node->data  = new_data; 
    new_node->next = (*head_ref); 
    (*head_ref)  = new_node; 
} 
    
void printList(struct Node *head) 
{ 
    struct Node *temp = head; 
    while (temp != NULL) 
    { 
        printf("%d ", temp->data); 
        temp = temp->next; 
    } 
    printf(""); 
} 
    
void merge(struct Node *p, struct Node **q) 
{ 
    struct Node *p_curr = p, *q_curr = *q; 
    struct Node *p_next, *q_next; 

    while (p_curr != NULL && q_curr != NULL) 
    { 
        p_next = p_curr->next; 
        q_next = q_curr->next; 

        q_curr->next = p_next;  
        p_curr->next = q_curr; 

        p_curr = p_next; 
        q_curr = q_next; 
    } 
    
    *q = q_curr; // Update head pointer of second list 
} 
    
int main() 
{ 
    struct Node *p = NULL, *q = NULL; 
    push(&p, 3); 
    push(&p, 2); 
    push(&p, 1); 
    printf("First Linked List:"); 
    printList(p); 

    push(&q, 8); 
    push(&q, 7); 
    push(&q, 6); 
    push(&q, 5); 
    push(&q, 4); 
    printf("Second Linked List:"); 
    printList(q); 

    merge(p, &q); 

    printf("Modified First Linked List:"); 
    printList(p); 

    printf("Modified Second Linked List:"); 
    printList(q); 

    getchar(); 
    return 0; 
} `,
    cplusplus: `//Merge a linked list into another linked list at alternate positions

#include <bits/stdc++.h> 
using namespace std; 
  
class Node  
{  
    public: 
    int data;  
    Node *next;  
};  
  
void push(Node ** head_ref, int new_data)  
{  
    Node* new_node = new Node(); 
    new_node->data = new_data;  
    new_node->next = (*head_ref);  
    (*head_ref) = new_node;  
}  
  
void printList(Node *head)  
{  
    Node *temp = head;  
    while (temp != NULL)  
    {  
        cout<<temp->data<<" ";  
        temp = temp->next;  
    }  
    cout<<endl; 
}  
  
void merge(Node *p, Node **q)  
{  
    Node *p_curr = p, *q_curr = *q;  
    Node *p_next, *q_next;  
  
    while (p_curr != NULL && q_curr != NULL)  
    {  
        p_next = p_curr->next;  
        q_next = q_curr->next;  
  
        q_curr->next = p_next;
        p_curr->next = q_curr; 
  
        p_curr = p_next;  
        q_curr = q_next;  
    }  
  
    *q = q_curr; // Update head pointer of second list  
}  
  
int main()  
{  
    Node *p = NULL, *q = NULL;  
    push(&p, 3);  
    push(&p, 2);  
    push(&p, 1);  
    cout<<"First Linked List:";  
    printList(p);  
  
    push(&q, 8);  
    push(&q, 7);  
    push(&q, 6);  
    push(&q, 5);  
    push(&q, 4);  
    cout<<"Second Linked List:";  
    printList(q);  
  
    merge(p, &q);  
  
    cout<<"Modified First Linked List:";  
    printList(p);  
  
    cout<<"Modified Second Linked List:";  
    printList(q);  
  
    return 0;  
}  `,
    java: `//Merge a linked list into another linked list at alternate positions
    
class LinkedList 
{ 
    Node head;
    class Node 
    { 
        int data; 
        Node next; 
        Node(int d) {data = d; next = null; } 
    } 
  
    void push(int new_data) 
    { 
        Node new_node = new Node(new_data); 
        new_node.next = head;
        head = new_node; 
    } 
  
    void merge(LinkedList q) 
    { 
        Node p_curr = head, q_curr = q.head; 
        Node p_next, q_next; 
  
        while (p_curr != null && q_curr != null) { 
  
            p_next = p_curr.next; 
            q_next = q_curr.next; 
  
            q_curr.next = p_next; // change next pointer of q_curr 
            p_curr.next = q_curr; // change next pointer of p_curr 
  
            p_curr = p_next; 
            q_curr = q_next; 
        } 
        q.head = q_curr; 
    } 
  
    void printList() 
    { 
        Node temp = head; 
        while (temp != null) 
        { 
           System.out.print(temp.data+" "); 
           temp = temp.next; 
        } 
        System.out.println(); 
    } 
  
    public static void main(String args[]) 
    { 
        LinkedList llist1 = new LinkedList(); 
        LinkedList llist2 = new LinkedList(); 
        llist1.push(3); 
        llist1.push(2); 
        llist1.push(1); 
  
        System.out.println("First Linked List:"); 
        llist1.printList(); 
  
        llist2.push(8); 
        llist2.push(7); 
        llist2.push(6); 
        llist2.push(5); 
        llist2.push(4); 
  
        System.out.println("Second Linked List:"); 
  
        llist1.merge(llist2); 
  
        System.out.println("Modified first linked list:"); 
        llist1.printList(); 
  
        System.out.println("Modified second linked list:"); 
        llist2.printList(); 
    } 
}`,
  },
  {
    id: 9,
    day: "Day 9",
    no: "Problem 9",
    ps: "Rearrange the linked list by separating odd nodes from even one",
    testCase:
      "Input:-  17->15->8->12->10->5->4->1->7->6  Output:- 8->12->10->4->6->17->15->5->1->7",
    done: true,
    text: `#Rearrange the linked list by separating odd nodes from even one
    
head = None
class Node:        
    def __init__(self, data):  
        self.data = data # Assign data  
        self.next =None
  
def segregateEvenOdd(): 
  
    global head 
    end = head 
    prev = None
    curr = head 
  
    while (end.next != None): 
        end = end.next
  
    new_end = end 
  
    while (curr.data % 2 !=0 and curr != end): 
        new_end.next = curr 
        curr = curr.next
        new_end.next.next = None
        new_end = new_end.next
          
    if (curr.data % 2 == 0): 
        head = curr 
        while (curr != end): 
            if (curr.data % 2 == 0): 
                prev = curr 
                curr = curr.next
            else: 
                prev.next = curr.next
                curr.next = None
                new_end.next = curr   
                new_end = curr  
                curr = prev.next
    else: 
        prev = curr 
  
    if (new_end != end and end.data % 2 != 0): 
        prev.next = end.next
        end.next = None
        new_end.next = end 
          
def push(new_data): 
    global head 
    new_node = Node(new_data) 
    new_node.next = head 
    head = new_node 
  
def printList(): 
    global head 
    temp = head 
    while(temp != None): 
        print(temp.data, end = " ") 
        temp = temp.next
    print(" ") 
  
push(11) 
push(10) 
push(8) 
push(6) 
push(4) 
push(2) 
push(0) 
print("Origional Linked List") 
printList() 
  
segregateEvenOdd() 
  
print("Modified Linked List") 
printList()  `,
    c: `//Rearrange the linked list by separating odd nodes from even one
    
#include <stdio.h> 
#include <stdlib.h> 
  
struct Node 
{ 
    int data; 
    struct Node *next; 
}; 
  
void segregateEvenOdd(struct Node **head_ref) 
{ 
    struct Node *end = *head_ref; 
    struct Node *prev = NULL; 
    struct Node *curr = *head_ref; 
  
    while (end->next != NULL) 
        end = end->next; 
  
    struct Node *new_end = end; 

    while (curr->data %2 != 0 && curr != end) 
    { 
        new_end->next = curr; 
        curr = curr->next; 
        new_end->next->next = NULL; 
        new_end = new_end->next; 
    } 

    if (curr->data%2 == 0) 
    { 
        *head_ref = curr; 
  
        while (curr != end) 
        { 
            if ( (curr->data)%2 == 0 ) 
            { 
                prev = curr; 
                curr = curr->next; 
            } 
            else
            { 
                prev->next = curr->next;   
                curr->next = NULL;   
                new_end->next = curr;  
                new_end = curr; 
                curr = prev->next; 
            } 
        } 
    } 
    else prev = curr; 
    if (new_end!=end && (end->data)%2 != 0) 
    { 
        prev->next = end->next; 
        end->next = NULL; 
        new_end->next = end; 
    } 
    return; 
} 

void push(struct Node** head_ref, int new_data) 
{ 
    struct Node* new_node = (struct Node*) malloc(sizeof(struct Node)); 
    new_node->data  = new_data; 
    new_node->next = (*head_ref); 
    (*head_ref)    = new_node; 
} 
  
void printList(struct Node *node) 
{ 
    while (node!=NULL) 
    { 
        printf("%d ", node->data); 
        node = node->next; 
    } 
} 
  
int main() 
{ 
    struct Node* head = NULL; 
    
    push(&head, 11); 
    push(&head, 10); 
    push(&head, 8); 
    push(&head, 6); 
    push(&head, 4); 
    push(&head, 2); 
    push(&head, 0); 
  
    printf("Original Linked list "); 
    printList(head); 
  
    segregateEvenOdd(&head); 
  
    printf("Modified Linked list "); 
    printList(head); 
  
    return 0; 
}     `,
    cplusplus: `//Rearrange the linked list by separating odd nodes from even one
    
#include <bits/stdc++.h> 
using namespace std; 
  
class Node  
{  
    public: 
    int data;  
    Node *next;  
};  
  
void segregateEvenOdd(Node **head_ref)  
{  
    Node *end = *head_ref;  
    Node *prev = NULL;  
    Node *curr = *head_ref;  
  
    while (end->next != NULL)  
        end = end->next;  
  
    Node *new_end = end;  
  
    while (curr->data % 2 != 0 && curr != end)  
    {  
        new_end->next = curr;  
        curr = curr->next;  
        new_end->next->next = NULL;  
        new_end = new_end->next;  
    }  
  
    if (curr->data%2 == 0)  
    {  
        *head_ref = curr;  
        while (curr != end)  
        {  
            if ( (curr->data) % 2 == 0 )  
            {  
                prev = curr;  
                curr = curr->next;  
            }  
            else
            {  
                prev->next = curr->next;  
                curr->next = NULL;  
                new_end->next = curr;  
                new_end = curr;  
                curr = prev->next;  
            }  
        }  
    }  
    else prev = curr;  
    if (new_end != end && (end->data) % 2 != 0)  
    {  
        prev->next = end->next;  
        end->next = NULL;  
        new_end->next = end;  
    }  
    return;  
}  

void push(Node** head_ref, int new_data)  
{  
    Node* new_node = new Node(); 
    new_node->data = new_data;  
    new_node->next = (*head_ref);  
    (*head_ref) = new_node;  
}  
  
void printList(Node *node)  
{  
    while (node != NULL)  
    {  
        cout << node->data <<" ";  
        node = node->next;  
    }  
}  
  
int main()  
{  
    Node* head = NULL;  
    push(&head, 11);  
    push(&head, 10);  
    push(&head, 8);  
    push(&head, 6);  
    push(&head, 4);  
    push(&head, 2);  
    push(&head, 0);  
  
    cout << "Original Linked list ";  
    printList(head);  
  
    segregateEvenOdd(&head);  
  
    cout << "Modified Linked list ";  
    printList(head);  
  
    return 0;  
}      `,
    java: `//Rearrange the linked list by separating odd nodes from even one
    
class LinkedList 
{ 
    Node head;
    class Node 
    { 
        int data; 
        Node next; 
        Node(int d) 
        { 
            data = d; 
            next = null; 
        } 
    } 
  
    void segregateEvenOdd() 
    { 
        Node end = head; 
        Node prev = null; 
        Node curr = head; 
  
        while (end.next != null) 
            end = end.next; 
  
        Node new_end = end; 
  
        while (curr.data %2 !=0 && curr != end) 
        { 
            new_end.next = curr; 
            curr = curr.next; 
            new_end.next.next = null; 
            new_end = new_end.next; 
        } 
  
        if (curr.data %2 ==0) 
        { 
            head = curr; 
            while (curr != end) 
            { 
                if (curr.data % 2 == 0) 
                { 
                    prev = curr; 
                    curr = curr.next; 
                } 
                else
                { 
                    prev.next = curr.next; 
                    curr.next = null; 
                    new_end.next = curr; 
                    new_end = curr; 
                    curr = prev.next; 
                } 
            } 
        } 
        else prev = curr; 
  
        if (new_end != end && end.data %2 != 0) 
        { 
            prev.next = end.next; 
            end.next = null; 
            new_end.next = end; 
        } 
    } 

    void push(int new_data) 
    { 
        Node new_node = new Node(new_data); 
        new_node.next = head; 
        head = new_node; 
    } 
  
    void printList() 
    { 
        Node temp = head; 
        while(temp != null) 
        { 
            System.out.print(temp.data+" "); 
            temp = temp.next; 
        } 
        System.out.println(); 
    } 
  
    public static void main(String args[]) 
    { 
        LinkedList llist = new LinkedList(); 
        llist.push(11); 
        llist.push(10); 
        llist.push(8); 
        llist.push(6); 
        llist.push(4); 
        llist.push(2); 
        llist.push(0); 
        System.out.println("Origional Linked List"); 
        llist.printList(); 
  
        llist.segregateEvenOdd(); 
  
        System.out.println("Modified Linked List"); 
        llist.printList(); 
    } 
}    `,
  },
  {
    id: 10,
    day: "Day 10",
    no: "Problem 10",
    ps: "Remove the duplicate elements from a sorted linked list",
    testCase: "Input:- 11->11->11->21->43->43->60 Output:- 11->21->43->60",
    done: true,
    text: `#Remove the duplicate elements from a sorted linked list
      
class Node: 
    def __init__(self, data): 
        self.data = data 
        self.next = None

class LinkedList: 
    def __init__(self): 
        self.head = None

    def push(self, new_data): 
        new_node = Node(new_data) 
        new_node.next = self.head 
        self.head = new_node 

    def deleteNode(self, key): 
        temp = self.head 

        if (temp is not None): 
            if (temp.data == key): 
                self.head = temp.next
                temp = None
                return

        while(temp is not None): 
            if temp.data == key: 
                break
            prev = temp 
            temp = temp.next

        if(temp == None): 
            return

        prev.next = temp.next

        temp = None

    def printList(self): 
        temp = self.head 
        while(temp): 
            print(temp.data , end = ' ')
            temp = temp.next
        
    def removeDuplicates(self):
        temp = self.head
        if temp is None:
            return
        while temp.next is not None:
            if temp.data == temp.next.data:
                new = temp.next.next
                temp.next = None
                temp.next = new
            else:
                temp = temp.next
        return self.head

llist = LinkedList() 

llist.push(20) 
llist.push(13) 
llist.push(13)
llist.push(11)
llist.push(11)
llist.push(11)
print ("Created Linked List: ")
llist.printList() 
print()
print("Linked List after removing", "duplicate elements:")
llist.removeDuplicates()
llist.printList()`,
    c: `//Remove the duplicate elements from a sorted linked list

#include<stdio.h>
#include<stdlib.h>

struct Node
{
    int data;
    struct Node* next;
};
 
void removeDuplicates(struct Node* head)
{
    struct Node* current = head;
    struct Node* next_next; 
   
    if (current == NULL) 
       return; 
 
    while (current->next != NULL) 
    {
       if (current->data == current->next->data) 
       {
           next_next = current->next->next;
           free(current->next);
           current->next = next_next;  
       }
       else
       {
          current = current->next; 
       }
    }
}
 
void push(struct Node** head_ref, int new_data)
{
    struct Node* new_node = (struct Node*) malloc(sizeof(struct Node));           
    new_node->data  = new_data;                
    new_node->next = (*head_ref);              
    (*head_ref)    = new_node;
}
 
void printList(struct Node *node)
{
    while (node!=NULL)
    {
       printf("%d ", node->data);
       node = node->next;
    }
} 
 
int main()
{
    struct Node* head = NULL;

    push(&head, 20);
    push(&head, 13);
    push(&head, 13);  
    push(&head, 11);
    push(&head, 11);
    push(&head, 11);                                    
 
    printf("Linked list before duplicate removal  ");
    printList(head); 
 
    removeDuplicates(head); 
 
    printf("Linked list after duplicate removal ");         
    printList(head);            
   
    return 0;
}`,
    cplusplus: `//Remove the duplicate elements from a sorted linked list
      
#include <bits/stdc++.h>
using namespace std;
 
class Node 
{ 
    public:
    int data; 
    Node* next; 
}; 
 
void removeDuplicates(Node* head) 
{ 
    Node* current = head; 
    Node* next_next; 
    if (current == NULL) 
    return; 
 
    while (current->next != NULL) 
    { 
    if (current->data == current->next->data) 
    { 
        next_next = current->next->next; 
        free(current->next); 
        current->next = next_next; 
    } 
    else
    { 
        current = current->next; 
    } 
    } 
} 

void push(Node** head_ref, int new_data) 
{ 
    Node* new_node = new Node();
    new_node->data = new_data; 
    new_node->next = (*head_ref);     
    (*head_ref) = new_node; 
} 
 
void printList(Node *node) 
{ 
    while (node!=NULL) 
    { 
        cout<<" "<<node->data; 
        node = node->next; 
    } 
} 
 
int main() 
{ 
    Node* head = NULL; 
     
    push(&head, 20); 
    push(&head, 13); 
    push(&head, 13); 
    push(&head, 11); 
    push(&head, 11); 
    push(&head, 11);                                     
 
    cout<<"Linked list before duplicate removal "; 
    printList(head); 
    removeDuplicates(head); 
 
    cout<<"Linked list after duplicate removal ";     
    printList(head);             
     
    return 0; 
} `,
    java: `//Remove the duplicate elements from a sorted linked list
      
class LinkedList
{
    Node head;
    class Node
    {
        int data;
        Node next;
        Node(int d) {data = d; next = null; }
    }
 
    void removeDuplicates()
    {
        Node curr = head;
 
        while (curr != null) {
            Node temp = curr;
            while(temp!=null && temp.data==curr.data) {
                temp = temp.next;
            }
            element denoted by temp*/
            curr.next = temp;
            curr = curr.next;
        }
    }
                      
    public void push(int new_data)
    {
        Node new_node = new Node(new_data);
        new_node.next = head;
        head = new_node;
    }
 
     void printList()
     {
         Node temp = head;
         while (temp != null)
         {
            System.out.print(temp.data+" ");
            temp = temp.next;
         }  
         System.out.println();
     }
 
    public static void main(String args[])
    {
        LinkedList llist = new LinkedList();
        llist.push(20);
        llist.push(13);
        llist.push(13);
        llist.push(11);
        llist.push(11);
        llist.push(11);
         
        System.out.println("List before removal of duplicates");
        llist.printList();
         
        llist.removeDuplicates();
         
        System.out.println("List after removal of elements");
        llist.printList();
    }
} `,
  },
  {
    id: 11,
    day: "Day 11",
    no: "Problem 11",
    ps: "Remove all Fibonacci Nodes from a Circular Singly Linked List",
    testCase:
      "Input:- 9 -> 11 -> 34 -> 6 -> 13 -> 20 Output:- 9 -> 11 -> 6 -> 20 ",
    done: true,
    text: `#Remove all Fibonacci Nodes from a Circular Singly Linked List
    
class Node:
    def __init__(self):
        self.data = 0
        self.next = None

def push(head_ref, data):
    ptr1 = Node()
    temp = head_ref; 
    ptr1.data = data; 
    ptr1.next = head_ref; 

    if (head_ref != None):
        while (temp.next != head_ref):
            temp = temp.next; 
        temp.next = ptr1; 
    else:
        ptr1.next = ptr1; 
   
    head_ref = ptr1; 
    return head_ref
   
def deleteNode( head_ref,  delt):
    temp = head_ref; 
    if (head_ref == delt):
        head_ref = delt.next; 

    while (temp.next != delt):
        temp = temp.next; 
    temp.next = delt.next; 
    del(delt); 
   
    return; 
 
def largestElement( head_ref):
    current = None  
    current = head_ref;   
    maxEle = -10000000
   
    while(True):
        if (current.data > maxEle):
            maxEle = current.data; 
           
        current = current.next; 
         
        if(current == head_ref):
            break
         
    return maxEle; 

def createHash(hashmap, maxElement):
    prev = 0
    curr = 1; 

    hashmap.add(prev); 
    hashmap.add(curr); 

    while (curr <= maxElement):
        temp = curr + prev; 
        hashmap.add(temp); 
        prev = curr; 
        curr = temp; 
       
def deleteFibonacciNodes( head):
    maxEle = largestElement(head); 
    hashmap = set()
    createHash(hashmap, maxEle); 
    ptr = head; 
    next = None
    while(True):
        if (ptr.data in hashmap):
            deleteNode(head, ptr); 
   
        next = ptr.next; 
        ptr = next; 
         
        if(ptr == head):
            break
             
def printList( head):
    temp = head; 
    if (head != None):
        while(True):
            print(temp.data, end = ' ')
            temp = temp.next
             
            if(temp == head):
                break
   
if __name__=='__main__':
    head = None; 
   
    head = push(head, 20); 
    head = push(head, 13); 
    head = push(head, 6); 
    head = push(head, 34); 
    head = push(head, 11); 
    head = push(head, 9); 
   
    deleteFibonacciNodes(head); 
   
    printList(head); `,
    c: `//Remove all Fibonacci Nodes from a Circular Singly Linked List
    
//No Solution`,
    cplusplus: `//Remove all Fibonacci Nodes from a Circular Singly Linked List
    
#include <bits/stdc++.h>
using namespace std;
 
struct Node {
    int data;
    struct Node* next;
};
 
void push(struct Node** head_ref, int data)
{
    struct Node* ptr1 = (struct Node*)malloc(sizeof(struct Node));
    struct Node* temp = *head_ref;
    ptr1->data = data;
    ptr1->next = *head_ref;

    if (*head_ref != NULL) {
        while (temp->next != *head_ref)
            temp = temp->next;
 
        temp->next = ptr1;
    }
    else
        ptr1->next = ptr1;
 
    *head_ref = ptr1;
}
 
void deleteNode(Node* head_ref, Node* del)
{
    struct Node* temp = head_ref;
    if (head_ref == del)
        head_ref = del->next;
 
    while (temp->next != del) {
        temp = temp->next;
    }
    temp->next = del->next;
    free(del);
 
    return;
}
 
int largestElement(struct Node* head_ref)
{
    struct Node* current;
    current = head_ref;
    int maxEle = INT_MIN;
 
    do {
        if (current->data > maxEle) {
            maxEle = current->data;
        }
 
        current = current->next;
    } while (current != head_ref);
 
    return maxEle;
}

void createHash(set<int>& hash,
                int maxElement)
{
    int prev = 0, curr = 1;

    hash.insert(prev);
    hash.insert(curr);

    while (curr <= maxElement) {
        int temp = curr + prev;
        hash.insert(temp);
        prev = curr;
        curr = temp;
    }
}

void deleteFibonacciNodes(Node* head)
{
    int maxEle = largestElement(head);

    set<int> hash;
    createHash(hash, maxEle);
 
    struct Node* ptr = head;
 
    struct Node* next;
 
    do {
 
        if (hash.find(ptr->data)
            != hash.end())
            deleteNode(head, ptr);
 
        next = ptr->next;
        ptr = next;
 
    } while (ptr != head);
}

void printList(struct Node* head)
{
    struct Node* temp = head;
    if (head != NULL) {
        do {
            printf("%d ", temp->data);
            temp = temp->next;
        } while (temp != head);
    }
}
 
int main()
{
    struct Node* head = NULL;

    push(&head, 20);
    push(&head, 13);
    push(&head, 6);
    push(&head, 34);
    push(&head, 11);
    push(&head, 9);
 
    deleteFibonacciNodes(head);
 
    printList(head);
 
    return 0;
}`,
    java: `//Remove all Fibonacci Nodes from a Circular Singly Linked List
    
import java.util.*;
 
class A{

static class Node {
    int data;
    Node next;
};

static Node push(Node head_ref, int data)
{
    Node ptr1 = new Node();
    Node temp = head_ref;
    ptr1.data = data;
    ptr1.next = head_ref;
  
    if (head_ref != null) {
        while (temp.next != head_ref)
            temp = temp.next;
  
        temp.next = ptr1;
    }
    else
        ptr1.next = ptr1;
  
    head_ref = ptr1;
    return head_ref;
}
  
static void deleteNode(Node head_ref, Node del)
{
    Node temp = head_ref;
    if (head_ref == del)
        head_ref = del.next;

    while (temp.next != del) {
        temp = temp.next;
    }
  
    temp.next = del.next;
    del = null;
  
    return;
}

static int largestElement(Node head_ref)
{
    Node current;
    current = head_ref;
    int maxEle = Integer.MIN_VALUE;
  
    do {
        if (current.data > maxEle) {
            maxEle = current.data;
        }
  
        current = current.next;
    } while (current != head_ref);
  
    return maxEle;
}
  
static void createHash(HashSet<Integer> hash,
                int maxElement)
{
    int prev = 0, curr = 1;

    hash.add(prev);
    hash.add(curr);

    while (curr <= maxElement) {
        int temp = curr + prev;
        hash.add(temp);
        prev = curr;
        curr = temp;
    }
}

static void deleteFibonacciNodes(Node head)
{
    int maxEle = largestElement(head);
  
    HashSet<Integer> hash = new HashSet<Integer>();
    createHash(hash, maxEle);
  
    Node ptr = head;
  
    Node next;
  
    do {
        if (hash.contains(ptr.data))
            deleteNode(head, ptr);
  
        next = ptr.next;
        ptr = next;
  
    } while (ptr != head);
}
  
static void printList(Node head)
{
    Node temp = head;
    if (head != null) {
        do {
            System.out.printf("%d ", temp.data);
            temp = temp.next;
        } while (temp != head);
    }
}
  
public static void main(String[] args)
{
    Node head = null;

    head = push(head, 20);
    head = push(head, 13);
    head = push(head, 6);
    head = push(head, 34);
    head = push(head, 11);
    head = push(head, 9);
  
    deleteFibonacciNodes(head);
  
    printList(head);
}
}    `,
  },
  {
    id: 12,
    day: "Day 12",
    no: "Problem 12",
    ps:
      "Sum and Product of the nodes of a Circular Singly Linked List which are divisible by K",
    testCase:
      "Input:- 5->6->7->8->9->10->11->11 K=11 Output:- Sum = 22 Product = 121",
    done: true,
    text: `#Sum and Product of the nodes of a Circular Singly Linked List which are divisible by K
      
import math  
  
class Node:  
    def __init__(self, data):  
        self.data = data  
        self.next = None
  
def sumProduct(head, key): 
    current = head 
  
    sum = 0
    product = 1

    if (head == None): 
        print("Display List is empty") 
        return
    else : 
        if ((current.data) % key == 0) : 
            sum = sum + current.data 
            product = product * current.data 
  
        current = current.next
        while (current != head): 
            if ((current.data) % key == 0) : 
                sum = sum + current.data 
                product = product * current.data 
            current = current.next
    print("Sum =", sum, end = ", ") 
    print("Product =", product) 
  
def displayList(head): 
    current = head 
    if (head == None): 
        print("Display List is empty") 
        return
    else : 
        print(current.data, end = " ") 
        current = current.next
        while (current != head): 
            print(current.data, end = " ") 
            current = current.next

def InsertNode(head, data): 
    current = head     
    newNode = Node(data) 
  
    if (newNode == None): 
        print("Memory Error") 
        return head 
      
    newNode.data = data 
    if (head == None): 
        newNode.next = newNode 
        head = newNode 
        return head 
    else : 
        while (current.next != head): 
            current = current.next
        newNode.next = head 
        current.next = newNode 
      
    return head 
  
if __name__=='__main__':  
    head = None
    head = InsertNode(head, 5)  
    head = InsertNode(head, 6)  
    head = InsertNode(head, 7)  
    head = InsertNode(head, 8)  
    head = InsertNode(head, 9)  
    head = InsertNode(head, 10)  
    head = InsertNode(head, 11)  
    head = InsertNode(head, 11)  
      
    print("Initial List: ", end = "") 
    displayList(head) 
  
    sumProduct(head, 11) `,
    c: `//Sum and Product of the nodes of a Circular Singly Linked List which are divisible by K
      
//No Solution`,
    cplusplus: `//Sum and Product of the nodes of a Circular Singly Linked List which are divisible by K
      
#include <bits/stdc++.h> 
using namespace std; 
  
struct Node { 
    int data; 
    struct Node* next; 
}; 
  
void sumProduct(struct Node* head, int key) 
{ 
    struct Node* current = head; 
  
    int sum = 0, product = 1; 
  
    if (head == NULL) { 
        printf("Display List is empty"); 
        return; 
    } 
    else { 
        do { 
            if ((current->data) % key == 0) { 
                sum += current->data; 
                product *= current->data; 
            } 
            current = current->next; 
        } while (current != head); 
    } 
  
    cout << "Sum = " << sum << ", Product = " << product; 
} 
  
void displayList(struct Node* head) 
{ 
    struct Node* current = head; 
  
    if (head == NULL) { 
        printf("Display List is empty"); 
        return; 
    } 
    else { 
        do { 
            printf("%d ", current->data); 
            current = current->next; 
        } while (current != head); 
    } 
} 
  
void InsertNode(struct Node** head, int data) 
{ 
    struct Node* current = *head; 
    struct Node* newNode = new Node; 
  
    if (!newNode) { 
        printf("Memory Error"); 
        return; 
    } 
    newNode->data = data; 
    if (*head == NULL) { 
        newNode->next = newNode; 
        *head = newNode; 
        return; 
    } 
    else { 
        while (current->next != *head) { 
            current = current->next; 
        } 
        newNode->next = *head; 
        current->next = newNode; 
    } 
} 
  
int main() 
{ 
    struct Node* head = NULL; 
    InsertNode(&head, 5); 
    InsertNode(&head, 6); 
    InsertNode(&head, 7); 
    InsertNode(&head, 8); 
    InsertNode(&head, 9); 
    InsertNode(&head, 10); 
    InsertNode(&head, 11); 
    InsertNode(&head, 11); 
  
    cout << "Initial List: "; 
    displayList(head); 
  
    cout << endl; 
    sumProduct(head, 11); 
  
    return 0; 
}`,
    java: `//Sum and Product of the nodes of a Circular Singly Linked List which are divisible by K
      
import java.util.*; 
class Solution 
{ 
    
static class Node {  
    int data;  
    Node next;  
}  
    
static void sumProduct( Node head, int key)  
{  
    Node current = head;  
    int sum = 0, product = 1;     
    if (head == null) {  
        System.out.print("Display List is empty");  
        return;  
    }  
    else {  
        do {  
            if ((current.data) % key == 0) {  
                sum += current.data;   
                product *= current.data;  
            }
            current = current.next;  
        } while (current != head);  
    }  
    System.out.print( "Sum = " + sum + ", Product = " + product);  
}  
    
static void displayList( Node head)  
{  
     Node current = head;  
    
    if (head == null) {  
        System.out.print("Display List is empty");  
        return;  
    }  
    else {  
        do {  
            System.out.print( current.data+" ");  
            current = current.next;  
        } while (current != head);  
    }  
}  
    
static Node InsertNode( Node head, int data)  
{  
    Node current = head;  
    Node newNode = new Node();  
    if (newNode==null) {  
       System.out.print("Memory Error");  
        return head;  
    }  
    newNode.data = data;  
    if (head == null) {  
        newNode.next = newNode;  
        head = newNode;  
        return head;  
    }  
    else {  
        while (current.next != head) {  
            current = current.next;  
        }  
        newNode.next = head;    
        current.next = newNode;  
    }  
    return head; 
}  
    
public static void main(String args[]) 
{  
     Node head=null ;  
    head =InsertNode(head, 5);  
    head =InsertNode(head, 6);  
    head =InsertNode(head, 7);  
    head =InsertNode(head, 8);  
    head =InsertNode(head, 9);  
    head =InsertNode(head, 10);  
    head =InsertNode(head, 11);  
    head =InsertNode(head, 11);  
    
    System.out.print( "Initial List: ");  
    displayList(head);  
    
    System.out.println();  
    sumProduct(head, 11);  
     
} 
}`,
  },
  {
    id: 13,
    day: "Day 13",
    no: "Problem 13",
    ps: "Find the maximum element from the stack",
    testCase: "Input:- Stack: 2 | 15 | 12 | 22 | 64 | 54  Output:- 64",
    done: true,
    text: `#Find the maximum element from the stack
    
class Node:
    def __init__(self, value):
        self.value = value
        self.next = None
    
    def __str__(self):
        return "Node({})".format(self.value)
      
    __repr__ = __str__
    
class Stack:
    def __init__(self):
        self.top = None
        self.count = 0
        self.maximum = None
          
    def __str__(self):
        temp=self.top
        out=[]
        while temp:
            out.append(str(temp.value))
            temp=temp.next
        out=' '.join(out)
        return ('Top {} Stack :{}'.format(self.top,out))
          
    __repr__=__str__
      
    def getMax(self):
        if self.top is None:
            return "Stack is empty"
        else:
            print("Maximum Element in the stack is: {}" .format(self.maximum))
  
    def isEmpty(self):
        if self.top == None:
            return True
        else:
            return False
  
    def __len__(self):
        self.count = 0
        tempNode = self.top
        while tempNode:
            tempNode = tempNode.next
            self.count+=1
        return self.count
  
    def peek(self):
        if self.top is None:
            print ("Stack is empty")
        else:    
            if self.top.value > self.maximum:
                print("Top Most Element is: {}" .format(self.maximum))
            else:
                print("Top Most Element is: {}" .format(self.top.value))
  
    def push(self,value):
        if self.top is None:
            self.top = Node(value)
            self.maximum = value
              
        elif value > self.maximum :
            temp = (2 * value) - self.maximum
            new_node = Node(temp)
            new_node.next = self.top
            self.top = new_node
            self.maximum = value
        else:
            new_node = Node(value)
            new_node.next = self.top
            self.top = new_node
        print("Number Inserted: {}" .format(value))
    
    def pop(self):
        if self.top is None:
            print( "Stack is empty")
        else:
            removedNode = self.top.value
            self.top = self.top.next
            if removedNode > self.maximum:
                print ("Top Most Element Removed :{} " .format(self.maximum))
                self.maximum = ( ( 2 * self.maximum ) - removedNode )
            else:
                print ("Top Most Element Removed : {}" .format(removedNode))     
              
stack = Stack() 
  
stack.push(3)
stack.push(5) 
stack.getMax()
stack.push(7)
stack.push(19)
stack.getMax()     
stack.pop()
stack.getMax()
stack.pop() 
stack.peek()`,
    cplusplus: `//Find the maximum element from the stack
    
#include <bits/stdc++.h>
using namespace std;
  
struct MyStack {
    stack<int> s;
    int maxEle;
  
    void getMax()
    {
        if (s.empty())
            cout << "Stack is empty";
        else
            cout << "Maximum Element in the stack is: "
                 << maxEle << "";
    }
  
    void peek()
    {
        if (s.empty()) {
            cout << "Stack is empty ";
            return;
        }
  
        int t = s.top();
  
        cout << "Top Most Element is: ";
        (t > maxEle) ? cout << maxEle : cout << t;
    }
  
    void pop()
    {
        if (s.empty()) {
            cout << "Stack is empty";
            return;
        }
  
        cout << "Top Most Element Removed: ";
        int t = s.top();
        s.pop();
  
        if (t > maxEle) {
            cout << maxEle << "";
            maxEle = 2 * maxEle - t;
        }
  
        else
            cout << t << "";
    }
  
    void push(int x)
    {
        if (s.empty()) {
            maxEle = x;
            s.push(x);
            cout << "Number Inserted: " << x << "";
            return;
        }
  
        if (x > maxEle) {
            s.push(2 * x - maxEle);
            maxEle = x;
        }
        else
            s.push(x);
  
        cout << "Number Inserted: " << x << "";
    }
};
  
int main()
{
    MyStack s;
    s.push(3);
    s.push(5);
    s.getMax();
    s.push(7);
    s.push(19);
    s.getMax();
    s.pop();
    s.getMax();
    s.pop();
    s.peek();
  
    return 0;
}`,
    java: `//Find the maximum element from the stack
    
import java.util.*;
  
class A 
{
static class MyStack 
{
    Stack<Integer> s = new Stack<Integer>();
    int maxEle;

    void getMax()
    {
        if (s.empty())
            System.out.print("Stack is empty");
        else
            System.out.print("Maximum Element in" + "the stack is: "+maxEle + "");
  
    }
  
    void peek()
    {
        if (s.empty())
        {
            System.out.print("Stack is empty ");
            return;
        }
  
        int t = s.peek();
  
        System.out.print("Top Most Element is: ");
        if(t > maxEle)
            System.out.print(maxEle);
        else
            System.out.print(t);
    }
  
    void pop()
    {
        if (s.empty()) 
        {
            System.out.print("Stack is empty");
            return;
        }
  
        System.out.print("Top Most Element Removed: ");
        int t = s.peek();
        s.pop();
  
        if (t > maxEle)
        {
            System.out.print(maxEle + "");
            maxEle = 2 * maxEle - t;
        }
        else
            System.out.print(t + "");
    }
  
    void push(int x)
    {
        if (s.empty()) 
        {
            maxEle = x;
            s.push(x);
            System.out.print("Number Inserted: " + x + "");
            return;
        }
  
        if (x > maxEle) 
        {
            s.push(2 * x - maxEle);
            maxEle = x;
        }
        else
            s.push(x);
        System.out.print("Number Inserted: " + x + "");
    }
};
  
public static void main(String[] args) 
{
    MyStack s = new MyStack();
    s.push(3);
    s.push(5);
    s.getMax();
    s.push(7);
    s.push(19);
    s.getMax();
    s.pop();
    s.getMax();
    s.pop();
    s.peek();
    }
}`,
    c: `//Find the maximum element from the stack
    
//No Solution`,
  },
  {
    id: 14,
    day: "Day 14",
    no: "Problem 14",
    ps: "Sorting array using Stacks",
    testCase: "Input :  8 5 7 1 9 12 10 Output : 1 5 7 8 9 10 12 ",
    done: true,
    text: `# Sorting array using Stacks

def sortStack(input):
    tmpStack = []
    while (len(input) > 0):    
        tmp = input[-1]
        input.pop()

        while (len(tmpStack) > 0 and tmpStack[-1] < tmp):
            input.append(tmpStack[-1])
            tmpStack.pop()
        tmpStack.append(tmp)
        
    return tmpStack
  
def sortArrayUsingStacks(arr, n):
    input = []
    i = 0
    while ( i < n ):
        input.append(arr[i])
        i = i + 1
          
    tmpStack = sortStack(input)
    i = 0
      
    while (i < n):
        arr[i] = tmpStack[-1]
        tmpStack.pop()
        i = i + 1
          
    return arr
  
arr = [10, 5, 15, 45]
n = len(arr)
  
arr = sortArrayUsingStacks(arr, n)
i = 0
  
while (i < n):
    print(arr[i] ,end= " ")
    i = i + 1`,
    cplusplus: `// Sorting array using Stacks
    
#include <bits/stdc++.h>
using namespace std;
  
stack<int> sortStack(stack<int> input)
{
    stack<int> tmpStack;
    while (!input.empty())
    {
        int tmp = input.top();
        input.pop();
  
        while (!tmpStack.empty() && tmpStack.top() < tmp)
        {
            input.push(tmpStack.top());
            tmpStack.pop();
        }
        tmpStack.push(tmp);
    } 
    return tmpStack;
}
  
void sortArrayUsingStacks(int arr[], int n)
{
    stack<int> input;
    for (int i=0; i<n; i++)
       input.push(arr[i]);

    stack<int> tmpStack = sortStack(input);
    for (int i=0; i<n; i++)
    {
        arr[i] = tmpStack.top();
        tmpStack.pop();
    }
}
  
int main()
{
    int arr[] = {10, 5, 15, 45};
    int n = sizeof(arr)/sizeof(arr[0]);
  
    sortArrayUsingStacks(arr, n);
  
    for (int i=0; i<n; i++)
       cout << arr[i] << " ";
  
    return 0;
}`,
    c: `// Sorting array using Stacks
    
// No Solution`,
    java: `// Sorting array using Stacks
    
import java.io.*;
import java.util.*;
  
class A
{
    static Stack<Integer> sortStack(Stack<Integer> input)
    {
        Stack<Integer> tmpStack = new Stack<Integer>();
      
        while (!input.empty())
        {
            int tmp = input.peek();
            input.pop();
      
            while (!tmpStack.empty() && tmpStack.peek() < tmp)
            {
                input.push(tmpStack.peek());
                tmpStack.pop();
            }
            tmpStack.push(tmp);
        }
        return tmpStack;
    }
      
    static void sortArrayUsingStacks(int []arr, int n)
    {
        Stack<Integer> input = new Stack<Integer>();
        for (int i = 0; i < n; i++)
            input.push(arr[i]);
      
        Stack<Integer> tmpStack = 
                       sortStack(input);

        for (int i = 0; i < n; i++)
        {
            arr[i] = tmpStack.peek();
            tmpStack.pop();
        }
    }
      
    public static void main(String args[])
    {
        int []arr = {10, 5, 15, 45};
        int n = arr.length;
      
        sortArrayUsingStacks(arr, n);
      
        for (int i = 0; i < n; i++)
            System.out.print(arr[i] + " ");
    }
}`,
  },
  {
    id: 15,
    day: "Day 15",
    no: "Problem 15",
    ps: "Delete middle element of a stack",
    testCase:
      "Input  : Stack[] = [1, 2, 3, 4, 5] Output : Stack[] = [1, 2, 4, 5]",
    done: true,
    text: `# Delete middle element of a stack
    
class Stack:
    def __init__(self):
        self.items = []
       
    def isEmpty(self):
        return self.items == []
       
    def push(self, item):
        self.items.append(item)
       
    def pop(self):
        return self.items.pop()
       
    def peek(self):
        return self.items[len(self.items)-1]
       
    def size(self):
        return len(self.items) 
           
def deleteMid(st, n, curr) :
    if (st.isEmpty() or curr == n) :
        return
       
    x = st.peek()
    st.pop()
       
    deleteMid(st, n, curr+1)
       
    if (curr != int(n/2)) :
        st.push(x)
   
st = Stack()
   
st.push('1')
st.push('2')
st.push('3')
st.push('4')
st.push('5')
st.push('6')
st.push('7')
   
deleteMid(st, st.size(), 0)

while (st.isEmpty() == False) :
    p = st.peek()
    st.pop()
    print (str(p) + " ", end="")`,

    c: `// Delete middle element of a stack
    
// No Solution`,
    cplusplus: `// Delete middle element of a stack
    
#include<bits/stdc++.h>
using namespace std;

void deleteMid(stack<char> &st, int n, int curr=0)
{
   if (st.empty() || curr == n)
     return;
  
   int x = st.top();
   st.pop();
  
   deleteMid(st, n, curr+1);
  
   if (curr != n/2)
     st.push(x);
}
  
int main()
{
    stack<char> st;
  
    st.push('1');
    st.push('2');
    st.push('3');
    st.push('4');
    st.push('5');
    st.push('6');
    st.push('7');
  
    deleteMid(st, st.size());

    while (!st.empty())
    {
        char p=st.top();
        st.pop();
        cout << p << " ";
    }
    return 0;
}`,
    java: `// Delete middle element of a stack

import java.io.*;
import java.util.*;
  
public class A {
    static void deleteMid(Stack<Character> st, int n, int curr)
    {
        if (st.empty() || curr == n)
            return;
          
        char x = st.pop();
          
        deleteMid(st, n, curr+1);
          
        if (curr != n/2)
            st.push(x);
    }
      
    public static void main(String args[]) 
    {
        Stack<Character> st = new Stack<Character>();

        st.push('1');
        st.push('2');
        st.push('3');
        st.push('4');
        st.push('5');
        st.push('6');
        st.push('7');
      
        deleteMid(st, st.size(), 0);

        while (!st.empty())
        {
            char p=st.pop();
            System.out.print(p + " ");
        }
    }
}`,
  },
  {
    id: 16,
    day: "Day 16",
    no: "Problem 16",
    ps: "Reverse all the elements of Queue",
    testCase: `
    Input :  Q = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
      Output : Q = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]`,
    done: true,
    text: `# Reverse all the elements of Queue

from queue import Queue 
  
def Print(queue):
    while (not queue.empty()):
        print(queue.queue[0], end = ", ") 
        queue.get()
  
def reversequeue(queue):
    Stack = [] 
    while (not queue.empty()): 
        Stack.append(queue.queue[0]) 
        queue.get()
    while (len(Stack) != 0): 
        queue.put(Stack[-1]) 
        Stack.pop()
  
if __name__ == '__main__':
    queue = Queue()
    queue.put(10) 
    queue.put(20) 
    queue.put(30) 
    queue.put(40) 
    queue.put(50) 
    queue.put(60) 
    queue.put(70) 
    queue.put(80) 
    queue.put(90) 
    queue.put(100) 
  
    reversequeue(queue) 
    Print(queue)    `,
    cplusplus: `// Reverse all the elements of Queue
    
#include <bits/stdc++.h>
using namespace std;
  
void Print(queue<int>& Queue)
{
    while (!Queue.empty()) {
        cout << Queue.front() << " ";
        Queue.pop();
    }
}
  
void reverseQueue(queue<int>& Queue)
{
    stack<int> Stack;
    while (!Queue.empty()) {
        Stack.push(Queue.front());
        Queue.pop();
    }
    while (!Stack.empty()) {
        Queue.push(Stack.top());
        Stack.pop();
    }
}
  
int main()
{
    queue<int> Queue;
    Queue.push(10);
    Queue.push(20);
    Queue.push(30);
    Queue.push(40);
    Queue.push(50);
    Queue.push(60);
    Queue.push(70);
    Queue.push(80);
    Queue.push(90);
    Queue.push(100);
  
    reverseQueue(Queue);
    Print(Queue);
}`,
    java: `// Reverse all the elements of Queue
    
import java.util.LinkedList;
import java.util.Queue;
import java.util.Stack;
  
public class Queue_reverse {
      
    static Queue<Integer> queue;
  
    static void Print()
    {
        while (!queue.isEmpty()) {
            System.out.print( queue.peek() + ", ");
            queue.remove();
        }
    }
  
    static void reversequeue()
    {
        Stack<Integer> stack = new Stack<>();
        while (!queue.isEmpty()) {
            stack.add(queue.peek());
            queue.remove();
        }
        while (!stack.isEmpty()) {
            queue.add(stack.peek());
            stack.pop();
        }
    }
  
    public static void main(String args[])
    {
        queue = new LinkedList<Integer>();
        queue.add(10);
        queue.add(20);
        queue.add(30);
        queue.add(40);
        queue.add(50);
        queue.add(60);
        queue.add(70);
        queue.add(80);
        queue.add(90);
        queue.add(100);
  
        reversequeue();
        Print();
    }
}`,
    c: `// Reverse all the elements of Queue

// No Solution`,
  },
];
