import { Box, Image, Text, Flex } from '@chakra-ui/react'
import React from 'react'
import { FaLinkedinIn, FaGithub } from 'react-icons/fa';

const TeamCard = ({ image, name, position, github, linkedin }) => {
    return (
        <Box bg="white" padding={4} borderRadius="25px" boxShadow="xl" className="team" width="250px" m="auto">
            <center>
                <span className="circle">
                    <Box>
                        <Image className="eventimg" src={image} borderRadius="full" width="150px" height="150px" />
                    </Box>
                </span>
            </center>
            <Text fontWeight={900} textAlign="center" fontSize={["sm", "md", "md", "lg"]} mt={6}>{name}</Text>
            <Text fontWeight={400} textAlign="center" fontSize={["sm", "md", "md", "md"]} mt={2}>{position}</Text>
            <Flex align="center" justify="center" mt={4} mb={6}>
                <FaLinkedinIn cursor="pointer" size="25px" className="teamicon" onClick={() => window.open(linkedin)} />&nbsp;&nbsp;&nbsp;
                <FaGithub cursor="pointer" size="25px" className="teamicon" onClick={() => window.open(github)} />
            </Flex>
        </Box>
    )
}

export default TeamCard
