import { Box, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import EventCard from './EventCard';

const LiveEvent = () => {

    let size;

    let widthscreen = window.screen.width
    if (widthscreen <= 1350 && widthscreen >= 541) {
        size = 3
    }
    else {
        size = 4
    }

    return (
        <Box className="desktopview">
            {/* <center>
                <SimpleGrid columns={[1, 1, 2, size]} spacingX={[4, 4, 8, 8]} spacingY={[6, 8]} width="80%" paddingY={6} >
                    <EventCard
                        name='Build Your Professional Portfolio'
                        date='6/6/21'
                        done={false}
                        image='https://ik.imagekit.io/codewarriors/portfolio_qREfBeFbR.png'
                        link='/events/build-professional-portfolio'
                    />
                </SimpleGrid>
            </center> */}
            <Text textAlign="center" fontWeight={800} mt={2} color="#003049" fontSize={["3xl"]} py={10} lineHeight={1.3} >Currently No Live Events</Text>
        </Box>
    )
}

export default LiveEvent
