import { Box, Stack, SimpleGrid, Text, Button, Image, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer/Footer';

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false)
  window.scrollTo(0, 0);

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <Box bg="#F4F5F7">
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />
      <Box bg="#000" height="420px">
        <SimpleGrid columns={[1, 2]} pt={[2, 16]} pb={12}>
          <Box py={[8]} pl={[2, 4, 20, 32]} px={4} maxWidth={650} mt="auto" mb="auto">

            <Text letterSpacing={[1.5]} textAlign="center" fontSize={["xl", "2xl", "3xl", "4xl"]} fontWeight={900} color="white" lineHeight={1.3} fontFamily="Montserrat">CONTACT US</Text>
            <Text py={8} letterSpacing={2} pb={[2, 4]} textAlign="center" fontSize={["xl", "2xl", "3xl"]} fontWeight={800} color="white" lineHeight={1.5} fontFamily="Montserrat">We'd love to help you start exceeding your goals</Text>

          </Box>
          <Box m="auto">
            <Image draggable={false} src="https://ik.imagekit.io/codewarriors/contactus_xchDoYQp1.svg" width={[300, 400, 450, 600]} />
          </Box>
        </SimpleGrid>
      </Box>
      <Image draggable={false} src="https://ik.imagekit.io/codewarriors/bg_k2vRctyaK.png" mt="-1px" width="100%" />
      <Box padding={[6, 6, 10, 12]} className="desktopview">
        <center>
          <Box width={["100%", "100%", "100%", "60%"]} bg="white" borderRadius="15px" boxShadow="xl" >

            <Text pt={6} fontWeight={900} textAlign="center" fontSize={["sm", "md", "md", "xl"]} >GET IN TOUCH WITH US</Text>
            <Text fontWeight={400} textAlign="center" fontSize={["sm", "md", "md", "xl"]} mt={2} >Drop us a line or visit us for cup of coffee!</Text>

            <Stack >

              <Flex display="flex" alignItems="center" justifyContent="center">
                <MdLocationOn size="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                  <Box>
                  <Text textAlign="start" fontFamily="Montserrat" fontWeight={900} fontSize={["sm", "md", "md", "md"]} mt={2}>Surat</Text>
                  <Text textAlign="start" fontFamily="Montserrat" color="gray" fontSize={["14px"]}>Gujarat, India</Text>
                </Box>
              </Flex>

              <Flex display="flex" alignItems="center" justifyContent="center">
                <MdPhone size="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                  <Box>
                  <Text fontWeight={900} fontFamily="Montserrat" textAlign="start" fontSize={["sm", "md", "md", "md"]} mt={2}>+91 7359122836</Text>
                  <Text fontWeight={900} fontFamily="Montserrat" textAlign="start" fontSize={["sm", "md", "md", "md"]}>+91 9601450402</Text>
                  <Text color="gray" textAlign="start" fontFamily="Montserrat" fontSize={["14px"]}>Mon to Fri 9am to 6pm</Text>
                </Box>
              </Flex>

              <Flex display="flex" alignItems="center" justifyContent="center">
                <MdEmail size="25px" />&nbsp;&nbsp;&nbsp;&nbsp;
                  <Box>
                  <Text fontWeight={900} fontFamily="Montserrat" textAlign="start" fontSize={["sm", "md", "md", "md"]} mt={2}>code.warriors.help@gmail.com</Text>
                  <Text color="gray" fontFamily="Montserrat" textAlign="start" fontSize={["14px"]}>Send us your query anytime</Text>
                </Box>
              </Flex>

              <center>
                <Button mb={6} mt={4} size="lg" width="45%" style={{ borderRadius: "25px", backgroundColor: "#46ADBE" }} onClick={() => window.open("https://codewarriors.typeform.com/to/h9lZrbqH")}>
                  <Text color="white" fontSize={["sm", "md", "lg"]} letterSpacing={2} fontWeight={600} fontFamily="Montserrat">Message Us</Text>
                </Button>
              </center>
            </Stack>
          </Box>

        </center>
      </Box>
      {/* <ReactTypeformEmbed url="https://codewarriors.typeform.com/to/h9lZrbqH" hideFooter={false} style={{ width: "100%", height: "500px", position: "relative" }} /> */}

      <Footer />

    </Box>
  );
};

export default Contact;
