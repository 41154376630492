import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { Text, Box, Button, Image, SimpleGrid } from "@chakra-ui/react"
// import { MdCheckCircle } from 'react-icons/md'; , SimpleGrid, List, ListIcon, ListItem
import Sidebar from '../components/Sidebar';
import { Link } from "react-scroll";
import Footer from '../components/Footer/Footer';
import { RiShieldStarFill } from 'react-icons/ri';
import { perks } from '../database/perks';
import { steps } from '../database/steps';

const Community = () => {
  const [isOpen, setIsOpen] = useState(false)
  window.scrollTo(0, 0);
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  let size;

  let widthscreen = window.screen.width;
  if (widthscreen <= 1400 && widthscreen >= 541) {
    size = 3;
  } else {
    size = 4;
  }
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />
      <Box bg="#000" >

        <center>
          <Box id="community">
            <Text color="white" fontWeight={900} fontSize={["lg", "xl", "2xl", "3xl"]} pb={6} letterSpacing={4} pt={[12, 12, 12, 24]}>BECOME A CHAPTER LEAD</Text>
            {/* <Text color="white" fontWeight={900} fontSize={["lg", "xl", "2xl", "3xl"]} letterSpacing={4} pb={6}>MENTOR</Text> */}

            <Text color="white" paddingX={[10, 12]} fontWeight={[200, 400]} fontSize={["md", "lg", "xl", "xl"]} pb={10}>Become the face of Code Warriors for your campus, win swags, T-Shirts & much more!</Text>

            <Link
              to="lead"
              spy={true}
              smooth={true}
              offset={-125}
              duration={1000}
            >
              <Button size="lg" style={{ borderRadius: "25px", border: "3px solid", backgroundColor: "#46ADBE" }}>
                <Text color="white" fontSize={["md", "lg", "xl"]} letterSpacing={2} fontWeight={600} fontFamily="Montserrat">Know More</Text>
              </Button>
            </Link>
            <br />
            <Image draggable={false} src="https://ik.imagekit.io/codewarriors/Community1_dOS6Hv4xp.svg" pt={8} width={600} id="communityimg" />
          </Box>
        </center>
      </Box>
      <Image draggable={false} mt="-1px" src="https://ik.imagekit.io/codewarriors/community_JpQE0Tmxo.png" width="100%" />




      <center>
        <Box paddingX={[8, 12, 20, 36]} className='lead'>
          <Box mt={6}>
            <Text fontWeight={900} textAlign='justify' fontSize={["lg", "xl", "xl", "2xl"]} letterSpacing="1px">About Chapter Lead Program</Text>
            <Text fontSize={["md", "lg", "lg"]} textAlign='justify' fontWeight={400} fontFamily="Montserrat" pt={2}>
              Chapter Leads are those passionate people who wish to contribute to the community. Our Chapter Leads are highly skilled, motivated individuals and leaders in their own campus. If you think you are someone who is curious to learn new skills, make new friends, spread knowledge and believes in getting things done, then this program is just perfect for you.
            </Text>
          </Box>

          <Box mt={12}>
            <Text fontWeight={900} textAlign='justify' fontSize={["lg", "xl", "xl", "2xl"]} letterSpacing="1px">Perks of a Chapter Lead</Text>
            <Text fontSize={["md", "lg", "lg"]} textAlign='justify' fontWeight={400} fontFamily="Montserrat" pt={2}>
              Some of the benefits that you will enjoy exclusively as Chapter Lead!
            </Text>

            <SimpleGrid columns={[1, 2, 2, size]} spacingX={6} mt={6} spacingY={6}>
              {perks.map(perk =>
                <Box bg='white' boxShadow='xl' textAlign='center' id='mission' p={2}>
                  <center>
                    <Box mt='20px' mb='20px'>
                      <Image src={perk.icon} height='70px' width='70px' />
                    </Box>
                    <Text fontWeight={900} fontSize={['sm', 'md', 'md', 'lg']} mt={4}>
                      {perk.title}
                    </Text>
                    <Text
                      fontWeight={400}
                      fontSize={['sm', 'md', 'md', '15px']}
                      mb='35px'
                      mt={4}
                    >
                      {perk.description}
                    </Text>
                  </center>
                </Box>
              )}
            </SimpleGrid>
          </Box>

          <Box mt={16}>
            <Text fontWeight={900} textAlign='justify' fontSize={["lg", "xl", "xl", "2xl"]} letterSpacing="1px">Application Timeline</Text>
            <Text fontSize={["md", "lg", "lg"]} textAlign='justify' fontWeight={400} fontFamily="Montserrat" pt={2}>
              Apply & Get Shortlisted for Chapter Lead Program in 4 simple steps.
            </Text>

            <SimpleGrid columns={[1, 2, 2, size]} spacingX={6} mt={6} spacingY={6}>
              {steps.map(step =>
                <Box bg='white' boxShadow='xl' textAlign='center' id='mission' p={2}>
                  <center>
                    <Box mt='20px' mb='20px'>
                      <Image src={step.icon} height='70px' width='70px' />
                    </Box>
                    <Text fontWeight={900} fontSize={['sm', 'md', 'md', 'lg']} mt={6}>
                      {step.title}
                    </Text>
                    <Text
                      fontWeight={400}
                      fontSize={['sm', 'md', 'md', '15px']}
                      mb='35px'
                      mt={4}
                    >
                      {step.description}
                    </Text>
                  </center>
                </Box>
              )}
            </SimpleGrid>
          </Box>

          <Box mt={16}>
            <Text fontWeight={900} textAlign='justify' fontSize={["lg", "xl", "2xl", "2xl"]} letterSpacing="1px">Start Your Application</Text>
            <Text fontSize={["md", "lg", "lg"]} textAlign='justify' fontWeight={400} fontFamily="Montserrat" pt={2} mb={4}>
              {/* Chapter Lead Applications are currently open. Complete the application form to hear back from us. */}
              Chapter Lead Applications will be starting soon. Stay Tuned!!
            </Text>

            <Button disabled size="lg" mt={4} style={{ borderRadius: "25px", backgroundColor: "#46ADBE" }} onClick={() => window.open("/chapterlead/register")}>
              <Text color="white" fontSize={["md", "lg", "xl"]} letterSpacing={2} fontWeight={600} fontFamily="Montserrat">Apply Now</Text>
            </Button>
          </Box>
        </Box>
      </center>


      {/* <SimpleGrid columns={[1, 1, 1, 2]} spacingX={6} p={6} spacingY={6}>
        <Box className="bounce">
          <center>
            <Image src={require('../images/lead.svg')} width="70%" />
          </center>
        </Box>

        <Box m="auto" width="85%" textAlign="justify">
          <Text as="u" fontWeight={900} fontSize={["lg", "xl", "2xl", "3xl"]} letterSpacing="1px">BECOME A COMMUNITY LEAD</Text>
          <Text fontSize={["md", "lg", "lg"]} fontWeight={400} fontFamily="Montserrat" pt={2}>
            DevScript Student Partners program is aimed to identify energetic & passionate tech geeks who aspire to take the initiative and make a mark. Be the face & voice of DevScript at your campus. We encourage you to come with innovative ideas to accomplish each of your objectives as a Unit. Amplify your impact and bring together your peers to learn new skills.
          </Text>

          <Text fontWeight={600} fontSize={["lg", "xl", "2xl", "xl"]} pt={4} fontFamily="Montserrat">Roles and Responsibilities of a DevScript Student Partner :</Text>
          <List spacing={1} pt={2} >

            <ListItem fontFamily="Montserrat" fontWeight={400} >
              <ListIcon as={MdCheckCircle} color="green.500" />
    Lorem ipsum dolor sit amet, consectetur adipisicing elit
            </ListItem>

            <ListItem fontFamily="Montserrat" fontWeight={400}>
              <ListIcon as={MdCheckCircle} color="green.500" />
    Assumenda, quia temporibus eveniet a libero incidunt suscipit
            </ListItem>

            <ListItem fontFamily="Montserrat" fontWeight={400}>
              <ListIcon as={MdCheckCircle} color="green.500" />
    Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
            </ListItem>

          </List>

          <Button size="md" style={{ borderRadius: "25px", backgroundColor: "#46ADBE" }} mt={4}>
            <Text color="white" fontSize={["md", "lg", "xl"]} letterSpacing={2} fontWeight={600} fontFamily="Montserrat">Apply Now</Text>
          </Button>
        </Box>

      </SimpleGrid> */}

      {/* <hr /> */}
      {/* <SimpleGrid columns={[1, 1, 1, 2]} p={6} spacingY={6} pt={12}>


        <Box m="auto" width="85%" textAlign="justify">
          <Text as="u" fontWeight={900} fontSize={["lg", "xl", "2xl", "3xl"]} letterSpacing="1px">BECOME A COMMUNITY LEAD</Text>
          <Text fontSize={["md", "lg", "lg"]} fontWeight={400} fontFamily="Montserrat" pt={2}>
            DevScript Student Partners program is aimed to identify energetic & passionate tech geeks who aspire to take the initiative and make a mark. Be the face & voice of DevScript at your campus. We encourage you to come with innovative ideas to accomplish each of your objectives as a Unit. Amplify your impact and bring together your peers to learn new skills.
          </Text>

          <Text fontWeight={600} fontSize={["lg", "xl", "2xl", "xl"]} pt={4} fontFamily="Montserrat">Roles and Responsibilities of a DevScript Student Partner :</Text>
          <List spacing={1} pt={2} >

            <ListItem fontFamily="Montserrat" fontWeight={400} >
              <ListIcon as={MdCheckCircle} color="green.500" />
    Lorem ipsum dolor sit amet, consectetur adipisicing elit
            </ListItem>

            <ListItem fontFamily="Montserrat" fontWeight={400}>
              <ListIcon as={MdCheckCircle} color="green.500" />
    Assumenda, quia temporibus eveniet a libero incidunt suscipit
            </ListItem>

            <ListItem fontFamily="Montserrat" fontWeight={400}>
              <ListIcon as={MdCheckCircle} color="green.500" />
    Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
            </ListItem>

          </List>

          <Button size="md" style={{ borderRadius: "25px", backgroundColor: "#46ADBE" }} mt={4}>
            <Text color="white" fontSize={["md", "lg", "xl"]} letterSpacing={2} fontWeight={600} fontFamily="Montserrat">Apply Now</Text>
          </Button>
        </Box>

        <Box className="bounce">
          <center>
            <Image src={require('../images/lead.svg')} width="70%" />
          </center>
        </Box>



      </SimpleGrid> */}

      <Footer />
    </>
  );
};

export default Community;
