import enrollment from '../images/enrollment.png';
import shortlist from '../images/shortlist.png';
import interview from '../images/interview.png';
import smile from '../images/smile.png';

export const steps = [
  {
    icon: enrollment,
    title: 'Apply',
    description: 'Fill out your details in the Google Form attached below.'
  },
  {
    icon: shortlist,
    title: 'Profile Shortlisting',
    description: 'We will review your profile and based on it you will be shortlisted.'
  },
  {
    icon: interview,
    title: 'Telephonic Interview',
    description: 'We will schedule a phone call with you to learn about you and your experiences.'
  },
  {
    icon: smile,
    title: 'Get Offer Letter',
    description: 'Once we are convinced that you are good fit for this role, you will get your offer letter.'
  },
]