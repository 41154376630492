export const data1 = [
    {
        id: 17,
        day: 'Day 1',
        no: 'Problem 1',
        ps: 'Interleave the first half of the queue with second half',
        testCase: `Input : 11 12 13 14 15 16 17 18 19 20
      Output : 11 16 12 17 13 18 14 19 15 20`,
        done: true,
        text: `# Interleave the first half of the queue with second half
    
from queue import Queue 
  
def interLeaveQueue(q):
    if (q.qsize() % 2 != 0): 
        print("Input even number of integers.")

    s = []
    halfSize = int(q.qsize() / 2) 
  
    for i in range(halfSize):
        s.append(q.queue[0]) 
        q.get()
  
    while len(s) != 0: 
        q.put(s[-1]) 
        s.pop()

    for i in range(halfSize):
        q.put(q.queue[0]) 
        q.get()
  
    for i in range(halfSize):
        s.append(q.queue[0]) 
        q.get()
  
    while len(s) != 0: 
        q.put(s[-1]) 
        s.pop() 
        q.put(q.queue[0]) 
        q.get()
  
if __name__ == '__main__':
    q = Queue()
    q.put(11) 
    q.put(12) 
    q.put(13) 
    q.put(14) 
    q.put(15) 
    q.put(16) 
    q.put(17) 
    q.put(18) 
    q.put(19) 
    q.put(20) 
    interLeaveQueue(q) 
    length = q.qsize()
    for i in range(length):
        print(q.queue[0], end = " ") 
        q.get()`,
        c: `// Interleave the first half of the queue with second half
    
// No Solution`,
        cplusplus: `// Interleave the first half of the queue with second half
    
#include <bits/stdc++.h>
using namespace std;
  
void interLeaveQueue(queue<int>& q)
{
    if (q.size() % 2 != 0)
        cout << "Input even number of integers." << endl;
  
    stack<int> s;
    int halfSize = q.size() / 2;
  
    for (int i = 0; i < halfSize; i++) {
        s.push(q.front());
        q.pop();
    }
  
    while (!s.empty()) {
        q.push(s.top());
        s.pop();
    }
  
    for (int i = 0; i < halfSize; i++) {
        q.push(q.front());
        q.pop();
    }
  
    for (int i = 0; i < halfSize; i++) {
        s.push(q.front());
        q.pop();
    }
  
    while (!s.empty()) {
        q.push(s.top());
        s.pop();
        q.push(q.front());
        q.pop();
    }
}
  
int main()
{
    queue<int> q;
    q.push(11);
    q.push(12);
    q.push(13);
    q.push(14);
    q.push(15);
    q.push(16);
    q.push(17);
    q.push(18);
    q.push(19);
    q.push(20);
    interLeaveQueue(q);
    int length = q.size();
    for (int i = 0; i < length; i++) {
        cout << q.front() << " ";
        q.pop();
    }
    return 0;
}`,
        java: `// Interleave the first half of the queue with second half
    
import java.util.*;
  
class A 
{
  static void interLeaveQueue(Queue<Integer>q)
  {
      if (q.size() % 2 != 0)
          System.out.println("Input even number of integers." );
    
      Stack<Integer> s = new Stack<>();
      int halfSize = q.size() / 2;
    
      for (int i = 0; i < halfSize; i++)
      {
          s.push(q.peek());
          q.poll();
      }
    
      while (!s.empty()) 
      {
          q.add(s.peek());
          s.pop();
      }
    
      for (int i = 0; i < halfSize; i++) 
      {
          q.add(q.peek());
          q.poll();
      }
    
      for (int i = 0; i < halfSize; i++)
      {
          s.push(q.peek());
          q.poll();
      }
    
      while (!s.empty())
      {
          q.add(s.peek());
          s.pop();
          q.add(q.peek());
          q.poll();
      }
  }
    
  public static void main(String[] args) 
  {
      Queue<Integer> q = new java.util.LinkedList<>();
      q.add(11);
      q.add(12);
      q.add(13);
      q.add(14);
      q.add(15);
      q.add(16);
      q.add(17);
      q.add(18);
      q.add(19);
      q.add(20);
      interLeaveQueue(q);
      int length = q.size();
      for (int i = 0; i < length; i++) 
      {
          System.out.print(q.peek() + " ");
          q.poll();
      }
  }
}`,
    },
    {
        id: 18,
        day: 'Day 2',
        no: 'Problem 2',
        ps: 'Reversing the first K elements of a Queue',
        testCase: `Input : Q = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    k = 5
Output : Q = [50, 40, 30, 20, 10, 60, 
         70, 80, 90, 100]`,
        done: true,
        text: `# Reversing the first K elements of a Queue

from queue import Queue
 
def reverseQueueFirstKElements(k, Queue):
    if (Queue.empty() == True or k > Queue.qsize()):
        return
    if (k <= 0):
        return
 
    Stack = []
 
    for i in range(k):
        Stack.append(Queue.queue[0])
        Queue.get()

    while (len(Stack) != 0 ):
        Queue.put(Stack[-1])
        Stack.pop()

    for i in range(Queue.qsize() - k):
        Queue.put(Queue.queue[0])
        Queue.get()
 
def Print(Queue):
    while (not Queue.empty()):
        print(Queue.queue[0], end =" ")
        Queue.get()
 
if __name__ == '__main__':
    Queue = Queue()
    Queue.put(10)
    Queue.put(20)
    Queue.put(30)
    Queue.put(40)
    Queue.put(50)
    Queue.put(60)
    Queue.put(70)
    Queue.put(80)
    Queue.put(90)
    Queue.put(100)
 
    k = 5
    reverseQueueFirstKElements(k, Queue)
    Print(Queue)    `,
        c: `// Reversing the first K elements of a Queue
    
// No Solution`,
        cplusplus: `// Reversing the first K elements of a Queue
    
#include <bits/stdc++.h>
using namespace std;
 
void reverseQueueFirstKElements(int k, queue<int>& Queue)
{
    if (Queue.empty() == true || k > Queue.size())
        return;
    if (k <= 0)
        return;
 
    stack<int> Stack;

    for (int i = 0; i < k; i++) {
        Stack.push(Queue.front());
        Queue.pop();
    }

    while (!Stack.empty()) {
        Queue.push(Stack.top());
        Stack.pop();
    }
 
    for (int i = 0; i < Queue.size() - k; i++) {
        Queue.push(Queue.front());
        Queue.pop();
    }
}
 
void Print(queue<int>& Queue)
{
    while (!Queue.empty()) {
        cout << Queue.front() << " ";
        Queue.pop();
    }
}
 
int main()
{
    queue<int> Queue;
    Queue.push(10);
    Queue.push(20);
    Queue.push(30);
    Queue.push(40);
    Queue.push(50);
    Queue.push(60);
    Queue.push(70);
    Queue.push(80);
    Queue.push(90);
    Queue.push(100);
 
    int k = 5;
    reverseQueueFirstKElements(k, Queue);
    Print(Queue);
}`,
        java: `// Reversing the first K elements of a Queue
    
import java.util.LinkedList;
import java.util.Queue;
import java.util.Stack;
 
public class Reverse_k_element_queue {
 
    static Queue<Integer> queue;
 
    static void reverseQueueFirstKElements(int k)
    {
        if (queue.isEmpty() == true || k > queue.size())
            return;
        if (k <= 0)
            return;
 
        Stack<Integer> stack = new Stack<Integer>();
 
        for (int i = 0; i < k; i++) {
            stack.push(queue.peek());
            queue.remove();
        }
 
        while (!stack.empty()) {
            queue.add(stack.peek());
            stack.pop();
        }
 
        for (int i = 0; i < queue.size() - k; i++) {
            queue.add(queue.peek());
            queue.remove();
        }
    }
 
    static void Print()
    {
        while (!queue.isEmpty()) {
            System.out.print(queue.peek() + " ");
            queue.remove();
        }
    }
 
    public static void main(String args[])
    {
        queue = new LinkedList<Integer>();
        queue.add(10);
        queue.add(20);
        queue.add(30);
        queue.add(40);
        queue.add(50);
        queue.add(60);
        queue.add(70);
        queue.add(80);
        queue.add(90);
        queue.add(100);
 
        int k = 5;
        reverseQueueFirstKElements(k);
        Print();
    }
}`,
    },
    {
        id: 19,
        day: 'Day 3',
        no: 'Problem 3',
        ps: 'Implement the insertion of a node in a Binary Search Tree.',
        testCase: '',
        done: true,
        text: `# Implement the insertion of a node in a Binary Search Tree
    
class Node:
    def __init__(self, key):
        self.left = None
        self.right = None
        self.val = key

def insert(root, key):
    if root is None:
        return Node(key)
    else:
        if root.val == key:
            return root
        elif root.val < key:
            root.right = insert(root.right, key)
        else:
            root.left = insert(root.left, key)
    return root

r = Node(50)
r = insert(r, 30)
r = insert(r, 20)
r = insert(r, 40)
r = insert(r, 70)
r = insert(r, 60)
r = insert(r, 80)
`,
        c: `// Implement the insertion of a node in a Binary Search Tree
    
#include <stdio.h>
#include <stdlib.h>
 
struct node {
    int key;
    struct node *left, *right;
};
 
struct node* newNode(int item)
{
    struct node* temp = (struct node*)malloc(sizeof(struct node));
    temp->key = item;
    temp->left = temp->right = NULL;
    return temp;
}

struct node* insert(struct node* node, int key)
{
    if (node == NULL)
        return newNode(key);
 
    if (key < node->key)
        node->left = insert(node->left, key);
    else if (key > node->key)
        node->right = insert(node->right, key);
 
    return node;
}
 
int main()
{
    struct node* root = NULL;
    root = insert(root, 50);
    insert(root, 30);
    insert(root, 20);
    insert(root, 40);
    insert(root, 70);
    insert(root, 60);
    insert(root, 80);
  
    return 0;
}`,
        cplusplus: `// Implement the insertion of a node in a Binary Search Tree
    
#include <iostream>
using namespace std;
 
class BST
{
    int data;
    BST *left, *right;
 
public:
    BST();
    BST(int);
    BST* Insert(BST*, int);
};
 
BST ::BST()
    : data(0)
    , left(NULL)
    , right(NULL)
{
}
 
BST ::BST(int value)
{
    data = value;
    left = right = NULL;
}
 
BST* BST ::Insert(BST* root, int value)
{
    if (!root)
    {
        return new BST(value);
    }
 
    if (value > root->data)
    {
        root->right = Insert(root->right, value);
    }
    else
    {
        root->left = Insert(root->left, value);
    }
    return root;
}

int main()
{
    BST b, *root = NULL;
    root = b.Insert(root, 50);
    b.Insert(root, 30);
    b.Insert(root, 20);
    b.Insert(root, 40);
    b.Insert(root, 70);
    b.Insert(root, 60);
    b.Insert(root, 80);
 
    return 0;
}`,
        java: `// Implement the insertion of a node in a Binary Search Tree
    
#include <stdio.h>
#include <stdlib.h>
 
struct node {
    int key;
    struct node *left, *right;
};
 
struct node* newNode(int item)
{
    struct node* temp = (struct node*)malloc(sizeof(struct node));
    temp->key = item;
    temp->left = temp->right = NULL;
    return temp;
}
 
struct node* insert(struct node* node, int key)
{
    if (node == NULL)
        return newNode(key);
 
    if (key < node->key)
        node->left = insert(node->left, key);
    else if (key > node->key)
        node->right = insert(node->right, key);
 
    return node;
}
 
int main()
{
    struct node* root = NULL;
    root = insert(root, 50);
    insert(root, 30);
    insert(root, 20);
    insert(root, 40);
    insert(root, 70);
    insert(root, 60);
    insert(root, 80);
  
    return 0;
}`,
    },
    {
        id: 20,
        day: 'Day 4',
        no: 'Problem 4',
        ps:
            'Convert a Binary Search Tree into a Skewed tree in increasing or decreasing order',
        testCase: '',
        done: true,
        cplusplus: `// Convert a Binary Search Tree into a Skewed tree in increasing or decreasing order
    
#include<bits/stdc++.h>
using namespace std;
 
struct Node
{
    int val;
    Node *left, *right;
 
    Node(int x)
    {
        val = x;
        left = right = NULL;
    }
};
 
Node *prevNode = NULL;
Node *headNode = NULL;
 
void flattenBTToSkewed(Node *root, int order)
{
    if (!root)
        return;

    if (order)
        flattenBTToSkewed(root->right, order);
    else
        flattenBTToSkewed(root->left, order);
 
    Node *rightNode = root->right;
    Node *leftNode = root->left;

    if (!headNode)
    {
        headNode = root;
        root->left = NULL;
        prevNode = root;
    }
    else
    {
        prevNode->right = root;
        root->left = NULL;
        prevNode = root;
    }

    if (order)
        flattenBTToSkewed(leftNode, order);
    else
        flattenBTToSkewed(rightNode, order);
}
 
void traverseRightSkewed(Node *root)
{
    if (!root)
        return;
         
    cout << root->val << " ";
    traverseRightSkewed(root->right);
}
 
int main()
{
    Node *root =new Node(5);
    root->left = new Node(3);
    root->right = new Node(6);

    int order = 0;
 
    flattenBTToSkewed(root, order);
 
    traverseRightSkewed(headNode);
}`,
        c: `// Convert a Binary Search Tree into a Skewed tree in increasing or decreasing order
    
// No Solution`,
        text: `# Convert a Binary Search Tree into a Skewed tree in increasing or decreasing order
    
class Node:
    def __init__(self, val):
        self.val = val
        self.left = None
        self.right = None
            
prevNode = None
headNode = None
    
def flattenBTToSkewed(root, order):
    if not root:
        return

    if order:
        flattenBTToSkewed(root.right, order)
    else:
        flattenBTToSkewed(root.left, order)
        
    global headNode; global prevNode
    rightNode = root.right
    leftNode = root.left
    
    if not headNode:
        headNode = root
        root.left = None
        prevNode = root
    else:
        prevNode.right = root
        root.left = None
        prevNode = root

    if order:
        flattenBTToSkewed(leftNode, order)
    else:
        flattenBTToSkewed(rightNode, order)

def traverseRightSkewed(root):
    if not root:
        return
    print(root.val, end = " ")
    traverseRightSkewed(root.right)
 
if __name__ == "__main__":
    root = Node(5)
    root.left = Node(3)
    root.right = Node(6)
     
    prevNode = None
    headNode = None
     
    order = 0
     
    flattenBTToSkewed(root, order)
     
    traverseRightSkewed(headNode)`,
        java: `// Convert a Binary Search Tree into a Skewed tree in increasing or decreasing order
    
import java.io.*;
 
class Node
{
    int val;
    Node left, right;

    Node(int item)
    {
        val = item;
        left = right = null;
    }
}
class A
{
    public static Node node;
    static Node prevNode = null;
    static Node headNode = null;

    static void flattenBTToSkewed(Node root, int order)
    {
        if(root == null)
        {
            return;
        }

        if(order > 0)
        {
            flattenBTToSkewed(root.right, order);
        }
        else
        {
            flattenBTToSkewed(root.left, order);
        }
        Node rightNode = root.right;
        Node leftNode = root.left;

        if(headNode == null)
        {
            headNode = root;
            root.left = null;
            prevNode = root;
        }
        else
        {
            prevNode.right = root;
            root.left = null;
            prevNode = root;
        }

        if (order > 0)
        {
            flattenBTToSkewed(leftNode, order);
        }
        else
        {
            flattenBTToSkewed(rightNode, order);
        }
    }

    static void traverseRightSkewed(Node root)
    {
        if(root == null)
        {
            return;
        }
        System.out.print(root.val + " ");
        traverseRightSkewed(root.right);       
    }
   
    public static void main (String[] args)
    {
        A tree = new A();
        tree.node = new Node(5);
        tree.node.left = new Node(3);
        tree.node.right = new Node(6);
 
        int order = 0;
        flattenBTToSkewed(node, order);
        traverseRightSkewed(headNode);
    }
}`,
    },

    {
        id: 21,
        day: 'Day 5',
        no: 'Problem 5',
        ps: 'Find maximum (or minimum) in Binary Tree',
        testCase: '',
        done: true,
        text: `# Find maximum (or minimum) in Binary Tree
    
class newNode:
    def __init__(self, data):
        self.data = data
        self.left = self.right = None
  
def findMax(root):
    if (root == None):
        return float('-inf')
  
    res = root.data
    lres = findMax(root.left)
    rres = findMax(root.right)
    if (lres > res):
        res = lres
    if (rres > res):
        res = rres
    return res
  
if __name__ == '__main__':
    root = newNode(2)
    root.left = newNode(7)
    root.right = newNode(5)
    root.left.right = newNode(6)
    root.left.right.left = newNode(1)
    root.left.right.right = newNode(11)
    root.right.right = newNode(9)
    root.right.right.left = newNode(4)
  
    print("Maximum element is", findMax(root))`,

        c: `// Find maximum (or minimum) in Binary Tree
    
#include <limits.h>
#include <stdio.h>
#include <stdlib.h>
  
struct Node {
    int data;
    struct Node *left, *right;
};
  
struct Node* newNode(int data)
{
    struct Node* node = (struct Node*)malloc(sizeof(struct Node));
    node->data = data;
    node->left = node->right = NULL;
    return (node);
}
  
int findMax(struct Node* root)
{
    if (root == NULL)
        return INT_MIN;

    int res = root->data;
    int lres = findMax(root->left);
    int rres = findMax(root->right);
    if (lres > res)
        res = lres;
    if (rres > res)
        res = rres;
    return res;
}
  
int main(void)
{
    struct Node* NewRoot = NULL;
    struct Node* root = newNode(2);
    root->left = newNode(7);
    root->right = newNode(5);
    root->left->right = newNode(6);
    root->left->right->left = newNode(1);
    root->left->right->right = newNode(11);
    root->right->right = newNode(9);
    root->right->right->left = newNode(4);
  
    printf("Maximum element is %d", findMax(root));
  
    return 0;
}`,
        cplusplus: `// Find maximum (or minimum) in Binary Tree
    
#include <bits/stdc++.h>
#include <iostream>
using namespace std;
  
class Node {
public:
    int data;
    Node *left, *right;

    Node(int data)
    {
        this->data = data;
        this->left = NULL;
        this->right = NULL;
    }
};
  
int findMax(Node* root)
{
    if (root == NULL)
        return INT_MIN;

    int res = root->data;
    int lres = findMax(root->left);
    int rres = findMax(root->right);
    if (lres > res)
        res = lres;
    if (rres > res)
        res = rres;
    return res;
}
  
int main()
{
    Node* NewRoot = NULL;
    Node* root = new Node(2);
    root->left = new Node(7);
    root->right = new Node(5);
    root->left->right = new Node(6);
    root->left->right->left = new Node(1);
    root->left->right->right = new Node(11);
    root->right->right = new Node(9);
    root->right->right->left = new Node(4);
  
    cout << "Maximum element is " << findMax(root) << endl;
  
    return 0;
}`,
        java: `// Find maximum (or minimum) in Binary Tree
    
class Node {
    int data;
    Node left, right;
  
    public Node(int data)
    {
        this.data = data;
        left = right = null;
    }
}
  
class BinaryTree {
    Node root;
  
    static int findMax(Node node)
    {
        if (node == null)
            return Integer.MIN_VALUE;
  
        int res = node.data;
        int lres = findMax(node.left);
        int rres = findMax(node.right);
  
        if (lres > res)
            res = lres;
        if (rres > res)
            res = rres;
        return res;
    }
  
    public static void main(String args[])
    {
        BinaryTree tree = new BinaryTree();
        tree.root = new Node(2);
        tree.root.left = new Node(7);
        tree.root.right = new Node(5);
        tree.root.left.right = new Node(6);
        tree.root.left.right.left = new Node(1);
        tree.root.left.right.right = new Node(11);
        tree.root.right.right = new Node(9);
        tree.root.right.right.left = new Node(4);
  
        System.out.println("Maximum element is " + tree.findMax(tree.root));
    }
}`,
    },

    {
        id: 22,
        day: 'Day 6',
        no: 'Problem 6',
        ps: 'Count single node isolated sub-graphs in a disconnected graph',
        testCase: '',
        done: true,
        text: `# Count single node isolated sub-graphs in a disconnected graph
    
def compute(graph, N):
    count = 0 
    
    for i in range(1, N+1):
        if (len(graph[i]) == 0):
            count += 1    
    
    return count
    
if __name__ == '__main__':
  
    N = 6 
    
    graph = [[] for i in range(7)] 
    
    graph[1].append(2) 
    graph[2].append(1) 
    
    graph[2].append(3) 
    graph[3].append(2) 
    
    graph[5].append(6) 
    graph[6].append(5) 
    
    print(compute(graph, N))`,
        c: `// Count single node isolated sub-graphs in a disconnected graph
    
// No Solution`,
        cplusplus: `// Count single node isolated sub-graphs in a disconnected graph
    
#include <bits/stdc++.h>
using namespace std;
  
int compute(vector<int> graph[], int N)
{
    int count = 0;
  
    for (int i = 1; i <= N; i++)
        if (graph[i].size() == 0)
            count++;    
  
    return count;
}

int main()
{
    int N = 6;
  
    vector<int> graph[7];
  
    graph[1].push_back(2);
    graph[2].push_back(1);
  
    graph[2].push_back(3);
    graph[3].push_back(2);
  
    graph[5].push_back(6);
    graph[6].push_back(5);
  
    cout << compute(graph, N);
}`,
        java: `// Count single node isolated sub-graphs in a disconnected graph
    
import java.util.*;
  
class A
{
static int compute(int []graph, int N) 
{ 
    int count = 0; 
      
    for (int i = 1; i < 7; i++) 
    {
        if (graph[i] == 0) 
            count++;     
    }
          
    return count; 
} 
  
public static void main(String[] args)
{
    int N = 6; 
  
    int []graph = new int[7];
    graph[1] = 2;
    graph[2] = 1;
    graph[2] = 3;
    graph[3] = 2;
    graph[5] = 6;
    graph[6] = 5;
  
    System.out.println(compute(graph, N)); 
}
}`,
    },

    {
        id: 23,
        day: 'Day 7',
        no: 'Problem 7',
        ps: 'Depth First Search or DFS for a Graph',
        testCase: '',
        done: true,
        text: `#Depth First Search or DFS for a Graph
        
from collections import defaultdict
 
class Graph:
    def __init__(self):
        self.graph = defaultdict(list)
 
    def addEdge(self, u, v):
        self.graph[u].append(v)
 
    def DFSUtil(self, v, visited):
        visited.add(v)
        print(v, end=' ')

        for neighbour in self.graph[v]:
            if neighbour not in visited:
                self.DFSUtil(neighbour, visited)

    def DFS(self, v):
        visited = set()
        self.DFSUtil(v, visited)
 
g = Graph()
g.addEdge(0, 1)
g.addEdge(0, 2)
g.addEdge(1, 2)
g.addEdge(2, 0)
g.addEdge(2, 3)
g.addEdge(3, 3)
 
print("Following is DFS from (starting from vertex 2)")
g.DFS(2)`,
        c: `//Depth First Search or DFS for a Graph

// No Solution        `,
        cplusplus: `//Depth First Search or DFS for a Graph
        
#include <bits/stdc++.h>
using namespace std;
 
class Graph
{
public:
    map<int, bool> visited;
    map<int, list<int>> adj;
 
    void addEdge(int v, int w);
    void DFS(int v);
};
 
void Graph::addEdge(int v, int w)
{
    adj[v].push_back(w);
}
 
void Graph::DFS(int v)
{
    visited[v] = true;
    cout << v << " ";

    list<int>::iterator i;
    for (i = adj[v].begin(); i != adj[v].end(); ++i)
        if (!visited[*i])
            DFS(*i);
}
 
int main()
{
    Graph g;
    g.addEdge(0, 1);
    g.addEdge(0, 9);
    g.addEdge(1, 2);
    g.addEdge(2, 0);
    g.addEdge(2, 3);
    g.addEdge(9, 3);
 
    cout << "Following is Depth First Traversal"
            "(starting from vertex 2)";
    g.DFS(2);
 
    return 0;
}`,
        java: `//Depth First Search or DFS for a Graph
        
import java.io.*;
import java.util.*;

class Graph {
    private int V;
 
    private LinkedList<Integer> adj[];
 
    @SuppressWarnings("unchecked") Graph(int v)
    {
        V = v;
        adj = new LinkedList[v];
        for (int i = 0; i < v; ++i)
            adj[i] = new LinkedList();
    }
 
    void addEdge(int v, int w)
    {
        adj[v].add(w);
    }
 
    void DFSUtil(int v, boolean visited[])
    {
        visited[v] = true;
        System.out.print(v + " ");
        Iterator<Integer> i = adj[v].listIterator();
        while (i.hasNext())
        {
            int n = i.next();
            if (!visited[n])
                DFSUtil(n, visited);
        }
    }
 
    void DFS(int v)
    {
        boolean visited[] = new boolean[V];

        DFSUtil(v, visited);
    }
 
    public static void main(String args[])
    {
        Graph g = new Graph(4);
 
        g.addEdge(0, 1);
        g.addEdge(0, 2);
        g.addEdge(1, 2);
        g.addEdge(2, 0);
        g.addEdge(2, 3);
        g.addEdge(3, 3);
 
        System.out.println("Following is Depth First Traversal " + "(starting from vertex 2)");
        g.DFS(2);
    }
}`,
    },
    {
        id: 24,
        day: 'Day 8',
        no: 'Problem 8',
        ps: 'Maximum and minimum of an array using minimum number of comparisons',
        testCase: 'Input  : Array = [1, 2, 3, 4, 5] Output : Min: 1, Max: 5',
        done: true,
        text: `# Maximum and minimum of an array using minimum number of comparisons
        
class pair:
    def __init__(self):
        self.min = 0
        self.max = 0
 
def getMinMax(arr: list, n: int) -> pair:
    minmax = pair()
 
    if n == 1:
        minmax.max = arr[0]
        minmax.min = arr[0]
        return minmax
 
    if arr[0] > arr[1]:
        minmax.max = arr[0]
        minmax.min = arr[1]
    else:
        minmax.max = arr[1]
        minmax.min = arr[0]
 
    for i in range(2, n):
        if arr[i] > minmax.max:
            minmax.max = arr[i]
        elif arr[i] < minmax.min:
            minmax.min = arr[i]
 
    return minmax
 
if __name__ == "__main__":
    arr = [1000, 11, 445, 1, 330, 3000]
    arr_size = 6
    minmax = getMinMax(arr, arr_size)
    print("Minimum element is", minmax.min)
    print("Maximum element is", minmax.max)`,
        c: `// Maximum and minimum of an array using minimum number of comparisons
        
struct pair
{
  int min;
  int max;
}; 
 
struct pair getMinMax(int arr[], int n)
{
  struct pair minmax;    
  int i;
   
  if (n == 1)
  {
     minmax.max = arr[0];
     minmax.min = arr[0];    
     return minmax;
  }   
 
  if (arr[0] > arr[1]) 
  {
      minmax.max = arr[0];
      minmax.min = arr[1];
  } 
  else
  {
      minmax.max = arr[1];
      minmax.min = arr[0];
  }   
 
  for (i = 2; i<n; i++)
  {
    if (arr[i] >  minmax.max)     
      minmax.max = arr[i];
   
    else if (arr[i] <  minmax.min)     
      minmax.min = arr[i];
  }
  return minmax;
}
 
int main()
{
  int arr[] = {1000, 11, 445, 1, 330, 3000};
  int arr_size = 6;
  struct pair minmax = getMinMax (arr, arr_size);
  printf("nMinimum element is %d", minmax.min);
  printf("nMaximum element is %d", minmax.max);
  getchar();
} `,
        cplusplus: `// Maximum and minimum of an array using minimum number of comparisons

#include<iostream>
using namespace std;
 
struct Pair
{
    int min;
    int max;
};
 
struct Pair getMinMax(int arr[], int n)
{
    struct Pair minmax;    
    int i;

    if (n == 1)
    {
        minmax.max = arr[0];
        minmax.min = arr[0];    
        return minmax;
    }

    if (arr[0] > arr[1])
    {
        minmax.max = arr[0];
        minmax.min = arr[1];
    }
    else
    {
        minmax.max = arr[1];
        minmax.min = arr[0];
    }
     
    for(i = 2; i < n; i++)
    {
        if (arr[i] > minmax.max)    
            minmax.max = arr[i];
             
        else if (arr[i] < minmax.min)    
            minmax.min = arr[i];
    }
    return minmax;
}
 
int main()
{
    int arr[] = { 1000, 11, 445, 1, 330, 3000 };
    int arr_size = 6;
     
    struct Pair minmax = getMinMax(arr, arr_size);
     
    cout << "Minimum element is "
         << minmax.min << endl;
    cout << "Maximum element is "
         << minmax.max;
          
    return 0;
}`,
        java: `// Maximum and minimum of an array using minimum number of comparisons
        
public class A {
    static class Pair {
 
        int min;
        int max;
    }
 
    static Pair getMinMax(int arr[], int n) {
        Pair minmax = new  Pair();
        int i;
 
        if (n == 1) {
            minmax.max = arr[0];
            minmax.min = arr[0];
            return minmax;
        }

        if (arr[0] > arr[1]) {
            minmax.max = arr[0];
            minmax.min = arr[1];
        } else {
            minmax.max = arr[1];
            minmax.min = arr[0];
        }
 
        for (i = 2; i < n; i++) {
            if (arr[i] > minmax.max) {
                minmax.max = arr[i];
            } else if (arr[i] < minmax.min) {
                minmax.min = arr[i];
            }
        }
        return minmax;
    }
 
    public static void main(String args[]) {
        int arr[] = {1000, 11, 445, 1, 330, 3000};
        int arr_size = 6;
        Pair minmax = getMinMax(arr, arr_size);
        System.out.printf("Minimum element is %d", minmax.min);
        System.out.printf("Maximum element is %d", minmax.max);
    }
}`,
    },

    {
        id: 25,
        day: 'Day 9',
        no: 'Problem 9',
        ps: 'Check if a M-th fibonacci number divides N-th fibonacci number',
        testCase: 'Input: M = 2, N = 9 Output: No',
        done: true,
        text: `// Check if a M-th fibonacci number divides N-th fibonacci number
    
def check(n, m):
    if (n == 2 or m == 2 or
        n % m == 0) :
        print( "Yes" )
    else :
        print( "No" )
 
m = 3
n = 9
check(n, m)`,
        c: `// Check if a M-th fibonacci number divides N-th fibonacci number
    
// No Solution`,
        cplusplus: `// Check if a M-th fibonacci number divides N-th fibonacci number
    
#include <bits/stdc++.h>
using namespace std;
 
void check(int n, int m)
{
    if (n == 2 || m == 2 || n % m == 0) {
        cout << "Yes" << endl;
    }
    else {
        cout << "No" << endl;
    }
}
 
int main()
{
    int m = 3, n = 9;
    check(n, m);
 
    return 0;
}`,
        java: `// Check if a M-th fibonacci number divides N-th fibonacci number
    
import java.io.*;
 
class A
{
static void check(int n, int m)
{
    if (n == 2 || m == 2 ||
        n % m == 0)
    {
        System.out.println( "Yes");
    }
    else
    {
        System.out.println( "No");
    }
}
 
public static void main (String[] args)
{
    int m = 3, n = 9;
    check(n, m);
}
}`,
    },

    {
        id: 26,
        day: 'Day 10',
        no: 'Problem 10',
        ps:
            'Sort all even numbers in ascending order and then sort all odd numbers in descending order',
        testCase:
            'Input  : arr = [ 1, 2, 3, 5, 4, 7, 10 ] Output : arr[ 7, 5, 3, 1, 2, 4, 10 ]',
        done: true,
        text: `#Sort all even numbers in ascending order and then sort all odd numbers in descending order
        
def twoWaySort(arr, n):
    for i in range(0, n):
        if (arr[i] & 1): 
            arr[i] *= -1
  
    arr.sort()
  
    for i in range(0, n):
        if (arr[i] & 1):
            arr[i] *= -1
  
arr = [1, 3, 2, 7, 5, 4]
n = len(arr)
twoWaySort(arr, n);
for i in range(0, n):
    print(arr[i], end = " ")

`,
        c: `//Sort all even numbers in ascending order and then sort all odd numbers in descending order
        
//No Solution`,
        cplusplus: `//Sort all even numbers in ascending order and then sort all odd numbers in descending order
        
#include <bits/stdc++.h>
using namespace std;

void twoWaySort(int arr[], int n)
{
    int l = 0, r = n - 1;
  
    int k = 0;
  
    while (l < r) 
    {
        while (arr[l] % 2 != 0) 
        {
            l++;
            k++;
        }

        while (arr[r] % 2 == 0 && l < r)
            r--;

        if (l < r)
            swap(arr[l], arr[r]);
    }
  
    sort(arr, arr + k, greater<int>());
  
    sort(arr + k, arr + n);
}
  
int main()
{
    int arr[] = { 1, 3, 2, 7, 5, 4 };
    int n = sizeof(arr) / sizeof(int);
    twoWaySort(arr, n);
    for (int i = 0; i < n; i++)
        cout << arr[i] << " ";
    return 0;
}`,
        java: `//Sort all even numbers in ascending order and then sort all odd numbers in descending order
        
import java.util.Arrays;
import java.util.Collections;
  
public class A 
{
    static void twoWaySort(Integer arr[], int n)
    {
        int l = 0, r = n - 1;
  
        int k = 0;
  
        while (l < r) 
        {
            while (arr[l] % 2 != 0) 
            {
                l++;
                k++;
            }

            while (arr[r] % 2 == 0 && l < r)
                r--;

            if (l < r) 
            {
              
                // swap arr[l] arr[r]
                int temp = arr[l];
                arr[l] = arr[r];
                arr[r] = temp;
            }
        }
  
        Arrays.sort(arr, 0, k, Collections.reverseOrder());
  
        Arrays.sort(arr, k, n);
    }
  
    public static void main(String[] args)
    {
        Integer arr[] = { 1, 3, 2, 7, 5, 4 };
  
        twoWaySort(arr, arr.length);
  
        System.out.println(Arrays.toString(arr));
    }
}`,
    },

    {
        id: 27,
        day: 'Day 11',
        no: 'Problem 11',
        ps:
            'Sort an array according to the increasing frequency of the digit K in the array elements',
        testCase: 'arr = [15, 66, 26, 91], K = 6 Output : 15 91 26 66',
        done: true,
        text: `# Sort an array according to the increasing frequency of the digit K in the array elements
        
def countOccurrences( num, K):
    if (K == 0 and num == 0):
        return 1

    count = 0
     
    while (num > 0):
        if (num % 10 == K):
            count += 1
        num //= 10

    return count

def sortOccurrences(arr, N, K):
    mp = []

    for i in range(N):
        count = countOccurrences(arr[i], K)
         
        mp.append([count, arr[i]])
 
    mp = sorted(mp)
     
    for itr in mp:
        print(itr[1], end = ' ')
 
arr = [ 15, 66, 26, 91 ]
K = 6
N = len(arr)
 
sortOccurrences(arr, N, K);`,
        c: `// Sort an array according to the increasing frequency of the digit K in the array elements
        
// No Solution`,
        cplusplus: `// Sort an array according to the increasing frequency of the digit K in the array elements
        
#include <bits/stdc++.h>
using namespace std;

int countOccurrences(int num, int K)
{
    if (K == 0 && num == 0)
        return 1;
 
    int count = 0;
 
    while (num > 0) {
        if (num % 10 == K)
            count++;
        num /= 10;
    }
 
    return count;
}
 
void sortOccurrences(int arr[], int N, int K)
{

    multimap<int, int> mp;
 
    for (int i = 0; i < N; i++) {
        int count = countOccurrences(
            arr[i], K);
 
        mp.insert(pair<int, int>(count, arr[i]));
    }
 
    for (auto& itr : mp) {
        cout << itr.second << " ";
    }
}
 
int main()
{
    int arr[] = { 15, 66, 26, 91 };
    int K = 6;
    int N = sizeof(arr) / sizeof(arr[0]);
 
    sortOccurrences(arr, N, K);
 
    return 0;
}`,
        java: `// Sort an array according to the increasing frequency of the digit K in the array elements
        
import java.io.*;
import java.lang.*;
import java.util.*;
 
class A {
  static int countOccurrences(int num, int K)
  {
    if (K == 0 && num == 0)
      return 1;

    int count = 0;

    while (num > 0) {
      if (num % 10 == K)
        count++;
      num /= 10;
    }
    return count;
  }
 
  static class Pair {
 
    int idx;
    int freq;
 
    Pair(int idx, int freq)
    {
      this.idx = idx;
      this.freq = freq;
    }
  }

  static void sortOccurrences(int arr[], int N, int K)
  {
    Pair mp[] = new Pair[N];
 
    for (int i = 0; i < N; i++) {
      int count = countOccurrences(arr[i], K);

      mp[i] = new Pair(i, count);
    }

    Arrays.sort(mp, (p1, p2) -> {
      if (p1.freq == p2.freq)
        return p1.idx - p2.idx;
      return p1.freq - p2.freq;
    });
 
    for (Pair p : mp) {
      System.out.print(arr[p.idx] + " ");
    }
  }
 
  public static void main(String[] args)
  {
 
    int arr[] = { 15, 66, 26, 91 };
    int K = 6;
    int N = arr.length;
 
    sortOccurrences(arr, N, K);
  }
}`,
    },

    {
        id: 28,
        day: 'Day 12',
        no: 'Problem 12',
        ps: 'Merge Sort for Doubly Linked List',
        testCase: '',
        done: true,
        text: `# Merge Sort for Doubly Linked List
    
class Node:
    def __init__(self, data):
        self.data = data 
        self.next = None
        self.prev = None
  
class DoublyLinkedList:
    def __init__(self):
        self.head = None
  
    # Function to merge two linked list
    def merge(self, first, second):
        if first is None:
            return second 
          
        if second is None:
            return first
  
        if first.data < second.data:
            first.next = self.merge(first.next, second)
            first.next.prev = first
            first.prev = None   
            return first
        else:
            second.next = self.merge(first, second.next)
            second.next.prev = second
            second.prev = None
            return second
  
    def mergeSort(self, tempHead):
        if tempHead is None: 
            return tempHead
        if tempHead.next is None:
            return tempHead
          
        second = self.split(tempHead)
          
        tempHead = self.mergeSort(tempHead)
        second = self.mergeSort(second)
  
        return self.merge(tempHead, second)

    def split(self, tempHead):
        fast = slow =  tempHead
        while(True):
            if fast.next is None:
                break
            if fast.next.next is None:
                break
            fast = fast.next.next 
            slow = slow.next
              
        temp = slow.next
        slow.next = None
        return temp

    def push(self, new_data):
        new_node = Node(new_data)
        new_node.next = self.head
        if self.head is not None:
            self.head.prev = new_node
        self.head = new_node
  
  
    def printList(self, node):
        temp = node
        print "Forward Traversal using next poitner"
        while(node is not None):
            print node.data,
            temp = node
            node = node.next
        print "Backward Traversal using prev pointer"
        while(temp):
            print temp.data,
            temp = temp.prev
  
dll = DoublyLinkedList()
dll.push(5)
dll.push(20);
dll.push(4);
dll.push(3);
dll.push(30)
dll.push(10);
dll.head = dll.mergeSort(dll.head)   
print "Linked List after sorting"
dll.printList(dll.head)
  `,
        c: `// Merge Sort for Doubly Linked List
    
#include<stdio.h>
#include<stdlib.h>
struct Node
{
    int data;
    struct Node *next, *prev;
};
  
struct Node *split(struct Node *head);
  
struct Node *merge(struct Node *first, struct Node *second)
{
    if (!first)
        return second;

    if (!second)
        return first;

    if (first->data < second->data)
    {
        first->next = merge(first->next,second);
        first->next->prev = first;
        first->prev = NULL;
        return first;
    }
    else
    {
        second->next = merge(first,second->next);
        second->next->prev = second;
        second->prev = NULL;
        return second;
    }
}
  
struct Node *mergeSort(struct Node *head)
{
    if (!head || !head->next)
        return head;
    struct Node *second = split(head);

    head = mergeSort(head);
    second = mergeSort(second);

    return merge(head,second);
}

void insert(struct Node **head, int data)
{
    struct Node *temp = (struct Node *)malloc(sizeof(struct Node));
    temp->data = data;
    temp->next = temp->prev = NULL;
    if (!(*head))
        (*head) = temp;
    else
    {
        temp->next = *head;
        (*head)->prev = temp;
        (*head) = temp;
    }
}

void print(struct Node *head)
{
    struct Node *temp = head;
    printf("Forward Traversal using next poitner");
    while (head)
    {
        printf("%d ",head->data);
        temp = head;
        head = head->next;
    }
    printf("Backward Traversal using prev pointer");
    while (temp)
    {
        printf("%d ", temp->data);
        temp = temp->prev;
    }
}

void swap(int *A, int *B)
{
    int temp = *A;
    *A = *B;
    *B = temp;
}
  
struct Node *split(struct Node *head)
{
    struct Node *fast = head,*slow = head;
    while (fast->next && fast->next->next)
    {
        fast = fast->next->next;
        slow = slow->next;
    }
    struct Node *temp = slow->next;
    slow->next = NULL;
    return temp;
}
  
int main(void)
{
    struct Node *head = NULL;
    insert(&head,5);
    insert(&head,20);
    insert(&head,4);
    insert(&head,3);
    insert(&head,30);
    insert(&head,10);
    head = mergeSort(head);
    printf("Linked List after sorting");
    print(head);
    return 0;
}`,
        cplusplus: `// Merge Sort for Doubly Linked List
    
#include <bits/stdc++.h>
using namespace std;
class Node 
{ 
    public:
    int data; 
    Node *next, *prev; 
}; 
  
Node *split(Node *head); 
  
Node *merge(Node *first, Node *second) 
{ 
    if (!first) 
        return second; 

    if (!second) 
        return first; 
  
    if (first->data < second->data) 
    { 
        first->next = merge(first->next,second); 
        first->next->prev = first; 
        first->prev = NULL; 
        return first; 
    } 
    else
    { 
        second->next = merge(first,second->next); 
        second->next->prev = second; 
        second->prev = NULL; 
        return second; 
    } 
} 
  
Node *mergeSort(Node *head) 
{ 
    if (!head || !head->next) 
        return head; 
    Node *second = split(head); 

    head = mergeSort(head); 
    second = mergeSort(second); 
 
    return merge(head,second); 
} 

void insert(Node **head, int data) 
{ 
    Node *temp = new Node();
    temp->data = data; 
    temp->next = temp->prev = NULL; 
    if (!(*head)) 
        (*head) = temp; 
    else
    { 
        temp->next = *head; 
        (*head)->prev = temp; 
        (*head) = temp; 
    } 
} 

void print(Node *head) 
{ 
    Node *temp = head; 
    cout<<"Forward Traversal using next poitner"; 
    while (head) 
    { 
        cout << head->data << " "; 
        temp = head; 
        head = head->next; 
    } 
    cout  << "Backward Traversal using prev pointer"; 
    while (temp) 
    { 
        cout << temp->data << " "; 
        temp = temp->prev; 
    } 
} 

void swap(int *A, int *B) 
{ 
    int temp = *A; 
    *A = *B; 
    *B = temp; 
} 

Node *split(Node *head) 
{ 
    Node *fast = head,*slow = head; 
    while (fast->next && fast->next->next) 
    { 
        fast = fast->next->next; 
        slow = slow->next; 
    } 
    Node *temp = slow->next; 
    slow->next = NULL; 
    return temp; 
} 
  
int main(void) 
{ 
    Node *head = NULL; 
    insert(&head, 5); 
    insert(&head, 20); 
    insert(&head, 4); 
    insert(&head, 3); 
    insert(&head, 30); 
    insert(&head, 10); 
    head = mergeSort(head); 
    cout << "Linked List after sorting"; 
    print(head); 
    return 0; 
}`,
        java: `// Merge Sort for Doubly Linked List
    
class LinkedList {
  
    static Node head; 
  
    static class Node {
  
        int data;
        Node next, prev;
  
        Node(int d) {
            data = d;
            next = prev = null;
        }
    }
  
    void print(Node node) {
        Node temp = node;
        System.out.println("Forward Traversal using next pointer");
        while (node != null) {
            System.out.print(node.data + " ");
            temp = node;
            node = node.next;
        }
        System.out.println("Backward Traversal using prev pointer");
        while (temp != null) {
            System.out.print(temp.data + " ");
            temp = temp.prev;
        }
    }

    Node split(Node head) {
        Node fast = head, slow = head;
        while (fast.next != null && fast.next.next != null) {
            fast = fast.next.next;
            slow = slow.next;
        }
        Node temp = slow.next;
        slow.next = null;
        return temp;
    }
  
    Node mergeSort(Node node) {
        if (node == null || node.next == null) {
            return node;
        }
        Node second = split(node);
  
        node = mergeSort(node);
        second = mergeSort(second);
  
        return merge(node, second);
    }
  
    Node merge(Node first, Node second) {
        if (first == null) {
            return second;
        }
  
        if (second == null) {
            return first;
        }
  
        if (first.data < second.data) {
            first.next = merge(first.next, second);
            first.next.prev = first;
            first.prev = null;
            return first;
        } else {
            second.next = merge(first, second.next);
            second.next.prev = second;
            second.prev = null;
            return second;
        }
    }
  
    public static void main(String[] args) {
  
        LinkedList list = new LinkedList();
        list.head = new Node(10);
        list.head.next = new Node(30);
        list.head.next.next = new Node(3);
        list.head.next.next.next = new Node(4);
        list.head.next.next.next.next = new Node(20);
        list.head.next.next.next.next.next = new Node(5);
          
          
        Node node = null;
        node = list.mergeSort(head);
        System.out.println("Linked list after sorting :");
        list.print(node);
  
    }
}`,
    },

    {
        id: 29,
        day: 'Day 13',
        no: 'Problem 13',
        ps: 'Implementing sorting of an array using Heap',
        testCase: '',
        done: true,
        text: `#Implementing sorting of an array using Heap
    
def heapify(arr, n, i):
    largest = i 
    if l < n and arr[largest] < arr[l]:
        largest = l
 
    if r < n and arr[largest] < arr[r]:
        largest = r
 
    if largest != i:
        arr[i], arr[largest] = arr[largest], arr[i]  # swap
 
        heapify(arr, n, largest)
 
def heapSort(arr):
    n = len(arr)
    for i in range(n//2 - 1, -1, -1):
        heapify(arr, n, i)
 
    for i in range(n-1, 0, -1):
        arr[i], arr[0] = arr[0], arr[i]  # swap
        heapify(arr, i, 0)
 
arr = [12, 11, 13, 5, 6, 7]
heapSort(arr)
n = len(arr)
print("Sorted array is")
for i in range(n):
    print("%d" % arr[i])`,
        c: `//Implementing sorting of an array using Heap
    
//No Solution`,
        cplusplus: `//Implementing sorting of an array using Heap
    
#include <iostream>
using namespace std;
 
void heapify(int arr[], int n, int i)
{
    int largest = i; // Initialize largest as root
    int l = 2 * i + 1; // left = 2*i + 1
    int r = 2 * i + 2; // right = 2*i + 2
 
    if (l < n && arr[l] > arr[largest])
        largest = l;
 
    if (r < n && arr[r] > arr[largest])
        largest = r;
 
    if (largest != i) {
        swap(arr[i], arr[largest]);
 
        heapify(arr, n, largest);
    }
}
 
void heapSort(int arr[], int n)
{
    for (int i = n / 2 - 1; i >= 0; i--)
        heapify(arr, n, i);
 
    for (int i = n - 1; i > 0; i--) {
        swap(arr[0], arr[i]);
 
        heapify(arr, i, 0);
    }
}
 
void printArray(int arr[], int n)
{
    for (int i = 0; i < n; ++i)
        cout << arr[i] << " ";
    cout << "";
}
 
int main()
{
    int arr[] = { 12, 11, 13, 5, 6, 7 };
    int n = sizeof(arr) / sizeof(arr[0]);
 
    heapSort(arr, n);
 
    cout << "Sorted array is ";
    printArray(arr, n);
}`,
        java: `//Implementing sorting of an array using Heap
    
public class HeapSort {
    public void sort(int arr[])
    {
        int n = arr.length;
 
        for (int i = n / 2 - 1; i >= 0; i--)
            heapify(arr, n, i);
 
        for (int i = n - 1; i > 0; i--) {
            int temp = arr[0];
            arr[0] = arr[i];
            arr[i] = temp;
 
            heapify(arr, i, 0);
        }
    }
 
    void heapify(int arr[], int n, int i)
    {
        int largest = i; // Initialize largest as root
        int l = 2 * i + 1; // left = 2*i + 1
        int r = 2 * i + 2; // right = 2*i + 2
 
        if (l < n && arr[l] > arr[largest])
            largest = l;
 
        if (r < n && arr[r] > arr[largest])
            largest = r;
 
        if (largest != i) {
            int swap = arr[i];
            arr[i] = arr[largest];
            arr[largest] = swap;
 
            heapify(arr, n, largest);
        }
    }
 
    static void printArray(int arr[])
    {
        int n = arr.length;
        for (int i = 0; i < n; ++i)
            System.out.print(arr[i] + " ");
        System.out.println();
    }
 
    public static void main(String args[])
    {
        int arr[] = { 12, 11, 13, 5, 6, 7 };
        int n = arr.length;
 
        HeapSort ob = new HeapSort();
        ob.sort(arr);
 
        System.out.println("Sorted array is");
        printArray(arr);
    }
}`,
    },

    {
        id: 30,
        day: "Day 14",
        no: "Problem 14",
        ps: "Merge two binary max heaps into one",
        testCase:
            "Input  : a = [10, 5, 6, 2], b = [ 12, 7, 9] Output : [ 12, 10, 9, 2, 5, 7, 6]",
        done: true,
        text: `# Merge two binary max heaps into one
        
def MaxHeapify(arr, n, idx):
    if idx >= n: 
        return
    l = 2 * idx + 1
    r = 2 * idx + 2
    Max = 0
    if l < n and arr[l] > arr[idx]: 
        Max = l 
    else:
        Max = idx 
    if r < n and arr[r] > arr[Max]: 
        Max = r 
 
    if Max != idx: 
        arr[Max], arr[idx] = arr[idx], arr[Max] 
        MaxHeapify(arr, n, Max)
  
def buildMaxHeap(arr, n):
    for i in range(int(n / 2) - 1, -1, -1):
        MaxHeapify(arr, n, i)
  
def mergeHeaps(merged, a, b, n, m):
    for i in range(n):
        merged[i] = a[i]
    for i in range(m):
        merged[n + i] = b[i]

    buildMaxHeap(merged, n + m)
  
if __name__ == '__main__': 
    a = [10, 5, 6, 2] 
    b = [12, 7, 9] 
  
    n = len(a) 
    m = len(b) 
  
    merged = [0] * (m + n) 
    mergeHeaps(merged, a, b, n, m) 
  
    for i in range(n + m):
        print(merged[i], end = " ")`,
        c: `// Merge two binary max heaps into one
        
// No Solution`,
        cplusplus: `// Merge two binary max heaps into one
        
#include <bits/stdc++.h>
using namespace std;

void maxHeapify(int arr[], int n, int idx)
{
    if (idx >= n)
        return;
    int l = 2 * idx + 1;
    int r = 2 * idx + 2;
    int max;
    if (l < n && arr[l] > arr[idx])
        max = l;
    else
        max = idx;
    if (r < n && arr[r] > arr[max])
        max = r;
  
    if (max != idx) {
        swap(arr[max], arr[idx]);
        maxHeapify(arr, n, max);
    }
}
  
void buildMaxHeap(int arr[], int n)
{
    for (int i = n / 2 - 1; i >= 0; i--)
        maxHeapify(arr, n, i);
}
  
void mergeHeaps(int merged[], int a[], int b[], int n, int m)
{
    for (int i = 0; i < n; i++)
        merged[i] = a[i];
    for (int i = 0; i < m; i++)
        merged[n + i] = b[i];

    buildMaxHeap(merged, n + m);
}
  
int main()
{
    int a[] = { 10, 5, 6, 2 };
    int b[] = { 12, 7, 9 };
  
    int n = sizeof(a) / sizeof(a[0]);
    int m = sizeof(b) / sizeof(b[0]);
  
    int merged[m + n];
    mergeHeaps(merged, a, b, n, m);
  
    for (int i = 0; i < n + m; i++)
        cout << merged[i] << " ";
  
    return 0;
}`,
        java: `// Merge two binary max heaps into one
        
class A {
    public static void maxHeapify(int[] arr, int n, int i)
    {
        if (i >= n) {
            return;
        }
        int l = i * 2 + 1;
        int r = i * 2 + 2;
        int max;
        if (l < n && arr[l] > arr[i]) {
            max = l;
        }
        else
            max = i;
        if (r < n && arr[r] > arr[max]) {
            max = r;
        }
          
        if (max != i) {
            int temp = arr[max];
            arr[max] = arr[i];
            arr[i] = temp;
            maxHeapify(arr, n, max);
        }
    }
      
    public static void mergeHeaps(int[] arr, int[] a, int[] b, int n, int m)
    {
        for (int i = 0; i < n; i++) {
            arr[i] = a[i];
        }
        for (int i = 0; i < m; i++) {
            arr[n + i] = b[i];
        }
        n = n + m;
  
        for (int i = n / 2 - 1; i >= 0; i--) {
            maxHeapify(arr, n, i);
        }
    }
      
    public static void main(String[] args)
    {
        int[] a = {10, 5, 6, 2};
        int[] b = {12, 7, 9};
        int n = a.length;
        int m = b.length;
  
        int[] merged = new int[m + n];
  
        mergeHeaps(merged, a, b, n, m);
  
        for (int i = 0; i < m + n; i++)
            System.out.print(merged[i] + " ");
        System.out.println();
    }
}`,
    },

    {
        id: 31,
        day: "Day 15",
        no: "Problem 15",
        ps: "Convert the given min Heap to max Heap",
        testCase:
            "Input  : { 3,5,9,6,8,20,10,12,18,9 } Output : { 20,18,10,12,9,9,3,5,6,8 }",
        done: true,
        text: `# Convert the given min Heap to max Heap
        
def MaxHeapify(arr, i, n):
    l = 2 * i + 1
    r = 2 * i + 2
    largest = i
    if l < n and arr[l] > arr[i]:
        largest = l
    if r < n and arr[r] > arr[largest]:
        largest = r
    if largest != i:
        arr[i], arr[largest] = arr[largest], arr[i]
        MaxHeapify(arr, largest, n)
 
def convertMaxHeap(arr, n):
    for i in range(int((n - 2) / 2), -1, -1):
        MaxHeapify(arr, i, n)

def printArray(arr, size):
    for i in range(size):
        print(arr[i], end = " ")
    print()
 
if __name__ == '__main__':
    arr = [3, 5, 9, 6, 8, 20, 10, 12, 18, 9]
    n = len(arr)
 
    print("Min Heap array : ")
    printArray(arr, n)
 
    convertMaxHeap(arr, n)
 
    print("Max Heap array : ")
    printArray(arr, n)`,
        c: `// Convert the given min Heap to max Heap
        
// No Solution`,
        cplusplus: `// Convert the given min Heap to max Heap
        
#include<bits/stdc++.h>
using namespace std;
 
void MaxHeapify(int arr[], int i, int n)
{
    int l = 2*i + 1;
    int r = 2*i + 2;
    int largest = i;
    if (l < n && arr[l] > arr[i])
        largest = l;
    if (r < n && arr[r] > arr[largest])
        largest = r;
    if (largest != i)
    {
        swap(arr[i], arr[largest]);
        MaxHeapify(arr, largest, n);
    }
}

void convertMaxHeap(int arr[], int n)
{
    for (int i = (n-2)/2; i >= 0; --i)
        MaxHeapify(arr, i, n);
}

void printArray(int* arr, int size)
{
    for (int i = 0; i < size; ++i)
        printf("%d ", arr[i]);
}
 
int main()
{
    int arr[] = {3, 5, 9, 6, 8, 20, 10, 12, 18, 9};
    int n = sizeof(arr)/sizeof(arr[0]);
 
    printf("Min Heap array : ");
    printArray(arr, n);
 
    convertMaxHeap(arr, n);
 
    printf("Max Heap array : ");
    printArray(arr, n);
 
    return 0;
}`,
        java: `// Convert the given min Heap to max Heap
        
class A
{
    static void MaxHeapify(int arr[], int i, int n)
    {
        int l = 2*i + 1;
        int r = 2*i + 2;
        int largest = i;
        if (l < n && arr[l] > arr[i])
            largest = l;
        if (r < n && arr[r] > arr[largest])
            largest = r;
        if (largest != i)
        {
            int temp = arr[i];
            arr[i] = arr[largest];
            arr[largest] = temp;
            MaxHeapify(arr, largest, n);
        }
    }
  
    static void convertMaxHeap(int arr[], int n)
    {
        for (int i = (n-2)/2; i >= 0; --i)
            MaxHeapify(arr, i, n);
    }

    static void printArray(int arr[], int size)
    {
        for (int i = 0; i < size; ++i)
            System.out.print(arr[i]+" ");
    }
     
    public static void main (String[] args)
    {
        int arr[] = {3, 5, 9, 6, 8, 20, 10, 12, 18, 9};
        int n = arr.length;
  
        System.out.print("Min Heap array : ");
        printArray(arr, n);
  
        convertMaxHeap(arr, n);
  
        System.out.print("Max Heap array : ");
        printArray(arr, n);
    }
}`,
    },

    //   {
    //     id: 32,
    //     day: "Day 16",
    //     no: "Problem 16",
    //     ps: "Merge two binary max heaps into one",
    //     testCase:
    //       "Input  : a = [10, 5, 6, 2], b = [ 12, 7, 9] Output : [ 12, 10, 9, 2, 5, 7, 6]",
    //     done: false,
    //     text: ``,
    //     c: ``,
    //     cplusplus: ``,
    //     java: ``,
    //   },
];
