import React, { useContext } from 'react';
import {
  Nav,
  NavLinks,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavbarContainer,
  NavItem,
  MobileIcon,
} from './NavbarElements';
import { FaBars } from 'react-icons/fa';
import { UserContext } from '../../Providers/UserProvider';
import { Avatar } from '@chakra-ui/avatar';
import { auth } from '../../services/firebase';
import { AiFillDownCircle } from 'react-icons/ai';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';

const Navbar = ({ toggle }) => {
  const user = useContext(UserContext);
  const logOut = () => {
    auth
      .signOut()
      .then((res) => {
        localStorage.removeItem('user');
        window.location.href = '/';
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <>
      <Nav>
        <NavbarContainer>
          <a href='/'>
            <img
              alt='logo'
              src='https://ik.imagekit.io/codewarriors/cw_NzMdvqmCV.png'
              width='70px'
              height='70px'
            />
          </a>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>

          <NavMenu>
            <NavItem>
              <NavLinks to='/courses'>Courses</NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks to='/events'>Events</NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks to='/team'>Team</NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks to='/contactus'>Contact</NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks to='/chapterlead'>Chapter Lead</NavLinks>
            </NavItem>
          </NavMenu>

          {user ? (
            <NavBtn>
              <Avatar
                cursor='pointer'
                name={user.displayName}
                src={user.photoURL}
                width='40px'
                height='40px'
              />
              <h1 style={{ color: 'white', paddingLeft: '6px' }}>
                {user.displayName}
              </h1>
              <Menu placement='bottom-end'>
                <MenuButton>
                  <AiFillDownCircle className='logout-icon' />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => logOut()}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </NavBtn>
          ) : (
            <NavBtn>
              <NavBtnLink to='/login'>Login / Sign Up</NavBtnLink>
            </NavBtn>
          )}
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
