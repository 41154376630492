import React from 'react'
import { Box, Flex, Divider } from '@chakra-ui/react'
import FaqComponent from './FaqComponent';


const Faq = () => {
    return (
        <Box >
            <Flex align="center" justify="center">
                <Box width="80%" pb={4} fontSize="lg">
                    <Divider />
                    <FaqComponent title="Who are we ?" info="We are on a mission to improve lives through learning, connecting people and ideas, and to create skilled software engineers for our country and the world, Code Warriors aims to connect learners from all over the globe with high-quality educators." />
                    <FaqComponent title="Why Code Warriors ?" info="Code Warriors was founded in March 2020 with a mission to create skilled programmers for our country and the world. We continuously innovate in the best ways to train the next generation of developers." />
                    <FaqComponent title="What We Offer?" info="We organize various events for you to learn, thrive and also have fun. We also offer Free Tutorials, Live, Online and events, Frequent Coding Competitions, Webinars by Industry Experts, and Internship opportunities." />
                </Box>
            </Flex>
        </Box>
    )
}

export default Faq
