import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { SimpleGrid, Box, Text, Image } from '@chakra-ui/react';
import TeamCard from '../components/Team/TeamCard';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer/Footer';

const Team = () => {
  const [isOpen, setIsOpen] = useState(false);
  window.scrollTo(0, 0);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  let size;

  let widthscreen = window.screen.width;
  if (widthscreen <= 1350 && widthscreen >= 541) {
    size = 3;
  } else {
    size = 4;
  }

  return (
    <Box bg='#F4F5F7'>
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />
      <Box bg='#000' height='420px'>
        <SimpleGrid columns={[1, 2]} pt={[2, 8]} pb={12}>
          <Box
            py={[8]}
            pl={[2, 4, 20, 32]}
            px={4}
            maxWidth={650}
            mt='auto'
            mb='auto'
          >
            <Text
              letterSpacing={[1.5]}
              textAlign='center'
              fontSize={['xl', '2xl', '3xl', '4xl']}
              fontWeight={900}
              color='white'
              lineHeight={1.3}
              fontFamily='Montserrat'
            >
              TEAM
            </Text>

            <Text
              py={8}
              letterSpacing={2}
              pb={[2, 4]}
              textAlign='center'
              fontSize={['xl', '2xl', '3xl']}
              fontWeight={800}
              color='white'
              lineHeight={1.5}
              fontFamily='Montserrat'
            >
              Alone we can do so little, together we can do so much
            </Text>
          </Box>
          <Box m='auto'>
            <Image
              draggable={false}
              src='https://ik.imagekit.io/codewarriors/team_2voqlVfv1c.svg'
              width={[300, 400, 450, 500]}
            />
          </Box>
        </SimpleGrid>
      </Box>
      <Image
        draggable={false}
        src='https://ik.imagekit.io/codewarriors/bg_k2vRctyaK.png'
        mt='-1px'
        width='100%'
      />
      <center>
        <SimpleGrid
          columns={[1, 1, 2, size]}
          spacingX={[4, 4, 4, 8]}
          spacingY={[6, 8]}
          width='80%'
          paddingY={6}
        >
          <TeamCard
            image='https://ik.imagekit.io/codewarriors/mayank_N-woVJR2C.png'
            name='MAYANK BAJAJ'
            position='FOUNDER'
            github='https://github.com/mayank8200'
            linkedin='https://www.linkedin.com/in/mayank-bajaj/'
          />
          <TeamCard
            image='https://ik.imagekit.io/codewarriors/anup-min_fPmE5Lhh3.png'
            name='ANUP MOR'
            position='CO-FOUNDER'
            github='https://github.com/anupmor1998'
            linkedin='https://www.linkedin.com/in/anup-mor-81aa34183/'
          />
          <TeamCard
            image='https://ik.imagekit.io/codewarriors/yashbajaj-min-min_a46DYh4t1.png'
            name='YASH BAJAJ'
            position='CO-FOUNDER'
            github='https://github.com/yash42828'
            linkedin='https://www.linkedin.com/in/yashbajaj42828'
          />
          <TeamCard
            image='https://ik.imagekit.io/codewarriors/raj_WSb2_lGVnY.png'
            name='RAJ BHENSDADIYA'
            position='TECHNICAL LEAD'
            github='https://github.com/rajpatel1225'
            linkedin='https://www.linkedin.com/in/raj-bhensdadiya/'
          />
          <TeamCard
            image='https://ik.imagekit.io/codewarriors/gaurav_2avMKnSl_.png'
            name='GAURAV SHARMA'
            position='COMMUNITY MANAGER'
            github='https://github.com/gaurav7121'
            linkedin='https://www.linkedin.com/in/gaurav-sharma-93098418b/'
          />
          <TeamCard
            image='https://ik.imagekit.io/codewarriors/yash-min_tmA6YLyWf.png'
            name='YASH MANTRI'
            position='WEB DEV LEAD'
            github='https://github.com/yashmantri20'
            linkedin='https://www.linkedin.com/in/yashmantri20/'
          />
          <TeamCard
            image='https://ik.imagekit.io/codewarriors/snehal_-cw3DPOft.png'
            name='SNEHAL SINGH'
            position='OPERATIONAL LEAD'
            github='https://github.com/Snehal-Singh174'
            linkedin='https://www.linkedin.com/in/snehal-singh-b5119817b/'
          />
          <TeamCard
            image='https://ik.imagekit.io/codewarriors/paras_o3Xnlc4hT.png'
            name='PARAS GANG'
            position='PROGRAM MANAGER'
            github='https://github.com/ParasGang'
            linkedin='https://www.linkedin.com/in/paras-gang/'
          />
          <TeamCard
            image='https://ik.imagekit.io/codewarriors/kaustubh-min_oAZp7sbQa.png'
            name='KAUSTUBH TRIPATHI'
            position='OUTREACH MANAGER'
            github='https://github.com/Maverick6798'
            linkedin='https://www.linkedin.com/in/kaustubh-tripathi-812a79129'
          />
        </SimpleGrid>
      </center>

      <Footer />
    </Box>
  );
};

export default Team;
