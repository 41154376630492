import { Box, Text, Image, SimpleGrid, Flex } from '@chakra-ui/react';
import React from 'react';
import { FaLinkedinIn, FaFacebookF, FaTelegramPlane } from 'react-icons/fa';
import {
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillYoutube,
} from 'react-icons/ai';

const Footer = () => {
  return (
    <Box p={6} bg='#000' mt={12}>
      <SimpleGrid columns={[1, 4, 4, 4]} spacingY={6} spacingX={2}>
        <Box m={[0, 'auto', 'auto', 'auto']}>
          <a href='/'>
            <Image
              src='https://ik.imagekit.io/codewarriors/cw_NzMdvqmCV.png'
              width={['100px', '100px', '100px', '125px']}
              height={['100px', '100px', '100px', '125px']}
              m={[0, 'auto', 'auto', 'auto']}
            />
          </a>
        </Box>
        <Box mx={[0, 'auto', 'auto', 'auto']}>
          <Text color='white' fontWeight={600} my={2}>
            Courses
          </Text>
          <a
            href='https://www.udemy.com/course/learn-machine-learning-in-21-days/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Text
              cursor='pointer'
              fontFamily='Montserrat'
              my='3px'
              className='texthovering'
            >
              Machine Learning
            </Text>
          </a>
          <a
            href='https://www.udemy.com/course/nlp-course-for-beginner/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
              lineHeight={1}
            >
              Natural Language Processing
            </Text>
          </a>
          <a
            href='https://www.udemy.com/course/basic-python-course-for-beginner/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
            >
              Python
            </Text>
          </a>
          <a
            href='https://www.udemy.com/course/python-for-computer-vision/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
            >
              Computer Vision
            </Text>
          </a>
          <a
            href='https://www.udemy.com/course/learn-flutter-in-30-days/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
            >
              Flutter
            </Text>
          </a>
        </Box>

        <Box mx={[0, 'auto', 'auto', 'auto']}>
          <Text color='white' fontWeight={600} my={2}>
            Quick Links
          </Text>
          <a href='/'>
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
            >
              Home
            </Text>
          </a>
          <a href='/courses'>
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
            >
              Courses
            </Text>
          </a>
          <a href='/events'>
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
            >
              Events
            </Text>
          </a>
          <a href='/team'>
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
            >
              Team
            </Text>
          </a>
          <a href='/contactus'>
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
            >
              Contact
            </Text>
          </a>
          <a href='/chapterlead'>
            <Text
              cursor='pointer'
              className='texthovering'
              fontFamily='Montserrat'
              my='3px'
            >
              Chapter Lead
            </Text>
          </a>
        </Box>

        <Box mx={[0, 'auto', 'auto', 'auto']}>
          <Text color='white' fontWeight={600} my={2}>
            Follow Us
          </Text>
          <Flex mt={[6, 8, 10, 12]}>
            <center>
              <span className='socialmedia'>
                <Box mt='8px' mb='8px'>
                  <a
                    href='https://www.linkedin.com/company/codewarriors/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FaLinkedinIn size='25px' />
                  </a>
                </Box>
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className='socialmedia'>
                <Box mt='8px' mb='8px'>
                  <a
                    href='https://www.instagram.com/codewarriors2020/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <AiFillInstagram size='25px' />
                  </a>
                </Box>
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className='socialmedia'>
                <Box mt='8px' mb='8px'>
                  <a
                    href='https://www.facebook.com/codewarriors2020/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FaFacebookF size='25px' />
                  </a>
                </Box>
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className='socialmedia'>
                <Box mt='8px' mb='8px'>
                  <a
                    href='https://twitter.com/codewarriors20'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <AiOutlineTwitter size='25px' />
                  </a>
                </Box>
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className='socialmedia'>
                <Box mt='8px' mb='8px'>
                  <a
                    href='https://youtube.com/CodeWarriors'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <AiFillYoutube size='25px' />
                  </a>
                </Box>
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className='socialmedia'>
                <Box mt='8px' mb='8px'>
                  <a
                    href='https://t.me/MLislove'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FaTelegramPlane size='25px' />
                  </a>
                </Box>
              </span>
              &nbsp;&nbsp;&nbsp;
            </center>
          </Flex>
        </Box>
      </SimpleGrid>
      <br />
      <hr style={{ opacity: '0.5' }} />
      <Text color='white' textAlign='center' fontWeight={400} mt={6}>
        Copyright © 2021 All Rights Reserved by Code Warriors
      </Text>
    </Box>
  );
};

export default Footer;
