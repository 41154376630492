import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { Image, Box, Tabs, TabList, TabPanel, TabPanels, Tab, Center, SimpleGrid, Text } from "@chakra-ui/react"
import UpcomingEvent from '../components/Events/UpcomingEvent';
import CompletedEvent from '../components/Events/CompletedEvent';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer/Footer';
import LiveEvent from '../components/Events/LiveEvent';
// import event from '../images/events.svg'

const Events = () => {

  const [isOpen, setIsOpen] = useState(false)
  window.scrollTo(0, 0);

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Box bg="#F4F5F7">
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />

      <Box bg="#000" height="420px">
        <SimpleGrid columns={[1, 2]} pt={[2, 8]} pb={12}>
          <Box py={[8]} pl={[2, 4, 20, 32]} px={4} maxWidth={650} mt="auto" mb="auto">

            <Text letterSpacing={[1.5]} textAlign="center" fontSize={["xl", "2xl", "3xl", "4xl"]} fontWeight={900} color="white" lineHeight={1.3} fontFamily="Montserrat">EVENTS</Text>
            <Text py={8} letterSpacing={2} pb={[2, 4]} textAlign="center" fontSize={["xl", "2xl", "3xl"]} fontWeight={800} color="white" lineHeight={1.5} fontFamily="Montserrat">Life is an event. Make it remarkable</Text>
          </Box>
          <Box m="auto">
            <Image draggable={false} src="https://ik.imagekit.io/codewarriors/events_HqBgRmSQD.svg" width={[300, 400, 500, 600]} mt="auto" mb="auto" />
          </Box>
        </SimpleGrid>
      </Box>

      <Image draggable={false} src="https://ik.imagekit.io/codewarriors/bg_k2vRctyaK.png" mt="-1px" width="100%" />

      <Tabs variant="soft-rounded" colorScheme="blue" mt={6} defaultIndex={0}>
        <Center>
          <TabList>
            <Tab>Upcoming Events</Tab>
            <Tab>Live Events</Tab>
            <Tab>Completed Events</Tab>
          </TabList>
        </Center>

        <TabPanels>
          <TabPanel>
            <UpcomingEvent />
          </TabPanel>

          <TabPanel>
            <LiveEvent />
          </TabPanel>

          <TabPanel>
            <CompletedEvent />
          </TabPanel>

        </TabPanels>
      </Tabs>

      <Footer />
    </Box>
  );
};

export default Events;
