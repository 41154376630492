import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD8QLuI4Oq5uLivIZtXByIQ7X4OW8T2bUo",
  authDomain: "codewarriors20.firebaseapp.com",
  projectId: "codewarriors20",
  storageBucket: "codewarriors20.appspot.com",
  messagingSenderId: "387577500339",
  appId: "1:387577500339:web:853e8f5f3f22ac0f3bd6c6"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const db = firebase.firestore();

export const logOut = () => {
  auth
    .signOut()
    .then((res) => {
      localStorage.removeItem('user');
    })
    .catch((error) => {
      console.log(error.message);
    });
};
