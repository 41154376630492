import certificate from '../images/certificate.png';
import teamwork from '../images/teamwork.png';
import linkedin from '../images/linkedin.png';
import onlinecourse from '../images/onlinecourse.png';
import event from '../images/event.png';
import developing from '../images/developing.png';
import gift from '../images/gift.png';
import reward from '../images/reward.png';


export const perks = [
  {
    icon: certificate,
    title: 'Internship Certificate',
    description: 'Every Chapter Lead will receive certificate on successful completion.'
  },
  {
    icon: teamwork,
    title: 'Work Culture',
    description: 'Opportunity to work with diverse team to enhance your skills.'
  },
  {
    icon: linkedin,
    title: 'LinkedIn Recommendation',
    description: 'Top perfoming Chapter Leads will get LinkedIn Recommendation.'
  },
  {
    icon: onlinecourse,
    title: 'Course Giveaways',
    description: 'Based upon performance, Chapter Leads will be eligible for 100% off on Code Warriors Udemy Courses.'
  },
  {
    icon: event,
    title: 'Exclusive Events',
    description: 'Free entry in our future exclusive events.'
  },
  {
    icon: developing,
    title: 'Personality Development',
    description: 'Special Guest Sessions on Personal Branding, Interview Preparation and many more.'
  },
  {
    icon: gift,
    title: 'Goodies & Swags',
    description: 'Exclusive Swags and Goodies to the Chapter Leads based upon performance.'
  },
  {
    icon: reward,
    title: 'Sponsors Perks',
    description: 'Free services from our sponsors (.xyz, voiceflow, Dockship, etc)'
  },
]