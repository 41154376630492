import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { SimpleGrid, Box, Image, Text } from "@chakra-ui/react"
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer/Footer';
import CourseCard from '../components/Course/CourseCard';


const Course = () => {
  const [isOpen, setIsOpen] = useState(false)
  window.scrollTo(0, 0);
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  let size;

  let widthscreen = window.screen.width
  if (widthscreen <= 1350 && widthscreen >= 541) {
    size = 3
  }
  else {
    size = 4
  }

  return (
    <Box bg="#F4F5F7">
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />
      <Box bg="#000" height="420px">
        <SimpleGrid columns={[1, 2]} pt={[2, 8]} pb={12}>
          <Box py={[8]} pl={[2, 4, 20, 32]} px={4} maxWidth={650} mt="auto" mb="auto">

            <Text letterSpacing={[1.5]} textAlign="center" fontSize={["xl", "2xl", "3xl", "4xl"]} fontWeight={900} color="white" lineHeight={1.3} fontFamily="Montserrat">COURSES</Text>


            <Text py={8} letterSpacing={2} pb={[2, 4]} textAlign="center" fontSize={["xl", "2xl", "3xl"]} fontWeight={800} color="white" lineHeight={1.5} fontFamily="Montserrat">Bringing Excellence To Students</Text>
            {/* <Text letterSpacing={[4]} pb={8} textAlign="center" fontSize={["lg", "xl", "2xl", "3xl"]} fontWeight={800} color="white" lineHeight={1} fontFamily="Montserrat"></Text> */}


          </Box>
          <Box m="auto">
            <Image draggable={false} src="https://ik.imagekit.io/codewarriors/course_HO6li1mFtl.svg" width={[250, 300, 400, 600]} mt="auto" mb="auto" />
          </Box>
        </SimpleGrid>
      </Box>
      <Image draggable={false} src="https://ik.imagekit.io/codewarriors/bg_k2vRctyaK.png" mt="-1px" width="100%" />

      <center>
        <SimpleGrid columns={[1, 1, 2, size]} spacingX={[4, 4, 8, 8]} spacingY={[6, 8]} width="80%" paddingY={6} >
          <CourseCard name='Flutter' image="https://ik.imagekit.io/codewarriors/flutter_YGvOq2k51e.png" data={['Dart', 'FlutterDev', 'App']} path="https://www.udemy.com/course/learn-flutter-in-30-days/" />
          <CourseCard name='Machine Learning' image="https://ik.imagekit.io/codewarriors/machine_5hyFWExvH.jpg" data={['Python', 'ML', 'AI', 'Data']} path="https://www.udemy.com/course/learn-machine-learning-in-21-days/" />
          <CourseCard name='Computer Vision' image="https://ik.imagekit.io/codewarriors/cv_3u5nsEMuh.jfif" data={['Python', 'OpenCV', 'AI']} path="https://www.udemy.com/course/python-for-computer-vision/" />
          < CourseCard name='Python' image="https://ik.imagekit.io/codewarriors/python_aQS7BYPKKP.jpg" data={['Python', 'Programming', 'Coding']} path="https://www.udemy.com/course/basic-python-course-for-beginner/" />
          <CourseCard name='Natural Language Processing' image="https://ik.imagekit.io/codewarriors/nlp_qPxMMUZzCR.jpg" data={['Python', 'NLP', 'ML', 'Data']} path="https://www.udemy.com/course/nlp-course-for-beginner/" />
          <CourseCard name='Getting Started with Machine Learning' image="https://ik.imagekit.io/codewarriors/ml_LsZTTk3YCO.png" data={['Python', 'ML', 'AI', 'Data']} path="https://www.udemy.com/course/machine-learning-course/" />

        </SimpleGrid>
      </center>
      <Footer />
    </Box>
  );
};

export default Course;
