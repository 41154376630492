import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  SimpleGrid,
  Text,
  Modal,
  Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { data } from "../../../database/solution";

const Card = () => {
  const [modal, setModal] = useState(false);
  const [description, setDesc] = useState("");

  const toggle = (desc) => {
    setDesc(desc);
    setModal(!modal);
  };

  const closeModal = () => setModal(!modal);

  return (
    <>
      <SimpleGrid
        columns={[3, 4, 6, 8]}
        spacingX={[4, 4, 8, 8]}
        spacingY={[6, 8]}
        width="80%"
        paddingY={6}
      >
        {data.map((code) => {
          return (
            <Box
              className="dayscard"
              key={code.id}
              bg="white"
              padding={2}
              borderRadius="25px"
              boxShadow="xl"
              width="70px"
              height="70px"
              cursor="pointer"
              onClick={() => toggle(code)}
            >
              <Text
                fontWeight={900}
                lineHeight={1.2}
                fontSize={["2xl", "2xl", "2xl", "2xl"]}
                textAlign="center"
              >
                {code.day}
              </Text>
            </Box>
          );
        })}
      </SimpleGrid>

      <Modal isOpen={modal} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{description.no}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {description.ps}
            <br />
            <br />
            {description.testCase}
          </ModalBody>

          <ModalFooter>
            {description.done ? null : (
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() =>
                  window.open("https://codewarriors.typeform.com/to/JYlRewGj")
                }
              >
                Submit Solution
              </Button>
            )}
            {description.text !== "" ? (
              <Link to={`/events/SpringOfCode/${description.id}`}>
                <Button colorScheme="blue">View Solution</Button>
              </Link>
            ) : (
              <Popover>
                <PopoverTrigger>
                  <Button variant="ghost">View Solution</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>{description.no}</PopoverHeader>
                  <PopoverBody>Solution will be available tomorrow</PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Card;
