import { Box, Image, Text, Button, Tag, TagLabel } from '@chakra-ui/react'
import React from 'react'
// import { BsCalendar } from 'react-icons/bs';
// import { MdLocationOn } from 'react-icons/md';

const CourseCard = ({ name, image, data, path }) => {
    return (
        <Box bg="white" borderRadius="25px" boxShadow="xl">
            <Image src={image} width="100%" borderRadius="12px 12px 0 0" height={[160]} />
            <Box p={4}>
                {
                    data.map((tags, index) => {
                        return (
                            <Tag key={index} size="sm" colorScheme="blue" borderRadius="full" ml={2}>
                                <TagLabel>{tags}</TagLabel>
                            </Tag>
                        )
                    })
                }

                <Text fontWeight={800} mt={2} pb={2} color="#003049" fontSize={["md", "md", "lg", "lg"]} fontFamily="Montserrat">{name}</Text>
                {/* <Text fontWeight={600} mt={2} color="#003049" fontSize={["sm", "md", "lg", "lg"]} fontFamily="Montserrat" pb={2} textAlign="left"></Text> */}

                <Button id="coursebtn" mt="5px" width="100%" borderRadius="15px" color="white" fontFamily="Montserrat" onClick={() => window.open(path)}>Explore Course</Button>
            </Box>
        </Box>
    )
}

export default CourseCard
