import { Button } from '@chakra-ui/button';
import { Flex, List, ListIcon, ListItem } from '@chakra-ui/layout';
import React from 'react';
import './Notification.css';
import { FaRegHandPointRight } from 'react-icons/fa';

const Notification = () => {
  return (
    <Flex>
      <a href='/events/cw-open-source'>
        <Button className='evt-btn'>Open Source Event</Button>
      </a>
      {/* <a href='/chapterlead'>
        <Button className='lead-btn'>Chapter Lead</Button>
      </a> */}
    </Flex>
  );
};

export const Title = () => {
  return (
    <List spacing={1}>
      {/* <ListItem fontFamily='Montserrat' fontWeight={400} mb={4}>
        <ListIcon as={FaRegHandPointRight} />
        CW - Chapter Lead Program
      </ListItem> */}

      <ListItem fontFamily='Montserrat' fontWeight={400} mb={4}>
        <ListIcon as={FaRegHandPointRight} />
        New Event is Coming soon Grab your seats now
      </ListItem>
    </List>
  );
};

export default Notification;
