import React, { useContext } from 'react';
import { UserContext } from '../../Providers/UserProvider';
import { auth } from '../../services/firebase';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
} from './SidebarElements';
import { Avatar } from '@chakra-ui/avatar';

const Sidebar = ({ isOpen, toggle }) => {
  const user = useContext(UserContext);
  const logOut = () => {
    auth
      .signOut()
      .then((res) => {
        localStorage.removeItem('user');
        window.location.href = '/';
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  return (
    <SidebarContainer isOpen={isOpen}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='/courses'>Courses</SidebarLink>

          <SidebarLink to='/events'>Events</SidebarLink>

          <SidebarLink to='/team'>Team</SidebarLink>

          <SidebarLink to='/contactus'>Contact</SidebarLink>
          <SidebarLink to='/chapterlead'>Chapter Lead</SidebarLink>
        </SidebarMenu>
        {user ? (
          <>
            <SideBtnWrap>
              <h1
                style={{
                  color: 'white',
                  paddingRight: '6px',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                {user.displayName}
              </h1>
              <Avatar
                cursor='pointer'
                name={user.displayName}
                src={user.photoURL}
                width='40px'
                height='40px'
              />
            </SideBtnWrap>

            <SideBtnWrap>
              <SidebarRoute to='/login' onClick={() => logOut()}>Logout</SidebarRoute>
            </SideBtnWrap>
          </>
        ) : (
          <SideBtnWrap>
            <SidebarRoute to='/login'>Login / Sign Up</SidebarRoute>
          </SideBtnWrap>
        )}
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
