import React, { useState } from 'react';
import {
  Box,
  Button,
  Image,
  SimpleGrid,
  Text,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { useHistory } from 'react-router';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';

const HacktoberFest = () => {
  const [isOpened, setIsOpen] = useState(false);
  window.scrollTo(0, 0);

  let history = useHistory();

  const toggle = () => {
    setIsOpen(!isOpened);
  };

  return (
    <Box bg='#F4F5F7'>
      <Sidebar isOpen={isOpened} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Image
        mt={8}
        draggable={false}
        className='chapter-img'
        src='https://ik.imagekit.io/codewarriors/posterDtTm_SuHkZBrx_.jpg'
      />
      <SimpleGrid columns={[1, 2]} mt={8}>
        <Box>
          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            Kickstart your Opensource Journey with CW
          </Text>

          <List spacing={4} className='list'>
            <ListItem d='flex' className='quote'>
              We all know that to achieve something big, we have to accomplish
              small things first.
            </ListItem>
            <ListItem d='flex'>
              Open source contribution can be an amazing learning experience. It
              allows you to give back to and be a part of communities that build
              valuable open source software.
            </ListItem>

            <ListItem d='flex' className='quote'>
              Prerequisite: Ready to contribute in open source.
            </ListItem>

            <ListItem d='flex'>
              Code Warriors is back with the amazing opportunity through which
              you can Kickstart your Opensource Journey.
            </ListItem>
          </List>

          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            About Event
          </Text>

          <List spacing={1} className='list'>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              𝗗𝗔𝗧𝗘: 16th October 2022
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              𝗧𝗜𝗠𝗘: 4:00 PM Onwards
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              𝗣𝗟𝗔𝗧𝗙𝗢𝗥𝗠: YOUTUBE
            </ListItem>
          </List>
        </Box>

        <Box>
          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            What should you expect from this event?
          </Text>
          <List spacing={1} className='list'>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              What is Opensource Contribution?
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Why should you contribute to Opensource?
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Prerequisite and get started with opensource contribution.
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Tools to get started with Opensource.
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Top Opensource programs you can participate.
            </ListItem>

            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Special challenge to win exciting prizes.
            </ListItem>

            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              All participants will also get Participation Certificate.
            </ListItem>
          </List>

          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            About Speaker
          </Text>

          <List spacing={4} className='list'>
            <ListItem d='flex'>
              <span>
                <span className='bold-text'>
                  <a
                    className='link'
                    href='https://www.linkedin.com/in/vanshika-srivastava/'
                  >
                    Vanshika Srivastava
                  </a>{' '}
                  is a Tech and Community Evangelist{' '}
                </span>{' '}
                . Her expertise happens in building and scaling communities
                across Web2, Web3, and SaaS. She has been advocating for open
                source, plugged into developer communities and the startup
                ecosystem.
              </span>
            </ListItem>
          </List>
        </Box>
      </SimpleGrid>
      <br />
      <center>
        <Button
          mb={6}
          size='lg'
          style={{ borderRadius: '25px', backgroundColor: '#46ADBE' }}
          onClick={() => history.push('/events/cw-open-source/register')}
        >
          <Text
            color='white'
            fontSize={['sm', 'md', 'lg']}
            letterSpacing={2}
            fontWeight={600}
            fontFamily='Montserrat'
          >
            Register Now
          </Text>
        </Button>
        <Button
          mb={6}
          marginLeft={3}
          size='lg'
          style={{ borderRadius: '25px', backgroundColor: '#46ADBE' }}
          onClick={() =>
            window.open('https://www.youtube.com/watch?v=Xw_intjGtXE')
          }
        >
          <Text
            color='white'
            fontSize={['sm', 'md', 'lg']}
            letterSpacing={2}
            fontWeight={600}
            fontFamily='Montserrat'
          >
            View Event
          </Text>
        </Button>
      </center>
      <Footer />
    </Box>
  );
};

export default HacktoberFest;
