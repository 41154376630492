import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserContext } from './Providers/UserProvider';
import { useLastLocation } from 'react-router-last-location';

function AuthRoute({ component: Component, ...rest }) {
  const user = useContext(UserContext);
  const lastLocation = useLastLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? lastLocation ? <Redirect to={lastLocation.pathname} /> : <Redirect to='/' /> : <Component {...props} />
      }
    />
  );
}

export default AuthRoute;
