import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import EventCard from './EventCard';

const UpcomingEvent = () => {
  let size;

  let widthscreen = window.screen.width;
  if (widthscreen <= 1400 && widthscreen >= 541) {
    size = 3;
  } else {
    size = 4;
  }

  return (
    <Box className='desktopview'>
      <center>
        <SimpleGrid
          columns={[1, 1, 2, size]}
          spacingX={[4, 4, 8, 8]}
          spacingY={[6, 8]}
          // width='80%'
          paddingY={6}
        >
          <EventCard
            name='Kickstart your Opensource Journey with CW'
            date='16/10/22'
            done={false}
            image='https://ik.imagekit.io/codewarriors/posterDtTm_SuHkZBrx_.jpg'
            link='/events/cw-open-source'
          />
        </SimpleGrid>
      </center>
      {/* <Text textAlign="center" fontWeight={800} mt={2} color="#003049" fontSize={["3xl"]} py={10} lineHeight={1.3} >Currently No Upcoming Events</Text> */}
    </Box>
  );
};

export default UpcomingEvent;
