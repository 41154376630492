import React, { useState } from 'react';
import {
  Box,
  Button,
  Image,
  SimpleGrid,
  Text,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { useHistory } from 'react-router';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';

const PortfolioEvent = () => {
  const [isOpened, setIsOpen] = useState(false);
  window.scrollTo(0, 0);

  let history = useHistory();

  const toggle = () => {
    setIsOpen(!isOpened);
  };

  return (
    <Box bg='#F4F5F7'>
      <Sidebar isOpen={isOpened} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Image
        mt={8}
        draggable={false}
        className='chapter-img'
        src='https://ik.imagekit.io/codewarriors/portfolio_qREfBeFbR.png'
      />
      <SimpleGrid columns={[1, 2]} mt={8}>
        <Box>
          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            Build Your Professional Portfolio
          </Text>

          <List spacing={4} className='list'>
            <ListItem d='flex' className='quote'>
              We all know that to achieve something big, we have to accomplish small things first.
            </ListItem>
            <ListItem d='flex'>
              In the same way building, a portfolio is like a stepping stone towards learning and working in web development.
            </ListItem>

            <ListItem d='flex' className='quote'>
              Perquisites: JavaScripts, Basic understanding of ReactJS.
            </ListItem>

            <ListItem d='flex'>
              Code Warriors is back with the amazing opportunity by providing you with a hands-on workshop on building a portfolio using ReactJS
            </ListItem>
          </List>
        </Box>

        <Box>
          <Text className='chapter-desc' fontSize={['xl', 'xl', '2xl', '2xl']}>
            How will this workshop help you?
          </Text>
          <List spacing={1} className='list'>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              You will build your portfolio that has immense demand if you want to pursue web development.
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              This will be a hands-on workshop, so you will be able to learn a lot of new concepts.
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              To present yourself virtually in a more appealing manner.
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              To build trust.
            </ListItem>
            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              To showcase your best work.
            </ListItem>

            <ListItem d='flex'>
              <ListIcon as={MdCheckCircle} color='green.500' />
              To highlight the technologies that you are best in.
            </ListItem>

          </List>

        </Box>
      </SimpleGrid>
      <br />
      <center>
        {/* <Button
          mb={6}
          size='lg'
          disabled
          style={{ borderRadius: '25px', backgroundColor: '#46ADBE' }}
          onClick={() =>
            history.push('/events/build-professional-portfolio/register')
          }
        >
          <Text
            color='white'
            fontSize={['sm', 'md', 'lg']}
            letterSpacing={2}
            fontWeight={600}
            fontFamily='Montserrat'
          >
            Register Now
          </Text>
        </Button> */}

        <Button
          mb={6}
          size='lg'
          ml={6}
          disabled
          style={{ borderRadius: '25px', backgroundColor: '#46ADBE' }}
          onClick={() =>
            history.push('/events/build-professional-portfolio/submit')
          }
        >
          <Text
            color='white'
            fontSize={['sm', 'md', 'lg']}
            letterSpacing={2}
            fontWeight={600}
            fontFamily='Montserrat'
          >
            Submit Portfolio
          </Text>
        </Button>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          mb={6}
          size="lg"
          style={{ borderRadius: "25px", backgroundColor: "#46ADBE" }}
          onClick={() => window.open("https://youtu.be/-IGhnyQvFhE")}
        >
          <Text
            color="white"
            fontSize={["sm", "md", "lg"]}
            letterSpacing={2}
            fontWeight={600}
            fontFamily="Montserrat"
          >
            View Workshop
          </Text>
        </Button>
        {/* // https://youtu.be/-IGhnyQvFhE */}
      </center>
      <Footer />
    </Box>
  );
};

export default PortfolioEvent;
