import { SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import EventCard from './EventCard';

const CompletedEvent = () => {
  let size;

  let widthscreen = window.screen.width;
  if (widthscreen <= 1400 && widthscreen >= 541) {
    size = 3;
  } else {
    size = 4;
  }

  return (
    <center>
      <SimpleGrid
        columns={[1, 1, 2, size]}
        spacingX={[4, 4, 8, 8]}
        spacingY={[6, 8]}
        // width='80%'
        paddingY={6}
      >
        <EventCard
          name='MLOps: What You Need To Know?'
          date='15/8/21'
          done={true}
          image='https://ik.imagekit.io/codewarriors/MLops_tUmXfe-g4LD.png'
          link='/events/MLOps'
        />
        <EventCard
          name='Build Your Professional Portfolio'
          date='6/6/21'
          done={true}
          image='https://ik.imagekit.io/codewarriors/Copy_of_Copy_of_Copy_of_Copy_of_Fun_Purple_Illustrated_3D_Humans_Education_Keynote_Presentation_JlM2_mQFl.png'
          link='/events/build-professional-portfolio'
        />
        <EventCard
          name='Code Warriors - Spring Of Code'
          date='1/3/21 onwards'
          done={true}
          image='https://ik.imagekit.io/codewarriors/soc_xiR7iCplC.jpeg'
          link='/events/SpringOfCode'
        />
        <EventCard
          name='Words of Wisdom'
          image='https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-20_at_8.04.47_PM_QChK9LfhC.jpeg'
          date='1/9/20'
          done={true}
          link='https://www.youtube.com/playlist?list=PLZV4RP3aP3FDMy9iu8xN66cC0VE9b_IW1'
        />
        <EventCard
          name='Data Visualization'
          image='https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-20_at_8.03.49_PM_RBIY0XObR0.jpeg'
          date='4/10/20'
          done={true}
          link='https://youtu.be/dP6zWyqpj9k'
        />
        <EventCard
          name='30 Days Of Flutter'
          image='https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-20_at_8.04.47_PM__1__ZNfjIj4Dks.jpeg'
          date='1/10/20'
          done={true}
          link='https://www.youtube.com/playlist?list=PLZV4RP3aP3FCC66sZxe_q7mre72Tfj6dS'
        />
        <EventCard
          name='Game of data'
          date='1/9/20'
          image='https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-20_at_8.04.46_PM_TqFBIImywa.jpeg'
          done={true}
          link='https://youtu.be/f_tg3OdVcnY'
        />
        <EventCard
          name='21 Days Of Machine Learning'
          date='1/9/20'
          image='https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-20_at_8.04.46_PM__1__8gjqW38lZ.jpeg'
          done={true}
          link='https://www.youtube.com/playlist?list=PLZV4RP3aP3FC7spRbkQ_W4lQbig1JrDan'
        />
        <EventCard
          name='End-to-End Machine Learning Project'
          image='https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-20_at_8.04.46_PM__2__VYsr-dCpd5.jpeg'
          date='22/8/20'
          done={true}
          link='https://youtu.be/laJ2Jfk2Tu4'
        />
        <EventCard
          name='Deep Learning for Fruit Classification'
          image='https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-20_at_8.04.46_PM__3__i7HzVXQ5ZZ.jpeg'
          date='16/8/20'
          done={true}
          link='https://youtu.be/82GMw6espuQ'
        />
        <EventCard
          name='Python for Machine Learning'
          image='https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-20_at_8.04.45_PM_4mRnL1Dgpn.jpeg'
          date='8/8/20 - 9/8/20'
          done={true}
          link='https://youtu.be/KrJ37uysu9Y'
        />
        <EventCard
          name='Getting Started with Deep Learning'
          image='https://ik.imagekit.io/codewarriors/WhatsApp_Image_2021-02-20_at_8.04.45_PM__1__dx8VafuYx.jpeg'
          date='25/7/20'
          done={true}
          link='https://youtu.be/pcRftFoNFzU'
        />
        <EventCard
          name='Code Warriors - Spring Of Code'
          date='1/3/21'
          done={true}
          image='https://ik.imagekit.io/codewarriors/soc_xiR7iCplC.jpeg'
          link='/events/SpringOfCode'
        />
      </SimpleGrid>
    </center>
  );
};

export default CompletedEvent;
