import React from 'react'
import { Box, Image, Text } from '@chakra-ui/react';


const Error404 = () => {
    return (
        <Box bg="white" height="100vh">
            <Image src="https://ik.imagekit.io/codewarriors/404_Yckhmnj5G.svg" width={[400, 500, 600]} m="auto" pt={[48, 32]} />
            <a href="/">
                <Text textAlign="center" fontSize={["lg", "xl", "2xl"]} pt={2} fontWeight={600} color="#46ADBE" fontFamily="Montserrat">Go back home</Text>
            </a>
        </Box>
    )
}

export default Error404
