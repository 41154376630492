import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import Course from './pages/course';
import Contact from './pages/contact';
import Events from './pages/events';
import Team from './pages/team';
import Community from './pages/community';
import Error404 from './components/Error404';
import ReactGA from 'react-ga';
import Coding from './components/Single Event/Coding';
import CodeView from './components/Single Event/CodeView';
import UserProvider from './Providers/UserProvider';
import Login from './components/Login/Login';
import AuthRoute from './AuthRouter';
import ProtectRoute from './ProtectRoute';
import { LastLocationProvider } from 'react-router-last-location';
import ChapterLead from './components/ChapterLead/ChapterLead';
import ChapterLeadForm from './components/ChapterLead/ChapterLeadForm';
import PortfolioEventForm from './components/Single Event/Portfolio Event/PortfolioEventForm';
import PortfolioEvent from './components/Single Event/Portfolio Event/PortfolioEvent';
import HacktoberFest from './components/Single Event/HacktoberFest/HacktoberFest';
import HacktoberFestForm from './components/Single Event/HacktoberFest/HacktoberFestForm';
import Mlops from './components/Single Event/MLOps Event/Mlops';
import MlopsForm from './components/Single Event/MLOps Event/MlopsForm';

function App() {
  ReactGA.initialize('UA-189108262-1');
  ReactGA.pageview('/');
  ReactGA.pageview('/courses');
  ReactGA.pageview('/events');
  ReactGA.pageview('/team');
  ReactGA.pageview('/contactus');
  ReactGA.pageview('/community');
  ReactGA.pageview('/events/SpringOfCode');
  ReactGA.pageview('/events/hacktoberfest');

  return (
    <UserProvider>
      <Router>
        <LastLocationProvider>
          <Switch>
            <Route path='/' exact component={Home} />
            <AuthRoute path='/login' exact component={Login} sensitive />
            <Route path='/courses' exact component={Course} sensitive />
            <Route path='/events' exact component={Events} sensitive />
            <Route
              path='/events/SpringOfCode'
              exact
              component={Coding}
              sensitive
            />
            <Route
              path='/events/SpringOfCode/:id'
              exact
              component={CodeView}
              sensitive
            />
            <Route
              path='/events/build-professional-portfolio'
              exact
              component={PortfolioEvent}
              sensitive
            />
            <Route
              path='/events/MLOps'
              exact
              component={Mlops}
              sensitive
            />
            <Route
              path='/events/cw-open-source'
              exact
              component={HacktoberFest}
              sensitive
            />
            <ProtectRoute
              path='/events/cw-open-source/register'
              exact
              component={HacktoberFestForm}
              sensitive
            />
            {/* <ProtectRoute
              path='/chapterlead/register'
              exact
              component={ChapterLeadForm}
              sensitive
            /> */}
            <Route path='/contactus' exact component={Contact} sensitive />
            <Route path='/team' exact component={Team} sensitive />
            <Route path='/chapterlead' exact component={Community} sensitive />
            <Route path='*' component={Error404} />
          </Switch>
        </LastLocationProvider>
      </Router>
    </UserProvider>
  );
}

export default App;
