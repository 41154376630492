import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { data } from "../../database/solution";
import { CopyBlock, dracula } from "react-code-blocks";
import {
  Box,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Center,
} from "@chakra-ui/react";

import Error404 from "../Error404";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { data1 } from "../../database/solution1";
// import { data1 } from "../../database/solution1";

const CodeView = () => {
  const { id } = useParams();

  // const solution = data.filter((cid) => cid.id === parseInt(id));

  const solution =
    id <= 16
      ? data.filter((cid) => cid.id === parseInt(id))
      : data1.filter((cid) => cid.id === parseInt(id));

  const [isOpened, setIsOpen] = useState(false);
  window.scrollTo(0, 0);

  const toggle = () => {
    setIsOpen(!isOpened);
  };

  return (
    <>
      {solution.length && solution[0].text !== "" ? (
        <>
          <Sidebar isOpen={isOpened} toggle={toggle} />

          <Navbar toggle={toggle} />

          <Tabs variant="soft-rounded" colorScheme="blue" mt={6}>
            <Center>
              <TabList>
                <Tab>Python</Tab>
                <Tab>C</Tab>
                <Tab>C++</Tab>
                <Tab>Java</Tab>
                {/* <Tab>JavaScript</Tab> */}
              </TabList>
            </Center>

            <TabPanels>
              <TabPanel>
                <Box padding={6}>
                  {solution.length && solution[0].text !== "" ? (
                    <CopyBlock
                      language="python"
                      text={solution[0].text}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                    />
                  ) : (
                    <Error404 />
                  )}
                </Box>
              </TabPanel>

              <TabPanel>
                <Box padding={6}>
                  {solution.length && solution[0].c !== "" ? (
                    <CopyBlock
                      language="c"
                      text={solution[0].c}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                    />
                  ) : (
                    <Error404 />
                  )}
                </Box>
              </TabPanel>

              <TabPanel>
                <Box padding={6}>
                  {solution.length && solution[0].cplusplus !== "" ? (
                    <CopyBlock
                      language="cpp"
                      text={solution[0].cplusplus}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                    />
                  ) : (
                    <Error404 />
                  )}
                </Box>
              </TabPanel>

              <TabPanel>
                <Box padding={6}>
                  {solution.length && solution[0].java !== "" ? (
                    <CopyBlock
                      language="java"
                      text={solution[0].java}
                      theme={dracula}
                      wrapLines={true}
                      codeBlock
                    />
                  ) : (
                    <Error404 />
                  )}
                </Box>
              </TabPanel>

              {/* <TabPanel>
                                    <Box padding={6}>
                                        {
                                            solution.length && solution[0].JavaScript !== "" ? <CopyBlock
                                                language="javascript"
                                                text={solution[0].JavaScript}
                                                theme={dracula}
                                                wrapLines={true}
                                                codeBlock
                                            /> : <Error404 />
                                        }
                                    </Box>
                                </TabPanel> */}
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <Error404 />
      )}
    </>
  );
};

export default CodeView;
